import { lazy } from 'react';

import { DASHBOARD_PORTAL_PATH } from 'core/config';
import loadable from 'core/utils/loadable';

import path from '../path';

export const NEW_ORDER_PATH = path(DASHBOARD_PORTAL_PATH, 'novo-pedido');
export const NEW_ORDER_TYPE_SELECTION_PATH = path(
  DASHBOARD_PORTAL_PATH,
  'opcoes-novo-pedido',
);
export const SCREEN_PATH = path(DASHBOARD_PORTAL_PATH, 'menu');
export const NOTIFICATIONS_PATH = path(DASHBOARD_PORTAL_PATH, 'notificacoes');
export const ORDER_DETAIL_PATH = path(DASHBOARD_PORTAL_PATH, 'detalhe-pedido');
export const CAMPAIGN_DETAIL_PATH = path(DASHBOARD_PORTAL_PATH, 'campanha');
export const MY_DATA_PATH = path(DASHBOARD_PORTAL_PATH, 'meus-dados');
export const REORDER_PATH = path(DASHBOARD_PORTAL_PATH, 'refazer-pedido');
export const EDIT_ORDER_PATH = path(DASHBOARD_PORTAL_PATH, 'editar-pedido');
export const LASTORDERS_PATH = path(NEW_ORDER_PATH, 'ultimos-pedidos');

export const HOME_PAGE = DASHBOARD_PORTAL_PATH;

const NotFound = {
  path: '*',
  Element: loadable(lazy(() => import('views/NotFound'))),
};

const EmptyScreen = {
  path: path(DASHBOARD_PORTAL_PATH, 'menu'),
  Element: loadable(lazy(() => import('views/@portal/Screen'))),
};

const Screen = {
  path: path(SCREEN_PATH, ':id'),
  Element: loadable(lazy(() => import('views/@portal/Screen'))),
};

const Notifications = {
  path: NOTIFICATIONS_PATH,
  Element: loadable(lazy(() => import('views/@portal/Notifications'))),
};

const NewOrderTypeSelection = {
  path: NEW_ORDER_TYPE_SELECTION_PATH,
  Element: loadable(lazy(() => import('views/@portal/NewOrderTypeSelection'))),
};

const NewOrder = {
  path: NEW_ORDER_PATH,
  Element: loadable(lazy(() => import('views/@portal/NewOrder'))),
};

const Reorder = {
  path: REORDER_PATH,
  Element: loadable(lazy(() => import('views/@portal/Reorder'))),
};

const editOrder = {
  path: EDIT_ORDER_PATH,
  Element: loadable(lazy(() => import('views/@portal/Reorder'))),
};

const Search = {
  path: path(NEW_ORDER_PATH, 'busca'),
  Element: loadable(lazy(() => import('views/@portal/NewOrder/Search'))),
};

const Favorites = {
  path: path(NEW_ORDER_PATH, 'favoritos'),
  Element: loadable(lazy(() => import('views/@portal/NewOrder/Favorites'))),
};

const Suggested = {
  path: path(NEW_ORDER_PATH, 'sugeridos/:widget/:sort'),
  Element: loadable(lazy(() => import('views/@portal/NewOrder/Suggested'))),
};

const LastOrders = {
  path: path(LASTORDERS_PATH, ':tableID/:widget/:sort'),
  Element: loadable(
    lazy(() => import('components/widgets/Widgets/LastOrders/SeeAll')),
  ),
};

const Category = {
  path: path(NEW_ORDER_PATH, 'categoria/:category'),
  Element: loadable(lazy(() => import('views/@portal/NewOrder/Category'))),
};

const CustomerPortfolio = {
  path: path(DASHBOARD_PORTAL_PATH, 'menu/:screen/clientes/:id'),
  Element: loadable(lazy(() => import('views/@portal/CustomerPortfolio'))),
};

const OrderDetail = {
  path: path(ORDER_DETAIL_PATH, ':id'),
  Element: loadable(lazy(() => import('views/@portal/OrderDetail'))),
};

const CampaignDetail = {
  path: path(CAMPAIGN_DETAIL_PATH, ':campaign'),
  Element: loadable(lazy(() => import('views/@portal/Campaigns/Campaign'))),
};

const MyData = {
  path: MY_DATA_PATH,
  Element: loadable(lazy(() => import('views/@portal/MyData'))),
};

export default [
  EmptyScreen,
  Screen,
  Notifications,
  NotFound,
  NewOrderTypeSelection,
  NewOrder,
  Search,
  Favorites,
  Suggested,
  LastOrders,
  CustomerPortfolio,
  OrderDetail,
  CampaignDetail,
  MyData,
  Category,
  Reorder,
  editOrder,
];
