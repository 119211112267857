import React, { useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import Iconify from '../Iconify';
import { SearchInputProps } from './props';

const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  trigger = 'click',
  iconPosition = 'end',
  onChange,
  ...other
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const seachIcon = (
    <InputAdornment position={iconPosition}>
      <IconButton
        color="inherit"
        onClick={() => {
          onSearch(inputRef.current?.value);
        }}
      >
        <Iconify icon="eva:search-fill" sx={{ width: 20, height: 20 }} />
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      inputRef={inputRef}
      InputProps={{
        onKeyDown: e => {
          if (e.key === 'Enter') onSearch(inputRef.current?.value);
        },

        ...(iconPosition === 'start'
          ? { startAdornment: seachIcon }
          : { endAdornment: seachIcon }),
      }}
      onChange={e => {
        if (trigger === 'change') {
          onSearch(e.target.value);
          return;
        }

        if (e.target.value === '') onSearch('');

        if (onChange) onChange(e);
      }}
      {...other}
    />
  );
};

export default SearchInput;
