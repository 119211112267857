import { Cart } from 'core/models/cart';

import api from './base/api';

export const CONFIG_NEW_ORDER_TAGS: any = ['NEW_ORDER'];

interface GetCartRequest {
  id: string;
  contexto: object;
}

interface EventCartRequest {
  id: string;
  novoPedidoID: string;
  evento: string;
  item: {
    id: string;
    unidade: string;
    controle: string;
    precoUnitario: number;
    precoTotal: number;
    quantidade: number;
    valorPercentual: number;
    valorDesconto: number;
  };
}

export const cartApi = api.injectEndpoints({
  endpoints: build => ({
    getCart: build.query<Cart, GetCartRequest>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'CarrinhoCompras',
          method: 'getCarrinho',
        },
        body,
      }),
      providesTags: CONFIG_NEW_ORDER_TAGS,
    }),
    eventCart: build.mutation<Cart, EventCartRequest | object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'CarrinhoCompras',
          method: 'evento',
        },
        body,
      }),
    }),
  }),
});

export const { useLazyGetCartQuery, useEventCartMutation } = cartApi;
