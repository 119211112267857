import { lazy } from 'react';
import { createMemoryRouter } from 'react-router-dom';

import Dashboard from 'core/layouts/@admin/Dashboard';
import loadable from 'core/utils/loadable';
import { ADMIN_DASHBOARD_PATH } from 'core/config';
import paths from './paths';

export const Home = loadable(lazy(() => import('views/@admin/Home')));

const router = createMemoryRouter(
  [
    {
      path: ADMIN_DASHBOARD_PATH,
      element: <Dashboard />,
      children: paths.map(({ path, Element }) => ({
        path,
        element: <Element />,
      })),
    },
  ],
  {
    initialEntries: ['/'],
  },
);

export default router;
