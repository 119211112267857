/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const deepCompareEquals = (a?: any, b?: any) => isEqual(a, b);

const useDeepCompareMemoize = (value?: any) => {
  const ref = useRef<any>();

  if (!deepCompareEquals(value, ref.current)) ref.current = value;

  return ref.current;
};

const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
