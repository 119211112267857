import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MyData } from 'core/models/myData';
import { myDataApi } from 'core/services/myDataApi';

const initialState: MyData = {
  nome: '',
  email: '',
  cpf: '',
  telefone: '',
  recebeNotificacao: false,
  foto: '',
};

const slice = createSlice({
  name: 'mydata',
  initialState,
  reducers: {
    setFoto: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        foto: payload,
      };
    },
    setMyData: (state, { payload }: PayloadAction<MyData>) => {
      return {
        ...state,
        nome: payload.nome,
        email: payload.email,
        cpf: payload.cpf,
        telefone: payload.telefone,
        recebeNotificacao: payload.recebeNotificacao,
      };
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      myDataApi.endpoints.getMyData.matchFulfilled,
      (state, action) => {
        if (Object.keys(action.payload).length > 0) {
          return {
            ...state,
            nome: action.payload.nome,
            email: action.payload.email,
            cpf: action.payload.cpf,
            telefone: action.payload.telefone,
            recebeNotificacao: action.payload.recebeNotificacao,
            foto: action.payload.foto,
          };
        }

        return state;
      },
    );
  },
});

export const { setFoto, setMyData } = slice.actions;

export default slice.reducer;
