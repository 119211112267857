import { useEffect, useState } from 'react';

const useActiveElement = () => {
  const [listenersReady, setListenersReady] = useState<boolean>(false);
  const [activeElement, setActiveElement] = useState<Element | null>(
    document.activeElement,
  );

  useEffect(() => {
    const onFocus = (event: FocusEvent) =>
      setActiveElement(event.target as Element);
    const onBlur = () => setActiveElement(null);

    window.addEventListener('focus', onFocus, true);
    window.addEventListener('blur', onBlur, true);

    setListenersReady(true);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return {
    activeElement,
    listenersReady,
  };
};

export default useActiveElement;
