import React, { PropsWithChildren, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import merge from 'lodash/merge';

const ThemeContrast: React.FC<PropsWithChildren> = ({ children }) => {
  const outerTheme = useTheme();

  const themeOptions = useMemo(
    () => ({
      palette: {
        background: {
          default: outerTheme.palette.grey[100],
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: outerTheme.customShadows.z4,
            },
          },
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeContrast;
