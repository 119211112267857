import React from 'react';
import { Button } from '@mui/material';

import ConfirmDialog from 'components/elements/ConfirmDialog';

import { ConfirmCartItemRemoveProps } from './props';

const ConfirmCartItemRemove: React.FC<ConfirmCartItemRemoveProps> = ({
  open,
  onClose,
  item,
  onClickRemove,
}) => {
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Confirmação de remoção"
      content={
        <>
          Você confirma a remoção do <strong>{item.productName}</strong>{' '}
          carrinho ?
        </>
      }
      action={
        <Button variant="contained" color="error" onClick={onClickRemove}>
          Remover
        </Button>
      }
    />
  );
};

export default ConfirmCartItemRemove;
