import { useCallback } from 'react';

import { clear } from 'core/redux/slices/cart';
import {
  resetOrder,
  updateOrderData,
  updateOrderStoredValues,
} from 'core/redux/slices/order';
import { OrderState, StoredValues } from 'core/redux/slices/order/types';
import { dispatch, useSelector } from 'core/redux/store';
import { Omit } from 'core/types/omit';
import { useAuth } from './useAuth';

const useOrder = (cartKey?: string) => {
  const { userIndentifier, userType } = useAuth();
  const orderIdentifier = cartKey || userIndentifier || '-';

  const orderState = useSelector(state => state.order);
  const order = orderState[orderIdentifier] || {};
  const { orderConfig, storedValues } = order;
  const TYPE_OF_PRE_ORDER = orderConfig?.prePedidoType;
  const preOrderFormValues = storedValues?.preOrderFormValues;

  const updateOrderState = useCallback(
    (item: keyof Omit<OrderState, 'storedValues'>, value: unknown) =>
      dispatch(
        updateOrderData({
          orderIdentifier,
          key: item,
          value,
        }),
      ),
    [orderIdentifier],
  );

  const updateOrderStateStoredValues = useCallback(
    (item: keyof StoredValues, value: unknown) =>
      dispatch(
        updateOrderStoredValues({
          orderIdentifier,
          key: item,
          value,
        }),
      ),
    [orderIdentifier],
  );

  const clearCurrentOrder = useCallback(() => {
    dispatch(clear(orderIdentifier));
    dispatch(resetOrder(orderIdentifier));
  }, [orderIdentifier]);

  const buildSteps = () => {
    if (userType === 'PARCEIRO') {
      if (
        TYPE_OF_PRE_ORDER === 'FILTRO_OBRIGATORIO' ||
        TYPE_OF_PRE_ORDER === 'FORMULARIO'
      )
        return ['Filtro', 'Produtos', 'Checkout'];

      return ['Produtos', 'Checkout'];
    }

    if (TYPE_OF_PRE_ORDER === 'FORMULARIO')
      return ['Pré-pedido', 'Produtos', 'Checkout'];

    return ['Produtos', 'Checkout'];
  };

  const STEPS = buildSteps();

  const goToCheckoutStep = () => {
    if (STEPS.length === 2) updateOrderStateStoredValues('currentStep', 1);
    else updateOrderStateStoredValues('currentStep', 2);
  };

  const goToSelectionProductsStep = () => {
    if (TYPE_OF_PRE_ORDER !== 'NENHUM' && !preOrderFormValues) {
      updateOrderStateStoredValues('currentStep', 0);

      return;
    }

    if (STEPS.length === 2) updateOrderStateStoredValues('currentStep', 0);
    else updateOrderStateStoredValues('currentStep', 1);
  };

  return {
    ...order,
    ...order.storedValues,
    storedValues: order.storedValues,
    updateOrderState,
    STEPS,
    updateOrderStateStoredValues,
    clearCurrentOrder,
    goToCheckoutStep,
    goToSelectionProductsStep,
  };
};

export default useOrder;
