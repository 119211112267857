import React from 'react';
import { Typography, InputAdornment, CircularProgress } from '@mui/material';

import NumberInput from 'components/elements/NumberInput';

import { DiscountFieldProps } from './props';

const DiscountField: React.FC<DiscountFieldProps> = ({
  value,
  disabled,
  loading,
  onChange,
  endAdornment,
  sx,
  ...other
}) => {
  return (
    <NumberInput
      size="small"
      label="Desconto"
      value={value}
      disabled={disabled}
      onChange={e => onChange(e.target.value)}
      sx={sx}
      fullWidth
      decimalScale={2}
      fixedDecimalScale={false}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={14} />
            ) : (
              <Typography color="text.disabled">{endAdornment}</Typography>
            )}
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};

export default DiscountField;
