import { logout } from 'core/redux/slices/auth';
import { resetMenus } from 'core/redux/slices/menuItems';
import { dispatch, useSelector } from 'core/redux/store';

export const useAuth = () => {
  const auth = useSelector(state => state.auth);
  const { token } = auth || {};
  const { partner, seller, sub, user } = auth.user;
  const { perfil } = user || {};

  const userIndentifier =
    sub === 'parceiro'
      ? `partner:${partner?.id || 0}`
      : `seller:${seller?.id || 0}`;

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetMenus());
  };

  return {
    auth,
    userType: perfil,
    userIndentifier: token ? userIndentifier : undefined,
    handleLogout,
  };
};
