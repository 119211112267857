// eslint-disable-next-line no-shadow
export enum FieldType {
  ANEXO = 'ANEXO',
  DATA = 'DATA',
  DATA_HORA = 'DATA_HORA',
  DESCRICAO = 'DESCRICAO',
  HORA = 'HORA',
  IMAGEM = 'IMAGEM',
  INTERVALO_DATA = 'INTERVALO_DATA',
  MENU_SUSPENSO = 'MENU_SUSPENSO',
  NUMERO_DECIMAL = 'NUMERO_DECIMAL',
  NUMERO_INTEIRO = 'NUMERO_INTEIRO',
  SELECAO_MULTIPLA = 'SELECAO_MULTIPLA',
  SELECAO_UNICA = 'SELECAO_UNICA',
  TEXTO_CURTO = 'TEXTO_CURTO',
  TEXTO_LONGO = 'TEXTO_LONGO',
  SUB_FORM = 'SUB_FORMULARIO',
  SIM_NAO = 'SIM_NAO',
  CONTADOR = 'CONTADOR',
  PESQUISA = 'PESQUISA',
  ENDERECO = 'ENDERECO',
}
