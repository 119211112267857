import { OrderConfig } from 'core/models/orderConfig';
import { PriceAndStock } from 'core/models/priceAndStock';
import { Orders } from 'core/types/orderCalculation';
import { ApiRequest } from 'core/types/apiRequest';

import api from './base/api';

export const CONFIG_NEW_ORDER_TAGS: any = ['NEW_ORDER'];

export const newOrderApi = api.injectEndpoints({
  endpoints: build => ({
    getConfigOrder: build.query<OrderConfig, { id: string; publico?: boolean }>(
      {
        query: ({ id, publico }) => ({
          url: publico ? 'services/public' : 'services',
          method: 'POST',
          params: {
            name: 'NovoPedido',
            method: 'getById',
          },
          body: { id },
        }),
        providesTags: CONFIG_NEW_ORDER_TAGS,
      },
    ),
    getPriceAndStock: build.mutation<PriceAndStock, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Produto',
          method: 'calculaPrecoEstoque',
        },
        body,
      }),
    }),
    calculateOrderValue: build.mutation<Orders, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'SalvarPedido',
          method: 'calcular',
        },
        body,
      }),
    }),
    saveOrder: build.mutation<Orders, ApiRequest>({
      query: ({ name, method, body }) => ({
        url: 'services',
        method: 'POST',
        params: {
          name,
          method,
        },
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetConfigOrderQuery,
  useGetConfigOrderQuery,
  useGetPriceAndStockMutation,
  useCalculateOrderValueMutation,
  useSaveOrderMutation,
} = newOrderApi;
