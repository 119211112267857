import React, { lazy } from 'react';
import loadable from 'core/utils/loadable';
import { PaymentBuilderProps, PaymentGateway } from './props';

const Cielo = loadable(lazy(() => import('./Cielo')));

const PaymentBuilder: React.FC<PaymentBuilderProps> = ({
  paymentID,
  type,
  parcelas,
  parcela,
  paymentGateway,
  onTokenGenerated,
  onSelectedParcela,
  savingOrder,
}) => {
  if (type === 'CARTAO_CREDITO' && paymentGateway && onTokenGenerated) {
    const PAYMENT_GATEWAYS = {
      [PaymentGateway.CIELO]: (
        <Cielo
          paymentID={paymentID}
          onTokenGenerated={onTokenGenerated}
          parcelas={parcelas}
          parcela={parcela}
          onSelectedParcela={onSelectedParcela}
          savingOrder={savingOrder}
        />
      ),
    };

    return PAYMENT_GATEWAYS[paymentGateway];
  }

  return null;
};

export default PaymentBuilder;
