import React from 'react';
import { Button, Dialog, Tooltip } from '@mui/material';

import DynamicForm from 'components/hook-form/DynamicForm';
import Iconify from 'components/elements/Iconify';
import useToggle from 'core/hooks/useToggle';
import { objectToArrayObject } from 'core/utils/array';

import Actions from './Actions';

import { QuantityPopoverProps } from './props';

const QuantityPopover: React.FC<QuantityPopoverProps> = ({
  cartItem,
  onChange,
  action,
  triggerButtonText,
  actionButtonText,
  modifyingItem,
  resetProduct,
  error,
  alert,
}) => {
  const [modalOpen, toogleModalOpen] = useToggle();
  const { formularioID } = cartItem.unit || {};

  const handleClickAddToCart = () => {
    action();
    toogleModalOpen();
  };

  const handleCancel = () => {
    toogleModalOpen();
    resetProduct();
  };

  const handleSubmit = (values: object) => {
    onChange(objectToArrayObject(values, 'id', 'valor'));
  };

  const defaultValues = cartItem.projectValues?.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr.value }),
    {},
  );

  return (
    <>
      {cartItem.key === '0' ? (
        <Button
          onClick={toogleModalOpen}
          startIcon={<Iconify icon="ic:outline-calculate" />}
          variant="contained"
          fullWidth
          sx={{ minHeight: 40 }}
        >
          {triggerButtonText}
        </Button>
      ) : (
        <Tooltip title="Não é possivel alterar um projeto">
          <div style={{ flex: 1 }}>
            <Button
              startIcon={<Iconify icon="ic:outline-calculate" />}
              variant="contained"
              fullWidth
              sx={{ minHeight: 40 }}
              disabled
            >
              {triggerButtonText}
            </Button>
          </div>
        </Tooltip>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            p: 3,
          },
        }}
        scroll="body"
      >
        {formularioID ? (
          <DynamicForm
            formId={formularioID}
            type="DEFAULT"
            onSubmit={handleSubmit}
            size="small"
            formContainerProps={{
              sx: {
                gap: 2,
              },
            }}
            actions={({ formDisabled }) => (
              <Actions
                cartItem={cartItem}
                actionButtonText={actionButtonText}
                modifyingItem={modifyingItem}
                onClickAction={handleClickAddToCart}
                onClickCancel={handleCancel}
                error={error}
                alert={alert}
                formDisabled={formDisabled}
              />
            )}
            showTitle
            titleProps={{
              variant: 'subtitle1',
              pb: 2.75,
            }}
            formValuesParams={{
              '$PARAM[CP][PRODUTO]': cartItem.productId,
              '$PARAM[CP][UNIDADE]': cartItem.unityId,
            }}
            defaultValues={cartItem.key !== '0' ? defaultValues : undefined}
          />
        ) : (
          <>Erro de configuração</>
        )}
      </Dialog>
    </>
  );
};

export default QuantityPopover;
