import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
import Iconify from 'components/elements/Iconify';
import { QrReader } from 'react-qr-reader';

interface Props {
  onSearch(value: string | undefined): void;
  open: boolean;
  onClose: VoidFunction;
  setToggleModalOpenQRCodeReader: (value: boolean) => void;
}

const QrCodeReader = ({
  onSearch,
  setToggleModalOpenQRCodeReader,
  open,
  onClose,
}: Props) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2, border: '1px solid', borderColor: 'divider' }}>
        <Stack direction="row" justifyContent="space-between">
          Aponte a câmera para o QR Code
          <IconButton onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack
        sx={{ padding: 3, maxWidth: 650, maxHeight: 650, borderRadius: 5 }}
      >
        <QrReader
          onResult={result => {
            if (result) {
              onSearch(result.getText());
              setToggleModalOpenQRCodeReader(false);
            }
          }}
          constraints={{ facingMode: 'environment' }}
        />
      </Stack>
    </Dialog>
  );
};

export default QrCodeReader;
