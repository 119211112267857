import React from 'react';
import { m } from 'framer-motion';
import { Box } from '@mui/material';

import { varContainer } from './variants';
import { MotionContainerProps } from './props';

const MotionContainer: React.FC<MotionContainerProps> = ({
  animate,
  action = false,
  children,
  ...other
}) => {
  if (action) {
    return (
      <Box
        component={m.div}
        initial={false}
        animate={animate ? 'animate' : 'exit'}
        variants={varContainer()}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component={m.div}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  );
};

export default MotionContainer;
