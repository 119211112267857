import React from 'react';
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';

import Iconify from 'components/elements/Iconify';
import ConfirmCartItemRemove from 'components/products/ConfirmCartItemRemove';
import Discount from 'components/products/Discount';
import DiscountField from 'components/products/DiscountField';
import QuantitySelector from 'components/products/QuantitySelector';
import useCart from 'core/hooks/useCart';
import useCartItem from 'core/hooks/useCartItem';
import useOrder from 'core/hooks/useOrder';
import useToggle from 'core/hooks/useToggle';
import { fCurrencyBRL } from 'core/utils/formatNumber';

import { formatProjectValues } from 'core/utils/cart';
import { RowProps } from './props';

const Row: React.FC<RowProps> = ({ index, item }) => {
  const { orderConfig } = useOrder();
  const { habilitaDecontoPercentual, habilitaDecontoValor } = orderConfig || {};
  const [openConfirm, toggleOpenConfirm] = useToggle();

  const {
    cartItem,
    calculatingDiscount,
    modifyingProject,
    error,
    calculatingValues,
    updateCartItem,
    insertInCart,
    resetProduct,
    alert,
  } = useCartItem(item, index, undefined, undefined, toggleOpenConfirm);
  const { removeItem } = useCart();

  const handleConfirmDelete = () => {
    removeItem(index);
    toggleOpenConfirm();
  };

  const handleCancelDelete = () => {
    resetProduct();
    toggleOpenConfirm();
  };

  const projectValues = formatProjectValues(
    orderConfig,
    cartItem.price.calculadora?.projeto || [],
  );

  const labelUnitsSymbols = {
    MILIGRAMA: 'mg',
    GRAMA: 'g',
    KILO: 'Kg',
    TONELADA: 't',
    METRO: 'm',
    CENTIMETRO: 'cm',
    MILIMETRO: 'mm',
  };

  return (
    <TableRow sx={{ height: 48, backgroundColor: 'grey.0' }}>
      <TableCell>
        <Stack>
          <Tooltip title={cartItem.productName} placement="top">
            <Stack sx={{ width: 391 }}>
              <Typography noWrap variant="subtitle2">
                {cartItem.productName || cartItem.product?.name || ''}
              </Typography>
              {!!(
                cartItem.price.unitaryValue &&
                cartItem.price.unitaryValue !== 0 &&
                cartItem.price.value !== cartItem.price.unitaryValue
              ) && (
                <Typography
                  variant="body2"
                  color="gray"
                  style={{ textDecoration: 'line-through' }}
                >
                  {fCurrencyBRL(cartItem.price.unitaryValue)} {' /'}{' '}
                  {(cartItem.unit?.padrao && cartItem.unit.descricao) ||
                    cartItem.unit?.descricao}
                </Typography>
              )}

              <Typography variant="body2" color="gray">
                {fCurrencyBRL(cartItem.price.value)} {' /'}{' '}
                {(cartItem.unit?.padrao && cartItem.unit.descricao) ||
                  cartItem.unit?.descricao}
              </Typography>
              {orderConfig?.campoFixosProjeto && (
                <>
                  {Object.entries(projectValues).map(
                    ([fieldName, fieldValue]) => (
                      <Typography
                        key={fieldName}
                        variant="body2"
                        color="grey.500"
                      >
                        {fieldName}: {String(fieldValue)}{' '}
                        {fieldName === 'Quantidade'
                          ? 'un'
                          : labelUnitsSymbols[cartItem.unit?.descricao || '']}
                      </Typography>
                    ),
                  )}
                </>
              )}
            </Stack>
          </Tooltip>
          {cartItem.control && cartItem.productGrid && cartItem.variation && (
            <Typography variant="inherit" color="text.secondary">
              {cartItem.productGrid.descricaoV1}:{' '}
              {cartItem.variation.description}
              {' | '}
              {cartItem.productGrid.descricaoV2}:{' '}
              {cartItem.variation.variation.descricao}
            </Typography>
          )}
        </Stack>
      </TableCell>
      {orderConfig?.exibeEstoque && cartItem.unit?.seletor !== 'PROJETO' && (
        <TableCell>
          <Stack sx={{ width: 140, alignItems: 'center' }}>
            <Typography variant="inherit" color="text.secondary">
              {(cartItem.stock || 0) === 0 && cartItem.product?.expectedDate
                ? `Entrega prevista: ${cartItem.product.expectedDate}`
                : cartItem.stock}
            </Typography>
          </Stack>
        </TableCell>
      )}

      {orderConfig?.exibeEstoque && cartItem.unit?.seletor === 'PROJETO' && (
        <TableCell>
          <Stack sx={{ width: 85, alignItems: 'center' }}>
            <Typography variant="inherit" color="text.secondary">
              {cartItem.stock}
            </Typography>
          </Stack>
        </TableCell>
      )}

      <TableCell sx={{ width: 120 }}>
        {cartItem.unit?.seletor === 'PROJETO' ? (
          <Tooltip
            title="Quer adicionar mais unidades deste produto? Volte para a página do
            produto e faça personalização para novo item."
            placement="top"
          >
            <Stack direction="column" alignItems="center" textAlign="center">
              <Iconify
                width={16}
                height={16}
                color="#22C55E"
                icon="material-symbols:info-outline"
              />
              <Typography sx={{ mt: '5px' }} variant="caption" color="GrayText">
                Produto personalizado
              </Typography>
            </Stack>
          </Tooltip>
        ) : (
          <QuantitySelector
            onChange={(prop, newValue) => updateCartItem(prop, newValue)}
            cartItem={cartItem}
            action={insertInCart}
            triggerButtonText="Adicionar"
            actionButtonText="Adicionar ao carrinho"
            modifyingItem={modifyingProject}
            resetProduct={resetProduct}
            error={error}
            alert={alert}
            disabled={calculatingValues}
          />
        )}
      </TableCell>

      {(habilitaDecontoPercentual || habilitaDecontoValor) && (
        <TableCell>
          {orderConfig && cartItem.unit?.seletor !== 'PROJETO' && (
            <Discount
              orderConfig={orderConfig}
              discountType={cartItem.discountType}
              onChangeDiscountType={newValue =>
                updateCartItem('discountType', newValue)
              }
              discountPercent={
                <DiscountField
                  name="discountPercentage"
                  value={cartItem.discountPercentage}
                  disabled={calculatingDiscount === 'discountValue'}
                  loading={calculatingDiscount === 'discountValue'}
                  onChange={value =>
                    updateCartItem('discountPercentage', value)
                  }
                  endAdornment="%"
                  sx={{ flex: 1 }}
                />
              }
              discountValue={
                <DiscountField
                  name="discountValue"
                  value={cartItem.discountValue}
                  disabled={calculatingDiscount === 'discountPercentage'}
                  loading={calculatingDiscount === 'discountPercentage'}
                  onChange={value => updateCartItem('discountValue', value)}
                  endAdornment="R$"
                  sx={{ flex: 1 }}
                />
              }
            />
          )}
        </TableCell>
      )}

      <TableCell>{fCurrencyBRL(item.price.totalValue)}</TableCell>

      <TableCell>
        <IconButton onClick={toggleOpenConfirm}>
          <Iconify icon="ic:sharp-delete-outline" color="error.main" />
        </IconButton>

        <ConfirmCartItemRemove
          open={openConfirm}
          onClose={handleCancelDelete}
          item={item}
          onClickRemove={handleConfirmDelete}
        />
      </TableCell>
    </TableRow>
  );
};

export default Row;
