import { useMemo } from 'react';

import { RequiredArguments } from 'core/models/requiredArguments';
import { add, removeContext, reset } from 'core/redux/slices/arguments';
import { dispatch, useSelector } from 'core/redux/store';
import { RequestArguments } from 'core/models/requestArguments';

const useArguments = (
  ctx?: string,
  requiredArguments?: RequiredArguments[],
) => {
  const { arguments: argumentsContexts } = useSelector(
    state => state.arguments,
  );

  const argumentsContext = ctx ? argumentsContexts[ctx] : undefined;

  const requestArguments = useMemo(() => {
    if (
      !argumentsContext ||
      !requiredArguments ||
      requiredArguments.length === 0
    )
      return undefined;

    const allArgumentsFilled = requiredArguments.every(
      ra => argumentsContext[ra.chave] !== undefined,
    );

    if (!allArgumentsFilled) return undefined;

    const values = requiredArguments.reduce<RequestArguments[]>(
      (acc, curr) => [
        ...acc,
        {
          chave: curr.chave,
          valor: argumentsContext[curr.chave],
        },
      ],
      [],
    );

    return values;
  }, [argumentsContext, requiredArguments]);

  const blockRequestThatNeedsTheArgument =
    requiredArguments &&
    requiredArguments.length > 0 &&
    (requestArguments === undefined || requestArguments.length === 0);

  const addArgumentValues = (context: string, value: object | undefined) =>
    dispatch(add({ context, value }));

  const removeArgumentContext = (context: string) =>
    dispatch(removeContext(context));

  const resetArgumentContext = () => dispatch(reset());

  const getArgumentContext = (context: string) => argumentsContexts[context];

  return {
    all: argumentsContexts,
    argumentsContext,
    requestArguments,
    addArgumentValues,
    removeArgumentContext,
    resetArgumentContext,
    getArgumentContext,
    blockRequestThatNeedsTheArgument,
  };
};

export default useArguments;
