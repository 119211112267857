import { Layout } from 'core/models/layout';

import api from './base/api';

export const layoutApi = api.injectEndpoints({
  endpoints: build => ({
    getLayout: build.query<Layout, void>({
      query: () => ({
        url: 'services/public',
        method: 'POST',
        params: {
          name: 'Portal',
          method: 'getAparencia',
        },
        body: {},
      }),
    }),
  }),
});

export const { useGetLayoutQuery } = layoutApi;
