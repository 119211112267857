import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tokens } from 'core/models/auth';
import { AuthState, User } from './types';

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  user: {} as User,
  needToChangePassword: false,
};

interface Payload extends Tokens {
  userData: User;
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<Payload>) => {
      return {
        ...state,
        token: payload.accessToken,
        refreshToken: payload.refreshToken,
        user: payload.userData,
        needToChangePassword: payload.userData.user.changePassword,
      };
    },
    refreshCredentials: (state, { payload }: PayloadAction<Payload>) => {
      return {
        ...state,
        token: payload.accessToken,
        refreshToken: payload.refreshToken,
        user: payload.userData,
      };
    },
    setNeedToChangePassword: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        needToChangePassword: payload,
      };
    },
    logout: () => initialState,
  },
});

export const {
  setCredentials,
  refreshCredentials,
  logout,
  setNeedToChangePassword,
} = slice.actions;

export default slice.reducer;
