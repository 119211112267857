/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { memo } from 'react';
import { Stack } from '@mui/material';

import { hideScrollbarY } from 'core/utils/cssStyles';
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';

const NavSectionHorizontal: React.FC<NavSectionProps> = ({
  data,
  sx,
  ...other
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        ...hideScrollbarY,
        ...sx,
      }}
      {...other}
    >
      {data.map(group => (
        <Items key={group.subheader} items={group.items} />
      ))}
    </Stack>
  );
};

export default memo(NavSectionHorizontal);

type ItemsProps = {
  items: NavListProps[];
};

const Items: React.FC<ItemsProps> = ({ items }) => {
  return (
    <>
      {items.map(list => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
};
