import { useCallback, useState } from 'react';

import { FormField } from 'core/models/formField';
import { OptionField } from 'core/models/optionField';
import {
  useGetOptionsQuery,
  useGetFieldOptionsConfigQuery,
} from 'core/services/fieldOptionsApi';
import { Option } from 'core/types/option';
import { getValue } from 'core/utils/tableData';

interface Props {
  options?: Option[];
  requestData?: object;
  groupBy?: (option: Option) => string;
  dependsOnFields?: string[];
  publicFormField: boolean;
  configs?: FormField;
}

const useFieldOptions = ({
  options,
  requestData,
  groupBy,
  dependsOnFields = [],
  publicFormField,
  configs,
}: Props) => {
  const [request, setRequest] = useState({
    data: requestData,
    skip: !requestData || dependsOnFields.length > 0,
  });
  const { tipo, pesquisaConfig } = configs || {};
  const { tabela, campoPesq1, campoPesq2, campoPesq3, campoPesq4, campoPesq5 } =
    pesquisaConfig || {};

  const { isLoading, isFetching, data, error } = useGetOptionsQuery(
    {
      url: publicFormField ? 'services/public' : 'services',
      name: publicFormField ? 'FormularioPublico' : 'Formulario',
      method:
        tipo === 'PESQUISA'
          ? 'listPesquisaCampoByFormularioCampoID'
          : 'listOpcoesCampoByFormularioCampoID',
      body: request.data,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: request.skip,
    },
  );

  const {
    data: additionalConfigs,
    isLoading: loadingAdditionalConfigs,
    isFetching: fetchingAdditionalConfigs,
  } = useGetFieldOptionsConfigQuery(tabela || '', {
    skip: tipo !== 'PESQUISA' || !tabela,
    refetchOnMountOrArgChange: true,
  });
  const fields = additionalConfigs?.campos || [];

  const sortOptions = (optionsForSorting: Option[]) => {
    if (groupBy)
      return optionsForSorting.sort((a, b) => {
        if (b.group && a.group) return -b.group.localeCompare(a.group);

        return -1;
      });

    return optionsForSorting.sort((a: Option, b: Option) => {
      if (a.value > b.value) return 1;

      return b.value > a.value ? -1 : 0;
    });
  };

  const buildOptions = (
    fieldOptions: Option[] = [],
    apiOptions?: OptionField[],
  ) => {
    if (requestData && apiOptions) {
      const formattedOptions = apiOptions.map(option => {
        const formattedOption = {
          label: option.descricao,
          value: option.valor,
          ...(option.imagem && {
            image: {
              field: option.imagem.campo,
              indexes: option.imagem.indices,
              instance: option.imagem.instancia,
            },
          }),
        };

        const additionalValue = [
          campoPesq1,
          campoPesq2,
          campoPesq3,
          campoPesq4,
          campoPesq5,
        ];

        additionalValue.forEach((v, index) => {
          if (v) {
            const config = fields.find(f => f.id === v);
            const originalValue = option[`campoPesq${index + 1}`];

            Object.assign(formattedOption, {
              [`field${index + 1}`]: {
                label: config?.descricao || '',
                value: getValue(originalValue, config),
                originalValue:
                  config?.opcoes && config?.opcoes.length > 0
                    ? undefined
                    : originalValue,
              },
            });
          }
        });

        return formattedOption;
      });

      return sortOptions(formattedOptions);
    }

    if (groupBy) return sortOptions(fieldOptions);

    return fieldOptions;
  };

  const getOptions = useCallback(
    (params: object) =>
      setRequest({
        data: {
          ...requestData,
          ...params,
        },
        skip: false,
      }),
    [requestData],
  );

  const optionsField = requestData
    ? buildOptions(undefined, data?.opcoes)
    : buildOptions(options);

  return {
    isLoading:
      isLoading ||
      isFetching ||
      loadingAdditionalConfigs ||
      fetchingAdditionalConfigs,
    error,
    options: optionsField,
    getOptions,
  };
};

export default useFieldOptions;
