import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  formInterface,
  pageInterface,
  componentInterface,
  editPropsPayload,
  setComponentsPayload,
  setPagePayload,
  setComponentPayload,
} from 'core/types/formAdmin';

const initialState: formInterface = {
  id: 0,
  name: 'Formulário 1',
  description: 'formulario de cadastro',
  selectPage: 0,
  selectComponent: 0,
  pages: [
    {
      id: 0,
      name: 'Página 1',
      components: [],
    },
  ],
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    resetToDefault: () => initialState,
    setSelectPage: (state, action: PayloadAction<number>) => {
      state.selectPage = action.payload;
    },
    setSelectComponent: (state, action) => {
      state.selectComponent = action.payload;
    },
    addComponent: (state, action: PayloadAction<componentInterface>) => {
      state.pages[state.selectPage].components = [
        ...state.pages[state.selectPage].components,
        action.payload,
      ];
    },
    editPropsComponent: (state, action: PayloadAction<editPropsPayload>) => {
      const { prop, value } = action.payload;
      state.pages[state.selectPage].components[state.selectComponent].props[
        prop
      ] = value;
    },
    deleteComponent: (state, action: PayloadAction<number>) => {
      const components = state.pages[state.selectPage].components.filter(
        component => component.id !== action.payload,
      );
      state.pages[state.selectPage].components = components;
    },
    setPages: (state, action: PayloadAction<Array<pageInterface>>) => {
      state.pages = action.payload;
    },
    setComponents(state, action: PayloadAction<setComponentsPayload>) {
      let page = 0;
      const { indexPage, components } = action.payload;
      if (indexPage !== undefined) {
        page = indexPage;
      } else if (state.selectPage !== -1) {
        page = state.selectPage;
      }
      state.pages[page].components = components;
    },
    setPage(state, action: PayloadAction<setPagePayload>) {
      const { pageUpdate, indexPage } = action.payload;
      let page = 0;
      if (indexPage !== undefined) {
        page = indexPage;
      } else if (state.selectPage !== -1) {
        page = state.selectPage;
      }
      state.pages[page] = pageUpdate;
    },
    setComponent(state, action: PayloadAction<setComponentPayload>) {
      const { componentUpdate, indexPage, indexComponent } = action.payload;
      let page = 0;
      let component = 0;

      if (indexPage !== undefined) {
        page = indexPage;
      } else if (state.selectPage !== -1) {
        page = state.selectPage;
      }

      if (indexComponent !== undefined) {
        component = indexComponent;
      } else if (state.selectComponent !== -1) {
        component = state.selectComponent;
      }
      state.pages[page].components[component] = componentUpdate;
    },
  },
});

export const {
  resetToDefault,
  setSelectPage,
  setSelectComponent,
  addComponent,
  editPropsComponent,
  deleteComponent,
  setPages,
  setComponents,
  setPage,
  setComponent,
} = slice.actions;

export default slice.reducer;
