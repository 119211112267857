import { Field } from 'core/models/config';
import { Option } from 'core/models/option';

import mask from './mask';
import { fDateTime } from './formatTime';

export const renderOptionValue = (
  value: string | number,
  options: Option[],
) => {
  const option = options.find(o => o.valor === value);

  return option?.descricao || '';
};

export const formatValue = (value: any, configField?: Field) => {
  const { mascara, tipo } = configField || {};

  if (mascara) return mask(value, mascara);

  if (tipo === 'TIMESTAMP') return fDateTime(value);

  if (tipo === 'BLOB') return <div>imagem</div>;

  return value;
};

export const getValue = (value: any, configField?: Field) => {
  const { apresentacao } = configField || {};

  const options = configField?.opcoes || [];

  if (options && options.length > 0) return renderOptionValue(value, options);

  if (Array.isArray(value)) {
    switch (apresentacao) {
      case 'AMBOS':
        return value.join(' - ');

      case 'DESCRICAO':
        return value[1];

      default:
        return value[0];
    }
  }

  return formatValue(value, configField);
};
