import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetPortalConfigQuery } from 'core/services/portalApi';
import WhatsAppButton from 'components/elements/WhatsAppButton';

const External: React.FC = () => {
  const { data } = useGetPortalConfigQuery({ publico: true });
  const { enableWhatsapp, msgWhatsapp, foneWhatsapp } = data || {};
  const whatsAppLink = `https://wa.me/${foneWhatsapp?.replace(
    / /g,
    '',
  )}?text=${msgWhatsapp}`;
  return (
    <>
      <Outlet />
      {enableWhatsapp && <WhatsAppButton whatsAppLink={whatsAppLink} />}
    </>
  );
};

export default External;
