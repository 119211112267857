/* eslint-disable @typescript-eslint/no-explicit-any */
import { ElementType, Suspense } from 'react';
import ProgressBarLoading from 'components/elements/ProgressBarLoading';

export default (Component: ElementType) =>
  function loader(props: any) {
    return (
      <Suspense fallback={<ProgressBarLoading />}>
        <Component {...props} />
      </Suspense>
    );
  };
