import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { useSelector } from 'core/redux/store';

import { PageProps } from './props';

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...other }, ref) => {
    const layout = useSelector(state => state.layout);

    return (
      <>
        <Helmet>
          <title>{title ? `${title} | ${layout.title}` : layout.title}</title>
        </Helmet>

        <div ref={ref} {...other}>
          {children}
        </div>
      </>
    );
  },
);

export default Page;
