import React, { useState } from 'react';
import { TextField, InputAdornment, Stack } from '@mui/material';
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';

import useOrder from 'core/hooks/useOrder';
import SearchWithHistory from 'components/widgets/SearchWithHistory';
import { NEW_ORDER_PATH } from 'core/routes/@portal/dashboardRoutes';
import Iconify from 'components/elements/Iconify';
import { SearchProps } from './props';
import { SearchContainer } from './styles';

const Search: React.FC<SearchProps> = ({ publicSearch, ...other }) => {
  const [searchParams] = useSearchParams();
  const [inputValue, setinputValue] = useState(searchParams.get('q') || '');
  const [searching, setSearching] = useState(false);
  const navigate = useNavigate();
  const { orderConfig, preOrderFormValues, currentStep, tableConfig } =
    useOrder();
  const { tabelaProdutoID, prePedidoType } = orderConfig || {};
  const preOrderNotFilled = prePedidoType !== 'NENHUM' && !preOrderFormValues;
  const preOrderStep = currentStep === 0 && prePedidoType !== 'NENHUM';
  const searchDisabled = preOrderNotFilled || preOrderStep;

  const handleSearch = (q: string | undefined) => {
    if (!q) return;

    if (publicSearch) {
      navigate({
        pathname: '/busca',
        search: createSearchParams({
          q,
        }).toString(),
      });
    }

    if (orderConfig && !publicSearch) {
      navigate({
        pathname: `${NEW_ORDER_PATH}/busca`,
        search: createSearchParams({
          q,
        }).toString(),
      });
    }
  };

  if (publicSearch)
    return (
      <Stack width="100%">
        <TextField
          onFocusCapture={() => setSearching(true)}
          onBlur={() => setSearching(false)}
          {...other}
          value={inputValue}
          onChange={e => setinputValue(e.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter') handleSearch(inputValue);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ pl: 1.5, pr: 0.5 }}>
                <Iconify
                  icon="eva:search-fill"
                  sx={{ width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    );

  if (!orderConfig) return null;

  return (
    <SearchContainer searching={searching}>
      <SearchWithHistory
        tableId={tabelaProdutoID || ''}
        value={inputValue}
        onSearch={handleSearch}
        onChange={e => setinputValue(e.target.value)}
        placeholder="Buscar produtos"
        size="small"
        searching={searching}
        sx={theme => ({
          flex: 1,

          '& .MuiInputBase-sizeSmall': {
            borderRadius: 28,
            backgroundColor: theme.palette.grey[0],
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            paddingLeft: '6px !important',
            height: '44px',
          },
          '& .Mui-focused': {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        })}
        onFocusCapture={() => setSearching(true)}
        onBlur={() => setSearching(false)}
        type="search"
        disabled={searchDisabled}
        habilitaQrCode={orderConfig.habilitaQrCode}
        historicoPesquisa={tableConfig?.historicoPesquisa}
        {...other}
      />
    </SearchContainer>
  );
};

export default Search;
