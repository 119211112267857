function sort<T>(
  arr: T[],
  prop: keyof T,
  direction: 'asc' | 'desc' = 'asc',
): T[] {
  return arr.slice().sort((a, b) => {
    const aValue = a[prop];
    const bValue = b[prop];

    if (aValue < bValue) {
      return direction === 'asc' ? -1 : 1;
    }

    if (aValue > bValue) {
      return direction === 'asc' ? 1 : -1;
    }

    return 0;
  });
}

export default sort;
