import React from 'react';
import { Stack, Typography, Button } from '@mui/material';

import PortalNotFoundElement from 'assets/illustrations/PortalNotFound';

const PortalNotFound: React.FC = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        svg: {
          width: '100%',
          maxWidth: '100%',
          mb: 5,
        },
      }}
      maxWidth={472}
      margin="auto"
      textAlign="center"
      p={3}
    >
      <PortalNotFoundElement />
      <Typography variant="h5" mb={1}>
        O portal que você está tentando acessar ainda não foi criado.
      </Typography>

      <Typography variant="body2" color="text.secondary" mb={5}>
        Você pode criar agora mesmo um novo portal com este endereço ou tentar
        acessar novamente digitando um endereço existente.
      </Typography>

      <Button
        variant="contained"
        size="large"
        href="https://zydon.com.br"
        target="__blank"
        rel="noopener"
        sx={{
          backgroundColor: '#CDEB00',
          color: '#000',
          boxShadow: 'none !important',
          mb: 5,

          '&:hover': {
            backgroundColor: '#CDEB00',
          },
        }}
      >
        Criar agora meu portal
      </Button>

      <img src="/assets/images/b2b-logo-grey.svg" alt="logo" />
    </Stack>
  );
};

export default PortalNotFound;
