import { FormWithPagesAndFields } from 'core/models/formWithPagesAndFields';
import { ApiRequest, FormRequest } from 'core/types/apiRequest';

import api from './base/api';

interface GetFormRequest extends ApiRequest {
  url: string;
}
interface CreateFormRequest extends ApiRequest {
  publico?: boolean;
  formulario: string;
  dados: object;
}

interface CalculateFormFieldRequest extends ApiRequest {
  url: string;
}

interface CalculateFormFieldResponse {
  campos: { campo: string; valor: string }[];
}

interface NewAddressRequest {
  publico?: boolean;
  formulario: string;
  campo: string;
  cep: string;
  bairro: string;
  logradouro: string;
  controle: object;
}

export const formsApi = api.injectEndpoints({
  endpoints: build => ({
    getForm: build.query<FormWithPagesAndFields, GetFormRequest>({
      query: ({ name, method, url, body }) => ({
        url,
        method: 'POST',
        body,
        params: {
          name,
          method,
        },
      }),
    }),
    saveForm: build.mutation<object, FormRequest>({
      query: ({ name, method, publico, body }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        body,
        params: {
          name,
          method,
        },
      }),
    }),
    createRegistro: build.mutation<
      {
        id: string;
        mensagem?: string;
        sucessoAutomacao?: boolean;
        status?: string;
      },
      CreateFormRequest
    >({
      query: ({ name, method, publico, dados, formulario }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name,
          method,
        },
        body: {
          formulario,
          dados,
        },
      }),
    }),
    calculateFormField: build.query<
      CalculateFormFieldResponse,
      CalculateFormFieldRequest
    >({
      query: ({ url, name, method, body }) => ({
        url,
        method: 'POST',
        params: {
          name,
          method,
        },
        body,
      }),
    }),
    newAddress: build.mutation<{ controle: object }, NewAddressRequest>({
      query: ({
        publico,
        bairro,
        campo,
        cep,
        controle,
        formulario,
        logradouro,
      }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name: publico ? 'FormularioPublico' : 'Formulario',
          method: 'novoEndereco',
        },
        body: {
          bairro,
          campo,
          cep,
          controle,
          formulario,
          logradouro,
        },
      }),
    }),
  }),
});

export const {
  useGetFormQuery,
  useLazyGetFormQuery,
  useSaveFormMutation,
  useCreateRegistroMutation,
  useLazyCalculateFormFieldQuery,
  useNewAddressMutation,
} = formsApi;
