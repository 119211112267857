import { useState, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { dynamicForm } from 'core/utils/form';
import { PagedFields } from 'core/types/pagedFields';
import { FormField } from 'core/models/formField';
import { FormConditional } from 'core/models/formWithPagesAndFields';
import useDeepCompareEffect from 'core/hooks/useDeepCompareEffect';

import { StepForm } from './types';

const useForm = (
  pagedFields: PagedFields[],
  conditions: FormConditional[],
  fieldId?: string,
  fieldIndex?: number,
  applyReset?: boolean,
) => {
  const sortedForm = useMemo(
    () =>
      pagedFields
        .map(f => {
          const fields = f.fields.slice().sort((a, b) => a.ordem - b.ordem);

          return { ...f, fields };
        })
        .slice()
        .sort((a, b) => a.ordem - b.ordem),
    [pagedFields],
  );

  const sortedConditions = useMemo(
    () => conditions.flat().sort((a, b) => a.ordem - b.ordem),
    [conditions],
  );

  const scenarios = sortedConditions.map(c => c.cenarios.map(ce => ce)).flat();
  const scenarioFieldsId = Array.from(
    new Set(
      scenarios.map(sce => {
        if (fieldId !== undefined && fieldIndex !== undefined)
          return `${fieldId}.${fieldIndex}.${sce.se}`;

        return sce.se;
      }),
    ),
  );

  const { trigger } = useFormContext();
  const formValues = useWatch();
  const values = useWatch({ name: scenarioFieldsId });

  const [activeStep, setActiveStep] = useState('');
  const [form, setForm] = useState<StepForm>({
    steps: [],
    nav: [],
  });

  const STEPS = form.steps
    .filter(s => s.visible || s.visible === undefined)
    .map(s => s.titulo);
  const STEP_INDEX = form.nav.indexOf(activeStep);

  const goStep = (step: string) => setActiveStep(step);

  const handleClickNextStep = async (pageFields: FormField[]) => {
    const fieldsToValidate = pageFields.map(f => f.id);
    const valid = await trigger(fieldsToValidate, {
      shouldFocus: true,
    });

    if (!valid) return;

    goStep(form.nav[STEP_INDEX + 1]);
  };

  useDeepCompareEffect(() => {
    const { steps, nav } = dynamicForm(
      sortedForm,
      sortedConditions,
      formValues,
      fieldId,
      fieldIndex,
    );

    setForm({ steps, nav });
    setActiveStep(state => (!state ? nav[0] : state));
  }, [values, sortedForm, sortedConditions, formValues, fieldId, fieldIndex]);

  useDeepCompareEffect(() => {
    if (applyReset) setActiveStep(form.nav[0]);
  }, [applyReset, form]);

  return {
    form,
    STEPS,
    STEP_INDEX,
    activeStep,
    goStep,
    handleClickNextStep,
  };
};

export default useForm;
