import { Stack, styled } from '@mui/material';

type StepProps = {
  active: boolean;
};

export const Step = styled(Stack, {
  shouldForwardProp: prop => prop !== 'active',
})<StepProps>(
  ({ active }) =>
    !active && { visibility: 'hidden', height: 0, overflow: 'hidden' },
);
