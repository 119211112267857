import { Address } from 'core/models/address';
import { Addresses } from 'core/models/orderAddressesConfig';

export const PICK_UP_ON_SITE = 'Retirada no local';

const buildAddresses = (items: Addresses[]): Address[] => {
  const formattedAddresses =
    items.map(item => {
      const fixedValues: Record<string | number, any> = item.camposFixos.reduce(
        (accFixedValues, currValue) => {
          const value = currValue.valor;

          return {
            ...accFixedValues,
            [currValue.referencia]: Array.isArray(value) ? value[1] : value,
          };
        },
        {},
      );

      const values = item.valores.reduce((accValues, currValue) => {
        const value = currValue.valor;

        return {
          ...accValues,
          [currValue.nomeCampo]: Array.isArray(value) ? value[0] : value,
        };
      }, {});

      const pk =
        item.indices.find(i => i.nomeCampo === 'CODCONTATO')?.valor ?? '0';

      return {
        ...fixedValues,
        ...values,
        '@pks': Array.isArray(pk) ? pk.join('-').toString() : pk,
        number: fixedValues.NUMERO,
        neighborhood: fixedValues.BAIRRO,
        complement: fixedValues.COMPLEMENTO,
        city: fixedValues.CIDADE,
        state: fixedValues.ESTADO,
        publicPlace: fixedValues.LOGRADOURO,
        zipCode: fixedValues.CEP,
      };
    }) || [];

  return formattedAddresses.sort((a, b) => {
    return a['@pks'].toString().localeCompare(b['@pks'].toString(), undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};

const labelAddress = (item: Address) => {
  if (item['@pks'] === -1) return PICK_UP_ON_SITE;

  const {
    number = '',
    neighborhood = '',
    complement = '',
    city = '',
    state = '',
    publicPlace = '',
    zipCode = '',
  } = item;

  return `${publicPlace} ${neighborhood}${
    number ? ` N° ${number}` : ''
  } ${city} / ${state} - ${zipCode}${complement ? ` ${complement}` : ''}`;
};

export { buildAddresses, labelAddress };
