import { alpha, Theme, styled } from '@mui/material/styles';
import { contrastText } from 'theme/palette';

import { LabelVariant } from './props';

export const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      color: string;
      variant: LabelVariant;
    };
  }) => {
    const isLight = theme.palette.mode === 'light';
    const { color, variant } = ownerState;

    const styleFilled = (colorParam: string) => ({
      color: contrastText(colorParam),
      backgroundColor: colorParam,
    });

    const styleOutlined = (colorParam: string) => ({
      color: colorParam,
      backgroundColor: 'transparent',
      border: `1px solid ${colorParam}`,
    });

    const styleGhost = (colorParam: string) => ({
      color: colorParam,
      backgroundColor: alpha(colorParam, 0.16),
    });

    return {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 6,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightBold,

      ...(color !== 'default'
        ? {
            ...(variant === 'filled' && { ...styleFilled(color) }),
            ...(variant === 'outlined' && { ...styleOutlined(color) }),
            ...(variant === 'ghost' && { ...styleGhost(color) }),
          }
        : {
            ...(variant === 'outlined' && {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.grey[500]}`,
            }),
            ...(variant === 'ghost' && {
              color: isLight
                ? theme.palette.text.secondary
                : theme.palette.common.white,
              backgroundColor: theme.palette.grey[500],
            }),
          }),
    };
  },
);
