import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Radio,
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import { decode } from 'core/utils/jwt';
import Iconify from 'components/elements/Iconify';
import SearchInput from 'components/elements/SearchInput';
import { User } from 'core/models/user';
import { dispatch, useSelector } from 'core/redux/store';
import { useChangePartnerMutation } from 'core/services/userApi';
import { refreshCredentials } from 'core/redux/slices/auth';
import EmptyContent from 'components/elements/EmptyContent';
import useCart from 'core/hooks/useCart';

import { PartnerChangeProps } from './props';
import { Partner, PartnerLabel, PartnerLabelContent } from './styles';

const PartnerChange: React.FC<PartnerChangeProps> = ({
  open,
  onClose,
  partners,
}) => {
  const navigate = useNavigate();
  const { changePartner: changePartnerCart } = useCart();
  const token = useSelector(state => state.auth.token);
  const { parceiro: parceiroSelect } = decode<User>(token);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<string>(
    parceiroSelect?.id.toString() || '',
  );
  const [filterName, setFilterName] = useState<string | undefined>('');

  const [changePartner, { isLoading }] = useChangePartnerMutation();

  const handleChangePartner = async () => {
    try {
      if (selected) {
        const credentials = await changePartner(selected).unwrap();
        const { usuario, parceiro, vendedor, sub } = decode<User>(
          credentials.accessToken,
        );
        dispatch(
          refreshCredentials({
            ...credentials,
            userData: {
              sub,
              user: usuario,
              partner: parceiro,
              seller: vendedor,
            },
          }),
        );
        navigate('/');
        changePartnerCart();
      }
    } catch {
      enqueueSnackbar('Erro ao trocar parceiro', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };

  const partnersFiltered = useMemo(() => {
    if (!filterName || filterName === '') return partners;
    return partners.filter(partner => {
      return partner.nome
        .concat(partner.id)
        .concat(partner.cnpj)
        .concat(partner.razaoSocial)
        .toLowerCase()
        .includes(filterName.toLowerCase());
    });
  }, [filterName, partners]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" scroll="body">
      <DialogActions
        sx={{
          zIndex: 9,
          padding: '16px 24px !important',
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Typography variant="subtitle1" marginRight="auto">
          Selecione um parceiro
        </Typography>

        <Tooltip title="Fechar">
          <IconButton color="inherit" onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Tooltip>
      </DialogActions>
      <Stack px={3} pb={2} gap={2}>
        <Box
          sx={{
            backgroundColor: 'background.neutral',
            border: '1px solid',
            borderColor: 'divider',
            p: 1.5,
            borderRadius: 1,
            mt: 3,
          }}
        >
          <SearchInput
            trigger="change"
            onSearch={value => setFilterName(value)}
            fullWidth
            size="small"
            iconPosition="start"
            placeholder="Buscar parceiro"
            sx={theme => ({
              '& .MuiInputBase-root': {
                background: theme.palette.background.paper,
              },
            })}
          />
        </Box>
        <Stack gap={1} width={1}>
          {partnersFiltered.length === 0 && (
            <Stack sx={{ height: 200 }}>
              <EmptyContent title="" description="Não há dados" sx={{ p: 1 }} />
            </Stack>
          )}
          {partnersFiltered.map(partner => (
            <Partner
              sx={{ padding: 1 }}
              key={partner.id}
              variant="outlined"
              selected={selected === partner.id}
            >
              <PartnerLabel
                value={1}
                checked={selected === partner.id}
                control={<Radio />}
                label={
                  <PartnerLabelContent sx={{ sm: { margin: 1 } }}>
                    <Stack width="100%" pr={2}>
                      <Typography variant="subtitle2" color="grey.700" noWrap>
                        {`${partner.id} - ${partner.nome}`}
                      </Typography>

                      <Typography variant="caption" color="grey.600" noWrap>
                        Razão social: {partner.razaoSocial}
                      </Typography>
                      <Typography variant="caption" color="grey.600" noWrap>
                        CNPJ: {partner.cnpj}
                      </Typography>
                      {partner.contato && (
                        <Typography variant="caption" color="grey.600" noWrap>
                          Contato: {partner.contato.nome}
                        </Typography>
                      )}
                    </Stack>
                  </PartnerLabelContent>
                }
                onClick={() => setSelected(partner.id)}
              />
            </Partner>
          ))}
        </Stack>
        <Stack
          gap={1}
          py={2}
          spacing={1}
          direction="row"
          justifyContent="flex-end"
        >
          <LoadingButton
            size="medium"
            variant="contained"
            disabled={selected === parceiroSelect?.id.toString()}
            loading={isLoading}
            onClick={handleChangePartner}
          >
            Trocar parceiro
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PartnerChange;
