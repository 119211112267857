import { Stack, Button, styled, keyframes, Card, alpha } from '@mui/material';
import Color from 'color';

interface CheckoutButtonProps {
  savingOrder?: boolean;
}

export const Container = styled(Stack)({
  flex: 1,
  maxWidth: 344,

  button: {
    span: {
      display: 'none',
    },
  },
});

const checkoutButtonAnimation = keyframes`
  from {
    width: 0;
  }

  to {
    width: '98%',
  }
`;

export const CheckoutButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'savingOrder',
})<CheckoutButtonProps>(({ savingOrder, theme }) => ({
  position: 'relative',
  overflow: 'hidden',

  div: {
    display: 'flex',
    position: 'relative',
    zIndex: 2,
  },

  ...(savingOrder && {
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '100%',
      width: '98%',
      backgroundColor:
        Color(theme.palette.primary.main).luminosity() <= 0.25
          ? 'rgba(255, 255, 255,.1)'
          : 'rgba(0, 0, 0,.175)',
      animation: `${checkoutButtonAnimation} 5s ease`,
      zIndex: 1,
    },

    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }),
}));

export const ErrorCard = styled(Card)(({ theme }) => ({
  padding: 24,
  backgroundColor: alpha(theme.palette.warning.main, 0.05),
  border: `1px solid ${alpha(theme.palette.warning.main, 0.24)}`,

  '& ul': {
    padding: '8px 24px',
  },
}));
