import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import LoadingScreen from 'components/LoadingScreen';
import useIsMounting from 'core/hooks/useIsMounting';
import { useSelector } from 'core/redux/store';
import { useGetLayoutQuery } from 'core/services/layoutApi';
import { getImageByType } from 'core/utils/image';
import PortalNotFound from 'views/PortalNotFound';
import PortalUnavailable from 'views/PortalUnavailable';

import Page from '../Page';

import { LayoutProps } from './props';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isMounting = useIsMounting();
  const { tipoImagemFavicon, imagemFavicon } = useSelector(
    state => state.layout.data,
  );
  const { isLoading, error } = useGetLayoutQuery(undefined);

  const showLoading = isMounting || isLoading;

  const favicon = useMemo(
    () => getImageByType(tipoImagemFavicon, imagemFavicon),
    [imagemFavicon, tipoImagemFavicon],
  );

  useEffect(() => {
    document.body.style.overflow = showLoading ? 'hidden' : '';
  }, [showLoading]);

  if (error && 'status' in error && error.status === 424) {
    return (
      <Page title="Portal não encontrado">
        <PortalNotFound />
      </Page>
    );
  }

  if (error && 'status' in error && error.status === 500) {
    return (
      <Page title="Portal não encontrado">
        <PortalUnavailable />
      </Page>
    );
  }

  if (showLoading) {
    return (
      <Page title="Carregando...">
        <LoadingScreen showLoading={showLoading} />
      </Page>
    );
  }

  return (
    <>
      <Page title="">
        <Helmet>
          <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
        </Helmet>

        {children}
      </Page>
    </>
  );
};

export default Layout;
