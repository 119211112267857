import { PortalConfig } from 'core/models/portalConfig';

import api from './base/api';

export const portalApi = api.injectEndpoints({
  endpoints: build => ({
    getPortalConfig: build.query<PortalConfig, { publico?: boolean } | void>({
      query: publico => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name: 'Portal',
          method: 'getConfiguracao',
        },
        body: {},
      }),
    }),
  }),
});

export const { useGetPortalConfigQuery } = portalApi;
