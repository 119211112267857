import { styled, Stack, Typography } from '@mui/material';

export const Details = styled(Stack)(({ theme }) => ({
  padding: 16,
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  gap: 4,
}));

export const Value = styled(Typography)({});

export const PaymentDataContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  borderRadius: 8,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: 16,

  [theme.breakpoints.down('lg')]: {
    margin: 'auto',
  },
}));

export const OrderDataContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: '16px 40px',
}));
