import { useWatch } from 'react-hook-form';

const notFilledValue = (value: any) => {
  const valueType = typeof value;

  switch (valueType) {
    case 'bigint':
    case 'number': {
      if (value === 0) return false;

      return !value;
    }
    case 'object': {
      if (!value) return true;

      if (Array.isArray(value)) return value.length === 0;

      return Object.values(value).some(notFilledValue);
    }
    default:
      return !value;
  }
};

const useFieldDependencies = (dependsOn: string[] = []) => {
  const dependsOnFieldsValues = useWatch({ name: dependsOn });

  const dependencyFieldsNotFilled = dependsOnFieldsValues.some(notFilledValue);

  return {
    dependsOnFieldsId: dependsOn,
    dependsOnFieldsValues,
    dependencyFieldsNotFilled,
  };
};

export default useFieldDependencies;
