import React from 'react';

import { CircularProgress } from '@mui/material';
import useFieldOptions from 'core/hooks/useFieldOptions';
import { LabelValueFieldOptionProps } from './props';

const LabelValueFieldOption: React.FC<LabelValueFieldOptionProps> = ({
  field,
  value,
  formID,
  argumentos,
}) => {
  const requestData = {
    formulario: formID,
    campo: field.id,
    argumentos,
  };
  const { options: autocompleteOptions, isLoading } = useFieldOptions({
    requestData,
    publicFormField: false,
    configs: field,
  });

  if (isLoading) return <CircularProgress size={20} />;

  const itemSelected = autocompleteOptions?.find(
    o => o.value?.toString() === value?.toString(),
  );

  return <>{itemSelected?.label || value}</>;
};

export default LabelValueFieldOption;
