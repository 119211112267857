import { lazy } from 'react';
import Iconify from 'components/elements/Iconify';
import { useSelector } from 'core/redux/store';
import {
  NEW_ORDER_PATH,
  SCREEN_PATH,
} from 'core/routes/@portal/dashboardRoutes';
import { Icon } from 'core/types/icon';
import loadable from 'core/utils/loadable';

import useRedirection from './useRedirection';
import useOrder from './useOrder';

const usePortalNavigation = () => {
  const { menuItems } = useSelector(state => state.menuItems);
  const { pathToRedirect } = useRedirection(false, false);

  const menus = menuItems
    .map(menu => ({
      title: menu.titulo,
      path: `${SCREEN_PATH}/${menu.id}`,
      icon: <Iconify icon={Icon[menu.icone]} />,
      order: menu.ordem,
      Element: loadable(lazy(() => import('views/@portal/Screen'))),
    }))
    .sort((a, b) => a.order - b.order);
  const { orderConfigId } = useOrder();

  if (menuItems.length === 0) return [];

  if (pathToRedirect?.includes(NEW_ORDER_PATH)) {
    return [
      {
        subheader: '',
        items: [
          {
            title: 'Início',
            path: pathToRedirect,
            icon: <Iconify icon="ic:outline-home" />,
            order: -999,
            state: {
              orderConfigId,
            },
          },
          ...menus,
        ],
      },
    ];
  }

  return [
    {
      subheader: '',
      items: [...menus],
    },
  ];
};

export default usePortalNavigation;
