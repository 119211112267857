import { useTheme } from '@mui/material';
import React from 'react';

const FormSuccess: React.FC = () => {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <svg
      width="318"
      height="234"
      viewBox="0 0 318 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_15970_135279"
        maskUnits="userSpaceOnUse"
        x="3"
        y="0"
        width="91"
        height="90"
      >
        <path
          d="M12.8553 67.4485C4.83342 98.6767 62.1907 91.0558 66.4303 78.3354C72.4468 60.286 63.1482 59.1744 83.3337 43.9557C101.956 29.9173 86.4851 8.14355 85.0526 6.13807C75.7443 -6.89756 59.2679 9.57603 49.2405 4.70558C39.2131 -0.164873 10.6333 -0.904035 10.6333 19.6034C10.6333 27.6254 5.18982 33.9283 4.33033 41.6637C2.44827 58.6014 16.2715 54.1493 12.8553 67.4485Z"
          fill="white"
          stroke="#0019FF"
          strokeWidth="1.14599"
        />
      </mask>
      <g mask="url(#mask0_15970_135279)">
        <path
          d="M98.2314 -2.17041L8.84424 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M92.5009 -2.17041L3.11377 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 3.55957L8.84424 92.9467"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 9.28955L8.84424 98.6767"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 15.0195L8.84424 104.407"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 20.7495L8.84424 110.137"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 26.4795L8.84424 115.867"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 32.2095L8.84424 121.597"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 37.9395L8.84424 127.327"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 43.6694L8.84424 133.057"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 49.3994L8.84424 138.787"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 55.1289L8.84424 144.516"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 60.8589L8.84424 150.246"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M98.2314 66.5889L8.84424 155.976"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M86.7709 -2.17041L-2.61621 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M81.0414 -2.17041L-8.3457 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M75.3115 -2.17041L-14.0757 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M69.5815 -2.17041L-19.8057 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M63.8515 -2.17041L-25.5356 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M58.1215 -2.17041L-31.2656 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M52.3915 -2.17041L-36.9956 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M46.6616 -2.17041L-42.7256 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M40.9316 -2.17041L-48.4556 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M35.2016 -2.17041L-54.1855 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
        <path
          d="M29.4716 -2.17041L-59.9155 87.2167"
          stroke={primary.main}
          strokeWidth="1.14599"
        />
      </g>
      <path
        d="M278.474 25.6997H34.6332C32.6016 25.6997 30.9546 27.3467 30.9546 29.3783V210.157C30.9546 212.188 32.6016 213.835 34.6332 213.835H278.474C280.505 213.835 282.152 212.188 282.152 210.157V29.3783C282.152 27.3467 280.505 25.6997 278.474 25.6997Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M30.4292 40.4138H39.8885C41.0499 40.4138 41.9906 39.4731 41.9906 38.3117V31.48C41.9906 30.3186 42.9313 29.3779 44.0927 29.3779H73.5217C74.6831 29.3779 75.6238 30.3186 75.6238 31.48V38.3117C75.6238 39.4731 76.5645 40.4138 77.7258 40.4138H282.678"
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M79.8276 30.9545C79.8276 30.0821 80.5318 29.3779 81.4042 29.3779H85.6083C86.4807 29.3779 87.1849 30.0821 87.1849 30.9545V35.1586C87.1849 36.031 86.4807 36.7352 85.6083 36.7352H81.4042C80.5318 36.7352 79.8276 36.031 79.8276 35.1586V30.9545Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M85.0829 32.5312H81.9298C81.6396 32.5312 81.4043 32.7665 81.4043 33.0568C81.4043 33.347 81.6396 33.5823 81.9298 33.5823H85.0829C85.3732 33.5823 85.6084 33.347 85.6084 33.0568C85.6084 32.7665 85.3732 32.5312 85.0829 32.5312Z"
        fill={primary.main}
      />
      <path
        d="M82.981 31.4801V34.6332C82.981 34.9234 83.2162 35.1587 83.5065 35.1587C83.7967 35.1587 84.032 34.9234 84.032 34.6332V31.4801C84.032 31.1898 83.7967 30.9545 83.5065 30.9545C83.2162 30.9545 82.981 31.1898 82.981 31.4801Z"
        fill={primary.main}
      />
      <path
        d="M271.642 25.6997H278.474C280.508 25.6997 282.152 27.3446 282.152 29.3783V32.0059H271.642V25.6997Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M261.131 25.6997H271.642V32.0059H264.81C262.776 32.0059 261.131 30.3611 261.131 28.3273V25.6997Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M303.008 140.788H235.808C234.121 140.788 232.753 142.156 232.753 143.843V224.133C232.753 225.82 234.121 227.188 235.808 227.188H303.008C304.695 227.188 306.062 225.82 306.062 224.133V143.843C306.062 142.156 304.695 140.788 303.008 140.788Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M257.626 211.042H297.335"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M257.626 197.952H297.335"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M257.626 184.86H297.335"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M257.626 171.77H297.335"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M257.626 158.679H297.335"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M248.026 203.188H242.79C242.067 203.188 241.48 203.774 241.48 204.497V209.733C241.48 210.456 242.067 211.042 242.79 211.042H248.026C248.749 211.042 249.335 210.456 249.335 209.733V204.497C249.335 203.774 248.749 203.188 248.026 203.188Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M248.026 190.097H242.79C242.067 190.097 241.48 190.683 241.48 191.406V196.643C241.48 197.366 242.067 197.952 242.79 197.952H248.026C248.749 197.952 249.335 197.366 249.335 196.643V191.406C249.335 190.683 248.749 190.097 248.026 190.097Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M248.026 177.006H242.79C242.067 177.006 241.48 177.592 241.48 178.315V183.552C241.48 184.275 242.067 184.861 242.79 184.861H248.026C248.749 184.861 249.335 184.275 249.335 183.552V178.315C249.335 177.592 248.749 177.006 248.026 177.006Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M248.026 163.916H242.79C242.067 163.916 241.48 164.502 241.48 165.225V170.461C241.48 171.184 242.067 171.77 242.79 171.77H248.026C248.749 171.77 249.335 171.184 249.335 170.461V165.225C249.335 164.502 248.749 163.916 248.026 163.916Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M248.026 150.824H242.79C242.067 150.824 241.48 151.41 241.48 152.133V157.37C241.48 158.093 242.067 158.679 242.79 158.679H248.026C248.749 158.679 249.335 158.093 249.335 157.37V152.133C249.335 151.41 248.749 150.824 248.026 150.824Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M306.66 164.075L317.237 170.184L300.053 199.949L288.656 207.476L289.472 193.84L306.66 164.075Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M288.167 208.321L292.239 205.633L288.46 203.451L288.167 208.321Z"
        fill={primary.main}
      />
      <path
        d="M304.099 167.636L315.435 174.181"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M289.262 193.333L300.599 199.878"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M307.72 169.661L292.666 195.736"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M311.879 172.061L296.824 198.136"
        stroke={primary.main}
        strokeWidth="0.872722"
      />
      <path
        d="M243.663 153.442L245.626 155.406L252.826 148.206"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M243.663 166.97L245.626 168.933L252.826 161.733"
        stroke={primary.main}
        strokeWidth="0.872722"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.387 158.224C171.072 158.224 187.84 141.456 187.84 120.772C187.84 100.087 171.072 83.3193 150.387 83.3193C129.703 83.3193 112.935 100.087 112.935 120.772C112.935 141.456 129.703 158.224 150.387 158.224Z"
        fill={primary.light}
      />
      <path
        d="M124.81 113.007L136.685 101.132L152.671 117.118L188.296 81.4922L200.172 93.3673L152.671 140.868L124.81 113.007Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="1.82695"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.205 112.55L138.969 120.315"
        stroke={primary.main}
        strokeWidth="1.82695"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.9147 138.686H26.7504C24.7188 138.686 23.0718 140.333 23.0718 142.365V218.039C23.0718 220.071 24.7188 221.718 26.7504 221.718H91.9147C93.9463 221.718 95.5933 220.071 95.5933 218.039V142.365C95.5933 140.333 93.9463 138.686 91.9147 138.686Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M85.6084 144.992H33.0566C31.0249 144.992 29.3779 146.639 29.3779 148.671V207.529C29.3779 209.561 31.0249 211.207 33.0566 211.207H85.6084C87.64 211.207 89.287 209.561 89.287 207.529V148.671C89.287 146.639 87.64 144.992 85.6084 144.992Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M35.1587 159.707L44.0925 150.773"
        stroke={primary.main}
        strokeWidth="1.05104"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8374 159.969L43.8298 154.977"
        stroke={primary.main}
        strokeWidth="1.05104"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3323 142.89C59.9128 142.89 60.3833 142.42 60.3833 141.839C60.3833 141.259 59.9128 140.788 59.3323 140.788C58.7518 140.788 58.2812 141.259 58.2812 141.839C58.2812 142.42 58.7518 142.89 59.3323 142.89Z"
        fill={primary.main}
      />
      <path
        d="M59.3325 218.564C60.4935 218.564 61.4346 217.623 61.4346 216.462C61.4346 215.301 60.4935 214.36 59.3325 214.36C58.1716 214.36 57.2305 215.301 57.2305 216.462C57.2305 217.623 58.1716 218.564 59.3325 218.564Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M33.0566 178.1H4.67863C2.64698 178.1 1 179.747 1 181.778V229.075C1 231.107 2.64698 232.754 4.67863 232.754H33.0566C35.0883 232.754 36.7352 231.107 36.7352 229.075V181.778C36.7352 179.747 35.0883 178.1 33.0566 178.1Z"
        fill="white"
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M1 181.778C1 179.747 2.64697 178.1 4.67863 178.1H33.0566C35.0883 178.1 36.7352 179.747 36.7352 181.778V183.355H1V181.778Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M18.8674 181.778C19.4479 181.778 19.9185 181.308 19.9185 180.727C19.9185 180.147 19.4479 179.676 18.8674 179.676C18.287 179.676 17.8164 180.147 17.8164 180.727C17.8164 181.308 18.287 181.778 18.8674 181.778Z"
        fill={primary.main}
      />
      <path
        d="M1 227.499H36.7352V229.075C36.7352 231.107 35.0883 232.754 33.0566 232.754H4.67863C2.64697 232.754 1 231.107 1 229.075V227.499Z"
        fill={primary.light}
        stroke={primary.main}
        strokeWidth="1.05104"
      />
      <path
        d="M4.67871 197.018L13.6125 188.084"
        stroke={primary.main}
        strokeWidth="1.05104"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.35742 197.281L13.3498 192.289"
        stroke={primary.main}
        strokeWidth="1.05104"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FormSuccess;
