import { IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Iconify from 'components/elements/Iconify';

import { validatePassword } from 'core/utils/validators';

import Field from '../Field';
import { PasswordFieldProps } from './props';

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  sx,
  rules,
  passwordSize,
  specialCharacter,
  upperCaseChar,
  numberChar,
  ...other
}) => {
  const { watch } = useFormContext();
  const password = watch(name);
  const [showPassword, setShowPassword] = useState(false);
  const {
    hasNumberChar,
    hasSpecialCharacter,
    hasUpperCaseChar,
    isLengthValid,
  } = validatePassword(
    password,
    passwordSize,
    numberChar,
    specialCharacter,
    upperCaseChar,
  );

  return (
    <Field
      {...other}
      name={name}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
              aria-label="Mostrar/Ocultar senha"
            >
              <Iconify
                icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      rules={{
        ...rules,
        validate: {
          ...rules?.validate,
          validationPassword: (v: string) => {
            const validation = validatePassword(
              v,
              passwordSize,
              numberChar,
              specialCharacter,
              upperCaseChar,
            );

            if (
              !validation.hasNumberChar ||
              !validation.hasSpecialCharacter ||
              !validation.hasUpperCaseChar ||
              !validation.isLengthValid
            )
              return 'Senha inválida';

            return true;
          },
        },
      }}
      sx={{
        ...sx,
        '& .MuiFormHelperText-root': {
          display:
            !hasSpecialCharacter ||
            !hasUpperCaseChar ||
            !isLengthValid ||
            !hasNumberChar
              ? 'none'
              : undefined,
        },
      }}
    />
  );
};

export default PasswordField;
