import { forwardRef } from 'react';
import { IconButton, InputAdornment } from '@mui/material';

import { isMultiple } from 'core/utils/number';
import { fWithDecimalPlacesOrNot } from 'core/utils/formatNumber';
import Iconify from '../Iconify';
import NumberInput from '../NumberInput';

import { IncrementerProps } from './props';

const Incrementer = forwardRef<HTMLDivElement, IncrementerProps>(
  (
    {
      name,
      increment = 1,
      updadteForMultiple,
      allowNegative = false,
      value = 0,
      onChange,
      minValue,
      maxValue,
      decimalScale,
      disabled,
      ...other
    },
    ref,
  ) => {
    const multiple = isMultiple(value, increment, decimalScale);

    const handleClickDecrement = () => {
      if (!allowNegative && value - increment < 0) return;

      const newValue = value - increment;
      const prevMultiple = Math.floor(value / increment) * increment;
      const setValue =
        !multiple && updadteForMultiple ? prevMultiple : newValue;

      if (minValue !== undefined && newValue < minValue) {
        onChange(minValue);
      } else {
        onChange(fWithDecimalPlacesOrNot(setValue));
      }
    };

    const handleClickIncrement = () => {
      const newValue = Number(value) + increment;
      const nextMultiple = Math.ceil(value / increment) * increment;
      const setValue =
        !multiple && updadteForMultiple ? nextMultiple : newValue;
      const setValueFormatted = fWithDecimalPlacesOrNot(setValue);

      if (maxValue !== undefined && newValue >= maxValue) {
        onChange(maxValue);
      } else {
        onChange(
          minValue !== undefined && setValueFormatted <= minValue
            ? minValue
            : setValueFormatted,
        );
      }
    };

    const handleChange = (e: {
      target: { name: string; value: string | number | undefined | null };
    }) => {
      const newValue = Number(e.target.value);
      const nextMultiple = Math.ceil(newValue / increment) * increment;
      const setValue = updadteForMultiple ? nextMultiple : newValue;

      if (minValue !== undefined && newValue <= minValue) {
        onChange(minValue);
        return;
      }

      if (maxValue !== undefined && newValue >= maxValue) {
        onChange(maxValue);
        return;
      }

      onChange(setValue);
    };

    return (
      <NumberInput
        ref={ref}
        name={name}
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                size="medium"
                disabled={disabled || (allowNegative ? false : !value)}
                onClick={handleClickDecrement}
              >
                <Iconify
                  icon="eva:minus-fill"
                  width={14}
                  height={14}
                  color="primary.main"
                />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="medium"
                onClick={handleClickIncrement}
                disabled={disabled}
              >
                <Iconify
                  icon="eva:plus-fill"
                  width={14}
                  height={14}
                  color="primary.main"
                />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            style: {
              textAlign: 'center',
            },
          },
        }}
        onChange={handleChange}
        disabled={disabled}
        {...other}
      />
    );
  },
);

export default Incrementer;
