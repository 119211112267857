import { createSlice } from '@reduxjs/toolkit';

import { userMenuItemsApi } from 'core/services/userMenuItemsApi';
import { MenuItemState } from './types';

const initialState: MenuItemState = {
  loading: true,
  menuItems: [],
};

const slice = createSlice({
  name: 'menuItems',
  initialState,
  reducers: {
    resetMenus: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(
      userMenuItemsApi.endpoints.getMenuItems.matchPending,
      () => {
        return {
          loading: true,
          menuItems: [],
        };
      },
    );
    builder.addMatcher(
      userMenuItemsApi.endpoints.getMenuItems.matchFulfilled,
      (_, action) => {
        return {
          loading: false,
          menuItems: action.payload.itensMenu || [],
        };
      },
    );
    builder.addMatcher(
      userMenuItemsApi.endpoints.getMenuItems.matchRejected,
      () => {
        return {
          loading: false,
          error: true,
          menuItems: [],
        };
      },
    );
  },
});

export const { resetMenus } = slice.actions;

export default slice.reducer;
