import { useCallback, useState } from 'react';

const useToggle = (
  initialState = false,
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState(currentState => !currentState), []);

  return [state, toggle, setState];
};

export default useToggle;
