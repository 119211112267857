import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormConditional } from 'core/models/formWithPagesAndFields';
import { useLazyCalculateFormFieldQuery } from 'core/services/formsApi';
import { condition } from 'core/utils/conditions';
import { getSceneryValue } from 'core/utils/form';
import useDeepCompareEffect from './useDeepCompareEffect';

const useCalculatedField = (
  formId: string,
  conditions: FormConditional[],
  publicForm: boolean,
) => {
  const calculationConditions = useMemo(
    () => conditions.filter(c => c.tipo === 'ATUALIZAR_CALCULAR_CAMPO'),
    [conditions],
  );
  const { getValues, setValue } = useFormContext();
  const values = getValues();

  const [calculationData, setCalculationData] = useState<object[]>([]);

  const [trigger, { isFetching, isLoading }] = useLazyCalculateFormFieldQuery();

  useDeepCompareEffect(() => {
    const data: object[] = [];

    calculationConditions.forEach(c => {
      const conditionsResults = c.cenarios.map(scenery => {
        return condition(
          getSceneryValue(scenery.se, values),
          scenery.estado,
          scenery.valor,
        );
      });

      const executeActions =
        c.regra === 'TODAS'
          ? conditionsResults.every(cr => cr)
          : conditionsResults.some(cr => cr);

      if (executeActions) {
        data.push({
          formulario: formId,
          condicional: c.id,
          campos: c.cenarios.map(f => ({ campo: f.se, valor: values[f.se] })),
        });
      }
    });

    if (data.length > 0) setCalculationData(data);
  }, [formId, calculationConditions, values]);

  useDeepCompareEffect(() => {
    if (calculationData.length === 0) return;

    calculationData.forEach(body => {
      trigger({
        url: publicForm ? 'services/public' : 'services',
        name: publicForm ? 'FormularioPublico' : 'Formulario',
        method: 'calculateFormularioCamposByFormularioCondicionalId',
        body,
      }).then(res =>
        res.data?.campos.forEach(c =>
          setTimeout(() => setValue(c.campo, c.valor), 300),
        ),
      );
    });
  }, [trigger, publicForm, calculationData]);

  return {
    calculatingConditions: isFetching || isLoading,
  };
};

export default useCalculatedField;
