import React from 'react';
import { TableRow, TableCell } from '@mui/material';

import EmptyContent from '../EmptyContent';

type Props = {
  isNotFound: boolean;
  title: string;
};

const TableNoData: React.FC<Props> = ({ isNotFound, title }) => {
  return (
    <TableRow sx={{ backgroundColor: 'grey.0' }}>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title=""
            description={title}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
};

export default TableNoData;
