import React from 'react';
import { Stack, Typography, Button } from '@mui/material';

import PortalUnavailableElement from 'assets/illustrations/PortalUnavailable';
import Iconify from 'components/elements/Iconify';

const PortalUnavailable: React.FC = () => {
  const refresh = () => window.location.reload();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        svg: {
          width: '100%',
          maxWidth: '100%',
          mb: 5,
        },
      }}
      maxWidth={510}
      margin="auto"
      textAlign="center"
      p={3}
    >
      <PortalUnavailableElement />
      <Typography variant="h5" color="text.secondary" my={1}>
        PORTAL INDISPONÍVEL NO MOMENTO
      </Typography>

      <Typography variant="body2" color="text.secondary" mb={5}>
        Estamos passando por uma manutenção e em breve estaremos de volta
      </Typography>
      <Stack>
        <Button
          variant="contained"
          size="large"
          onClick={refresh}
          color="success"
          sx={{
            mb: 5,
            '& svg': {
              mb: 0,
            },
          }}
          startIcon={<Iconify icon="ic:sharp-refresh" />}
        >
          Atualizar página
        </Button>
      </Stack>
    </Stack>
  );
};

export default PortalUnavailable;
