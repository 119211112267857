// eslint-disable-next-line no-shadow
export enum Validation {
  NENHUM = 'NENHUM',
  ALFABETICA = 'ALFABETICA',
  MOEDA = 'MOEDA',
  EMAIL = 'EMAIL',
  NUMERICO = 'NUMERICO',
  URL = 'URL',
  SEM_VALORES_DECIMAIS = 'SEM_VALORES_DECIMAIS',
  CPF_CNPJ = 'CPF_CNPJ',
  TELEFONE = 'TELEFONE',
  CEP = 'CEP',
}
