/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { memo } from 'react';
import { Box, Stack } from '@mui/material';

import LogoB2B from 'components/elements/LogoB2B';
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';

const NavSectionMini: React.FC<NavSectionProps> = ({ data, sx, ...other }) => {
  return (
    <Stack
      height="100%"
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      gap={3}
      {...other}
    >
      <Stack spacing={0.5}>
        {data.map((group, index) => (
          <Items
            key={group.subheader}
            items={group.items}
            isLastGroup={index + 1 === data.length}
          />
        ))}
      </Stack>

      <Stack
        spacing={1}
        alignItems="center"
        mt="auto"
        sx={{ color: 'grey.500' }}
      >
        <LogoB2B showVersionText={false} />
      </Stack>
    </Stack>
  );
};

export default memo(NavSectionMini);

type ItemsProps = {
  items: NavListProps[];
  isLastGroup: boolean;
};

const Items: React.FC<ItemsProps> = ({ items, isLastGroup }) => {
  return (
    <>
      {items.map(list => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
        />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
};
