const getImageByType = (type?: 'ARQUIVO' | 'URL', image?: string) => {
  if (type === 'ARQUIVO' && image) return `data:image/png;base64,${image}`;
  if (type === 'URL' && image) return image;

  return undefined;
};

const base64Image = (value?: string, type: 'IMAGE' | 'SVG' = 'IMAGE') => {
  if (!value) return null;

  const formattedValue = value.replace(/data:image\/.*;base64,/, '');

  if (type === 'IMAGE') return `data:image/jpeg;base64,${formattedValue}`;

  return `data:image/svg+xml;base64,${formattedValue}`;
};

export { getImageByType, base64Image };
