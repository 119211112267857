import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Main from './Main';
import NavVertical from './nav/NavVertical';

const DashboardLayout: React.FC = () => {
  const renderNavVertical = <NavVertical />;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: 1,
      }}
    >
      {renderNavVertical}

      <Main>
        <Outlet />
      </Main>
    </Box>
  );
};

export default DashboardLayout;
