import React from 'react';
import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { FormProps } from './props';

const Form: React.FC<FormProps> = ({ id, children, onSubmit, methods }) => {
  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        id={id}
        onSubmit={onSubmit}
        flex={1}
        noValidate
        autoComplete="off"
      >
        {children}
      </Stack>
    </FormProvider>
  );
};

export default Form;
