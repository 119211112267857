import React, { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import { useSelector } from 'core/redux/store';

import { ENVIROMENT_KEY, ENVIROMENT_SKW } from 'core/config';
import palette, { buildPalette } from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const color = useSelector(state => state.layout.primaryColor);

  const colorHx = color ? `#${color.replace('#', '')}` : undefined;

  const customPallete = useMemo(
    () => (colorHx ? buildPalette(colorHx) : undefined),
    [colorHx],
  );

  const { themeMode, themeDirection } = {
    themeMode: 'light',
    themeDirection: 'ltr',
  } as const;

  const environmentIsSKW = params.get(ENVIROMENT_KEY) === ENVIROMENT_SKW;
  const defaultPalette =
    customPallete && !environmentIsSKW ? customPallete : palette(themeMode);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: defaultPalette,
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode, colorHx),
    }),
    [defaultPalette, themeDirection, themeMode, colorHx],
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
