import React from 'react';
import { Typography, Divider, Stack, useTheme } from '@mui/material';
import { B2B_LINK, VERSION } from 'core/config';
import { useSelector } from 'core/redux/store';

import { LogoB2BProps } from './props';
import Logo from '../Logo';

const LogoB2B: React.FC<LogoB2BProps> = ({
  divider,
  forceLogo,
  showVersionText = true,
}) => {
  const { exibirMarcaDagua } = useSelector(state => state.layout.data);
  const theme = useTheme();

  return (
    <Stack alignItems="center" justifyContent="center">
      {(exibirMarcaDagua || forceLogo) && (
        <>
          <a
            href={B2B_LINK}
            target="_blank"
            rel="noreferrer"
            style={{
              color: theme.palette.primary.main,
            }}
          >
            {showVersionText ? (
              <img src="/assets/images/b2b-logo-grey.svg" alt="logo" />
            ) : (
              <Logo />
            )}
          </a>

          {divider && <Divider orientation="vertical" flexItem />}
        </>
      )}
      {showVersionText && (
        <Typography variant="caption" align="center">
          Versão {VERSION}
        </Typography>
      )}
    </Stack>
  );
};

export default LogoB2B;
