import React from 'react';
import { Stack } from '@mui/material';

import ChangeDiscountButton from '../ChangeDiscountButton';

import { DiscountProps } from './props';

const Discount: React.FC<DiscountProps> = ({
  discountType = 'VALUE',
  onChangeDiscountType,
  discountPercent,
  discountValue,
  orderConfig,
}) => {
  const { habilitaDecontoPercentual, habilitaDecontoValor } = orderConfig;

  if (habilitaDecontoPercentual && habilitaDecontoValor) {
    return (
      <Stack direction="row" gap={1}>
        <ChangeDiscountButton
          value={discountType}
          onChange={(_, newView) => onChangeDiscountType(newView)}
        />
        {discountType === 'VALUE' && discountValue}

        {discountType === 'PERCENT' && discountPercent}
      </Stack>
    );
  }

  if (habilitaDecontoValor) return <Stack>{discountValue}</Stack>;

  if (habilitaDecontoPercentual) return <Stack>{discountPercent}</Stack>;

  return null;
};

export default Discount;
