import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'core/hooks/useAuth';
import Login from 'views/@auth/Login';

import { AuthGuardProps } from './types';

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const {
    auth: { token },
  } = useAuth();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null,
  );

  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
