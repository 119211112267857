import { NavConfig } from './types';

type Route = {
  path: string;
  Element: () => JSX.Element;
};

const buildDashRoutes = (navConfig: NavConfig[]) => {
  return navConfig.reduce((acc: Route[], route) => {
    route.items.forEach(item => {
      if (item.children && item.children?.length > 0) {
        item.children.forEach(subItem => {
          if (subItem.Element) {
            acc.push({
              path: subItem.path,
              Element: subItem.Element,
            });
          }
        });
      } else if (item.Element) {
        acc.push({ path: item.path, Element: item.Element });
      }
    });

    return acc;
  }, []);
};

export default buildDashRoutes;
