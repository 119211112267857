import React from 'react';
import { List, Stack } from '@mui/material';
import LogoB2B from 'components/elements/LogoB2B';

import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';

const NavSectionVertical: React.FC<NavSectionProps> = ({
  data,
  sx,
  ...other
}) => {
  return (
    <Stack
      sx={sx}
      {...other}
      height="calc(100% - 115px)"
      justifyContent="space-between"
      gap={3}
    >
      {data.map(group => {
        const key =
          group.subheader ||
          (group.items.length > 0 && group.items[0].title) ||
          Math.random().toString();

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>
            )}

            {group.items.map(list => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
      <Stack
        spacing={1}
        alignItems="center"
        mt="auto"
        mb={2}
        sx={{ color: 'grey.500' }}
      >
        <LogoB2B />
      </Stack>
    </Stack>
  );
};

export default NavSectionVertical;
