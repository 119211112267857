import { Partner } from 'core/models/partner';
import { Tokens } from 'core/models/auth';
import api from './base/api';

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    updateFavorite: build.mutation<object, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'updateProdutoFavorito',
        },
        body,
      }),
    }),
    changePartner: build.mutation<Tokens, string>({
      query: parceiro => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'changePartner',
        },
        body: {
          parceiro,
        },
      }),
    }),
    getPartners: build.query<{ parceiros: Partner[] }, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'listParceiros',
        },
        body: {},
      }),
    }),
  }),
});

export const {
  useUpdateFavoriteMutation,
  useChangePartnerMutation,
  useGetPartnersQuery,
} = userApi;
