import React, { useCallback, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { unEscape } from 'core/utils/html';

import PaymentBuilder from 'components/widgets/PaymentBuilder';
import { PaymentGateway } from 'components/widgets/PaymentBuilder/props';
import useCart from 'core/hooks/useCart';
import useOrder from 'core/hooks/useOrder';
import useOrderCheckout from 'core/hooks/useOrderCheckout';
import {
  NEW_ORDER_PATH,
  ORDER_DETAIL_PATH,
} from 'core/routes/@portal/dashboardRoutes';
import { Orders } from 'core/types/orderCalculation';
import { labelAddress } from 'core/utils/addresses';
import { objectToArrayObject } from 'core/utils/array';
import {
  fCurrencyBRL,
  fDecimalNumber,
  fThousandIntegerNumber,
} from 'core/utils/formatNumber';
import mask from 'core/utils/mask';
import { Pagamento } from 'core/models/orderPaymentConfig';
import ClientLogo from 'components/elements/ClientLogo';
import useArguments from 'core/hooks/useArguments';
import { ErrorCard } from 'components/widgets/OrderSummary/styles';
import Iconify from 'components/elements/Iconify';
import { useSnackbar } from 'notistack';
import { ParcelaPayment } from 'core/models/paymentMethod';
import { paymentProcess } from 'core/utils/payment';
import sort from 'core/utils/sort';
import LabelValueFieldOption from 'components/widgets/LabelValueFieldOption';
import { FieldType } from 'core/models/formFieldType';
import {
  Details,
  OrderDataContainer,
  PaymentDataContainer,
  Value,
} from './styles';
import CheckoutItemsViewer from '../NewOrder/CheckoutItemsViewer';

const Payment: React.FC = () => {
  const {
    preOrderFormFields,
    preOrderFormValues,
    preOrderFormTitle,
    additionalFormValues,
    additionalFormFields,
    additionalFormTitle,
    selectedAddress,
    selectedPaymentMethod,
    orderConfig,
    cartKey,
    clearCurrentOrder,
    contextOfArguments: contextOfArgumentsOrder,
    updateOrderStateStoredValues,
  } = useOrder();
  const { prePedidoType } = orderConfig || {};
  const { cartTotal, items } = useCart();
  const { removeArgumentContext, argumentsContext } = useArguments(
    contextOfArgumentsOrder,
  );
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onOrderSucess = useCallback(
    (ordersResponse: Orders) => {
      const { pedidos } = ordersResponse;
      const orderId = pedidos[0].id;
      const orders = pedidos.map(p => p.id);

      clearCurrentOrder();
      removeArgumentContext('');

      navigate(`${ORDER_DETAIL_PATH}/${orderId}`, {
        state: {
          orderId,
          orders,
        },
      });
    },
    [clearCurrentOrder, navigate, removeArgumentContext],
  );

  const onOrderFail = useCallback(
    message => {
      enqueueSnackbar(message, {
        variant: 'error',
        preventDuplicate: false,
      });
    },
    [enqueueSnackbar],
  );

  const preOrderFormValuesFormatted = objectToArrayObject(
    preOrderFormValues,
    'campo',
    'valor',
  ).filter(i => i.valor);

  const additionalFormValuesFormatted = objectToArrayObject(
    additionalFormValues,
    'campo',
    'valor',
  ).filter(i => i.valor);
  const paymentParcela = selectedPaymentMethod?.parcelas?.find(
    p => p.parcela === selectedPaymentMethod?.parcelaSelecionada,
  );

  const [parcela, setParcela] = useState<ParcelaPayment | undefined>(
    paymentParcela,
  );
  const [pagamento, setPagamento] = useState<Pagamento | undefined>(undefined);

  const {
    totalOrderAmount,
    calculatingSummary,
    summary,
    paymentMethodMessage,
    savingOrder,
  } = useOrderCheckout({
    cartKey,
    orderConfig,
    preOrderFormValues: preOrderFormValuesFormatted,
    additionalFormValues: additionalFormValuesFormatted,
    address: selectedAddress ? selectedAddress['@pks'].toString() : undefined,
    labelAddress: selectedAddress ? labelAddress(selectedAddress) : undefined,
    paymentMethod:
      selectedPaymentMethod && paymentProcess(selectedPaymentMethod),
    pagamento,
    argumentos: objectToArrayObject(argumentsContext, 'chave', 'valor'),
    type: 'NEW_ORDER',
    cartTotal: cartTotal(),
    items,
    onOrderSucess,
    onOrderFail,
    checkoutFormHasRequiredFields: false,
  });

  const handleTokenGenerated = (value: Pagamento) => {
    setPagamento(value);
  };

  const validObjectValuePreOrder = field => {
    const valuesInFields = preOrderFormValues[field.id];
    if (typeof valuesInFields === 'object') {
      const valueObjectPreOrder = Object.values(valuesInFields);
      const hasValuesInObjectPreOrder = valueObjectPreOrder.some(v => !!v);
      if (hasValuesInObjectPreOrder) return true;

      return false;
    }

    return true;
  };

  const valueFieldForm = (field, value, formID) => {
    if (field.tipo === FieldType.SUB_FORM) {
      return `${field.tipo}`;
    }
    if (
      field.tipo === FieldType.MENU_SUSPENSO ||
      field.tipo === FieldType.SELECAO_UNICA ||
      field.tipo === FieldType.PESQUISA ||
      field.tipo === FieldType.SELECAO_MULTIPLA
    ) {
      if (field.tipoOpcoes === 'MANUAL') {
        const optionsFieldsPreOrder = field.opcoes?.find(
          o => o.valor === value,
        );
        if (optionsFieldsPreOrder) {
          if (Array.isArray(optionsFieldsPreOrder.descricao)) {
            return (
              field.opcoes?.find(o => o.valor === value)?.descricao || value
            ).join(' ');
          }
          return field.opcoes?.find(o => o.valor === value)?.descricao || value;
        }
        return value;
      }

      if (field.tipoOpcoes === 'TABELA') {
        return (
          <LabelValueFieldOption
            argumentos={objectToArrayObject(argumentsContext, 'chave', 'valor')}
            field={field}
            formID={formID || ''}
            value={value}
          />
        );
      }
    } else if (field.tipo === FieldType.SIM_NAO) {
      return <>{value === 'S' ? 'Sim' : 'Não'}</>;
    } else if (field.tipo === FieldType.INTERVALO_DATA) {
      return (
        <>
          {value?.dtIni || ''}
          {`${value?.dtIni && value?.dtFim ? ' a ' : ''}`}
          {value?.dtFim || ''}
        </>
      );
    } else if (field.tipo === FieldType.NUMERO_DECIMAL) {
      return fDecimalNumber(value);
    } else if (field.tipo === FieldType.NUMERO_INTEIRO) {
      return fThousandIntegerNumber(value);
    }
    return <>{value}</>;
  };

  const showPreOrderFormValues = () => {
    const preOrderShowFormFields =
      preOrderFormFields?.filter(field => !field.ocultar) || [];
    if (preOrderShowFormFields.length > 0) {
      const preOrderValuesShow = preOrderShowFormFields.filter(
        field => preOrderFormValues[field.id],
      );
      return preOrderValuesShow.length > 0;
    }
    return false;
  };

  const showAdditionalFormValues = () => {
    const additionalShowFormFields =
      additionalFormFields?.filter(field => !field.ocultar) || [];
    if (additionalShowFormFields.length > 0) {
      const additionValuesShow = additionalShowFormFields.filter(
        field => additionalFormValues[field.id],
      );
      return additionValuesShow.length > 0;
    }
    return false;
  };

  if (
    !orderConfig ||
    items.length === 0 ||
    !selectedAddress ||
    !selectedPaymentMethod?.tipo ||
    (prePedidoType !== 'NENHUM' &&
      (!preOrderFormValues || preOrderFormValuesFormatted.length === 0))
  )
    return <Navigate to={NEW_ORDER_PATH} />;

  return (
    <Container>
      <Stack mx="auto" mb={2} mt={8} alignItems="center">
        <ClientLogo
          sx={{
            '& svg, & img': {
              maxWidth: 250,
              height: 'auto',
              maxHeight: 100,
            },
          }}
        />
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        gap={3}
        alignItems={{
          lg: 'flex-start',
        }}
      >
        <Stack flex={1} gap={4.5}>
          {orderConfig.prePedidoType !== 'NENHUM' &&
            showPreOrderFormValues() && (
              <Card>
                <CardContent>
                  <Stack gap={3}>
                    <Typography variant="h6">{preOrderFormTitle}</Typography>

                    <Details>
                      {sort(
                        preOrderFormFields?.filter(field => !field.ocultar) ||
                          [],
                        'ordem',
                      ).map(field => (
                        <Value variant="body2">
                          <strong>{field.titulo}:</strong>{' '}
                          {valueFieldForm(
                            field,
                            preOrderFormValues[field.id],
                            orderConfig.formularioPrePedidoID,
                          )}
                        </Value>
                      ))}
                    </Details>
                  </Stack>
                </CardContent>
              </Card>
            )}

          <Card>
            <CardContent>
              <Stack gap={3}>
                <Typography variant="h6">
                  Endereço de entrega e forma de pagamento
                </Typography>

                <Details>
                  <Value variant="body2">
                    <strong>Entrega:</strong> {labelAddress(selectedAddress)}
                  </Value>
                  {selectedPaymentMethod && (
                    <Value variant="body2">
                      <strong>Forma de pagamento:</strong>{' '}
                      {selectedPaymentMethod?.descricao}
                    </Value>
                  )}
                </Details>
              </Stack>
            </CardContent>
          </Card>

          {showAdditionalFormValues() && (
            <Card>
              <CardContent>
                <Stack gap={3}>
                  <Typography variant="h6">{additionalFormTitle}</Typography>

                  <Details>
                    {sort(
                      additionalFormFields?.filter(field => !field.ocultar) ||
                        [],
                      'ordem',
                    ).map(field => {
                      if (
                        preOrderFormValues &&
                        validObjectValuePreOrder(field) &&
                        field.id in preOrderFormValues &&
                        preOrderFormValues[field.id]
                      ) {
                        return (
                          <Value variant="body2">
                            <strong>{field.titulo}</strong>:{' '}
                            {valueFieldForm(
                              field,
                              additionalFormValues[field.id],
                              orderConfig.checkout.formularioID,
                            )}
                          </Value>
                        );
                      }
                      return null;
                    })}
                  </Details>
                </Stack>
              </CardContent>
            </Card>
          )}

          <Stack gap={1}>
            <Typography variant="subtitle1">Itens do pedido</Typography>
            <CheckoutItemsViewer />
          </Stack>
        </Stack>

        <Stack>
          <PaymentDataContainer>
            <PaymentBuilder
              paymentID={selectedPaymentMethod.id}
              type={selectedPaymentMethod.tipo}
              parcelas={selectedPaymentMethod.parcelas}
              parcela={parcela}
              onSelectedParcela={(value: ParcelaPayment) => {
                setParcela(value);
                updateOrderStateStoredValues('selectedPaymentMethod', {
                  ...selectedPaymentMethod,
                  parcelaSelecionada: value.parcela,
                });
              }}
              paymentGateway={PaymentGateway.CIELO}
              onTokenGenerated={payment => handleTokenGenerated(payment)}
              savingOrder={savingOrder || calculatingSummary}
            />

            <OrderDataContainer>
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h6">Resumo</Typography>

                {calculatingSummary && <CircularProgress size={20} />}
              </Stack>

              <Stack gap={2}>
                <Stack
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  rowGap={1}
                  mt={3}
                >
                  <Typography variant="body2" color="grey.600">
                    Subtotal
                  </Typography>
                  <Typography variant="body2" flex={1} textAlign="right">
                    {fCurrencyBRL(cartTotal())}
                  </Typography>

                  {items.length > 0 &&
                    summary?.resumo.map(s => [
                      s.descricao && (
                        <Typography
                          key={`${s.id}key`}
                          variant="body2"
                          color="grey.600"
                        >
                          {s.descricao}
                        </Typography>
                      ),
                      s.mascara ? (
                        <Typography
                          key={`${s.id}value`}
                          variant="body2"
                          flex={1}
                          textAlign={s.descricao ? 'right' : 'justify'}
                          gridColumn={s.descricao ? 'span 1' : 'span 2'}
                        >
                          {mask(s.valor, s.mascara) || '-'}
                        </Typography>
                      ) : (
                        <Typography
                          key={`${s.id}value`}
                          variant="body2"
                          flex={1}
                          textAlign={s.descricao ? 'right' : 'justify'}
                          whiteSpace="pre-wrap"
                          gridColumn={s.descricao ? 'span 1' : 'span 2'}
                          dangerouslySetInnerHTML={{
                            __html: unEscape(s.valor?.toString()),
                          }}
                        />
                      ),
                    ])}
                </Stack>
                {items.length > 0 && summary && (
                  <>
                    <Divider />

                    <Stack direction="row">
                      <Typography variant="subtitle1" flex={1}>
                        Total
                      </Typography>
                      <Typography variant="subtitle1" color="primary.main">
                        {fCurrencyBRL(totalOrderAmount)}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </OrderDataContainer>
          </PaymentDataContainer>
          {paymentMethodMessage && (
            <Stack
              sx={{
                backgroundColor: 'grey.0',
                borderRadius: 2,
                mb: 2,
                maxWidth: 400,
              }}
            >
              <ErrorCard>
                <Stack direction="row" gap={1} mb={1} alignItems="center">
                  <Iconify
                    icon="eva:alert-triangle-outline"
                    color="warning.main"
                  />
                  <Typography variant="h6">Atenção</Typography>
                </Stack>

                <ul>
                  {paymentMethodMessage && (
                    <Typography variant="body2" color="grey.600" component="li">
                      {paymentMethodMessage}
                    </Typography>
                  )}
                </ul>
              </ErrorCard>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Payment;
