import { RouterProvider } from 'react-router-dom';

import { ENVIROMENT_KEY, ENVIROMENT_SKW } from 'core/config';
import adminRouter from './@admin';
import portalRouter from './@portal';

const Router: React.FC = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const environmentIsSKW = params.get(ENVIROMENT_KEY) === ENVIROMENT_SKW;

  if (environmentIsSKW) return <RouterProvider router={adminRouter} />;

  return <RouterProvider router={portalRouter} />;
};

export default Router;
