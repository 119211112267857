import { useState, useEffect } from 'react';

import { useLazyGetFormQuery } from 'core/services/formsApi';
import { buildPagedFields } from 'core/utils/form';
import { FormWithPagesAndFields } from 'core/models/formWithPagesAndFields';
import { PagedFields } from 'core/types/pagedFields';

const REQUEST = {
  DEFAULT: {
    url: 'services',
    name: 'Formulario',
    method: 'getById',
  },
  PUBLIC: {
    url: 'services/public',
    name: 'FormularioPublico',
    method: 'getByChave',
  },
  ACCESS_REQUEST: {
    url: 'services/public',
    name: 'SolicitacaoAcesso',
    method: 'getFormularioNovoCliente',
  },
  SHOWCASE: {
    url: 'services/public',
    name: 'Formulario',
    method: 'getById',
  },
};

const useDynamicForm = (
  formId: string,
  type: 'DEFAULT' | 'PUBLIC' | 'ACCESS_REQUEST' | 'SHOWCASE',
  onFormLoded?: (data: FormWithPagesAndFields) => void,
) => {
  const [trigger, { isLoading, isFetching, isUninitialized, isSuccess }] =
    useLazyGetFormQuery();

  const [form, setForm] = useState<FormWithPagesAndFields>();
  const [pagedFields, setPagedFields] = useState<PagedFields[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await trigger({
          ...REQUEST[type],
          body: { chave: formId, id: formId },
        });

        const { paginas = [], campos = [], tipo } = data || {};
        const formattedData = buildPagedFields(campos, paginas, tipo);

        setPagedFields(formattedData);
        setForm(data);

        if (onFormLoded && data) onFormLoded(data);
      } catch {
        console.log('error');
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, formId, type]);

  return {
    configFormId: form?.id,
    responseData: form,
    conditions: form?.condicionais || [],
    pagedFields,
    loading: isUninitialized || isLoading || isFetching,
    pagesMode: form?.tipo,
    isSuccess,
    formArguments: form?.argumentos,
  };
};

export default useDynamicForm;
