import { lazy } from 'react';

import loadable from 'core/utils/loadable';

const Login = {
  path: 'login',
  Element: loadable(lazy(() => import('views/@auth/Login'))),
  index: true,
};

const ResetPassword = {
  path: 'recuperar-senha',
  Element: loadable(lazy(() => import('views/@auth/Login/ResetPassword'))),
  index: true,
};

const EmailSuccess = {
  path: 'email-enviado-sucesso',
  Element: loadable(lazy(() => import('views/@auth/Login/EmailSuccess'))),
  index: true,
};

const NewPassword = {
  path: 'nova-senha/:keyAccess',
  Element: loadable(lazy(() => import('views/@auth/Login/NewPassword'))),
  index: true,
};

const RegisterForm = {
  path: 'formulario-registro',
  Element: loadable(lazy(() => import('views/@auth/Login/Register'))),
  index: true,
};

const StatusRegister = {
  path: 'status-registro',
  Element: loadable(
    lazy(() => import('views/@auth/Login/Register/StatusRegister')),
  ),
  index: true,
};

const ConfirmRegister = {
  path: 'confirmacao/:key',
  Element: loadable(
    lazy(() => import('views/@auth/Login/Register/ConfirmEmail')),
  ),
  index: true,
};
const SendConfirmRegister = {
  path: 'enviar-email-confirmacao',
  Element: loadable(
    lazy(() => import('views/@auth/Login/Register/SendConfirmEmail')),
  ),
  index: true,
};

const PublicForm = {
  path: 'public/form/:id',
  Element: loadable(lazy(() => import('views/PublicForm'))),
  index: false,
};

const RecordCreatedSuccessfully = {
  path: 'public/sucesso',
  Element: loadable(lazy(() => import('views/RecordCreatedSuccessfully'))),
  index: false,
};

const NotFound = {
  path: '404',
  Element: loadable(lazy(() => import('views/NotFound'))),
  index: false,
};

export const ServerError = {
  path: 'server-error',
  Element: loadable(lazy(() => import('views/ServerError'))),
  index: false,
};

const Showcase = {
  path: '',
  Element: loadable(lazy(() => import('views/Showcase'))),
  index: true,
};
const Category = {
  path: 'categoria/:id',
  Element: loadable(lazy(() => import('views/Showcase/Categories/Category'))),
  index: true,
};

const Search = {
  path: 'busca',
  Element: loadable(lazy(() => import('views/Showcase/Search'))),
};

const DatasheetFile = {
  path: 'ficha-tecnica/:key',
  Element: loadable(lazy(() => import('views/@portal/DatasheetFile'))),
};

export const EXTERNAL_ROUTES = [
  NotFound,
  PublicForm,
  RecordCreatedSuccessfully,
  ResetPassword,
  NewPassword,
  EmailSuccess,
  RegisterForm,
  StatusRegister,
  ConfirmRegister,
  SendConfirmRegister,
  DatasheetFile,
];
export const EXTERNAL_GUEST_ROUTES = [Showcase, Category, Search, Login];
