import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import api from 'core/services/base/api';

import authReducer from './slices/auth';
import cartReducer from './slices/cart';
import formReducer from './slices/form';
import layoutReducer from './slices/layout';
import menuItemsReducer from './slices/menuItems';
import orderReducer from './slices/order';
import argumentsReducer from './slices/arguments';
import tablesReducer from './slices/tables';
import myDataReducer from './slices/myData';
import dynamicFormFieldReducer from './slices/dynamicFormField';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: '@B2B/',
  whitelist: [],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: '@B2B/',
};

const layoutPersistConfig = {
  key: 'layout',
  storage,
  keyPrefix: '@B2B/',
  whitelist: ['title', 'primaryColor', 'themeDirection', 'isNavMini'],
};

const cartPersistConfig = {
  key: 'cart',
  storage,
  keyPrefix: '@B2B/',
};

const argumentsPersistConfig = {
  key: 'arguments',
  storage,
  keyPrefix: '@B2B/',
};

const ordersPersistConfig = {
  key: 'order',
  storage,
  keyPrefix: '@B2B/',
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  layout: persistReducer(layoutPersistConfig, layoutReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  order: persistReducer(ordersPersistConfig, orderReducer),
  arguments: persistReducer(argumentsPersistConfig, argumentsReducer),
  menuItems: menuItemsReducer,
  form: formReducer,
  tables: tablesReducer,
  myData: myDataReducer,
  dynamicFormField: dynamicFormFieldReducer,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
