import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export default (
  error: FetchBaseQueryError | SerializedError,
  defaultMessage: string,
) => {
  if ('data' in error) {
    if (
      error.data &&
      typeof error.data === 'object' &&
      'message' in error.data &&
      typeof error.data.message === 'string'
    ) {
      return error.data.message;
    }

    return defaultMessage;
  }

  return defaultMessage;
};
