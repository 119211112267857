import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

import Iconify from 'components/elements/Iconify';
import { NAV } from 'core/config';
import useActiveLink from 'core/hooks/useActiveLink';
import useLayout from 'core/hooks/useLayout';
import useResponsive from 'core/hooks/useResponsive';
import { NEW_ORDER_PATH } from 'core/routes/@portal/dashboardRoutes';

interface NavToggleButtonProps extends IconButtonProps {
  onClick(): void;
}

const NavToggleButton: React.FC<NavToggleButtonProps> = ({
  sx,
  onClick,
  ...other
}) => {
  const isDesktop = useResponsive('up', 'lg');
  const { isNavMini } = useLayout();
  const { active: newOrderPage } = useActiveLink(NEW_ORDER_PATH);

  if (!isDesktop) return null;

  return (
    <IconButton
      size="small"
      onClick={onClick}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_DASHBOARD - 12,
        bgcolor: 'grey.0',
        zIndex: theme => theme.zIndex.appBar + 1,
        border: theme => `dashed 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          isNavMini || newOrderPage
            ? 'eva:arrow-ios-forward-fill'
            : 'eva:arrow-ios-back-fill'
        }
      />
    </IconButton>
  );
};

export default NavToggleButton;
