import { Stack, alpha, styled } from '@mui/material';

export const RulesContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
  padding: 16,
  borderRadius: 8,
  gap: 8,
}));

export const Rules = styled(Stack)(() => ({
  gap: 4,
}));

export const Rule = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
  color: theme.palette.text.secondary,

  div: {
    fontSize: 12,
  },

  strong: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}));
