import { Button, styled } from '@mui/material';

type BoxCompaniesProps = {
  open?: boolean;
};

type CompanyButtonProps = {
  active?: boolean;
};

export const OptionsContainer = styled('div')({
  position: 'relative',

  '& > button': {
    marginTop: 68,
  },
});

export const BoxCompanies = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<BoxCompaniesProps>(({ open, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  position: 'absolute',
  left: 11,
  width: 257,
  height: 68,
  padding: '8px',
  borderRadius: '7px',
  overflow: 'hidden',
  zIndex: 999,
  backgroundColor: theme.palette.background.default,

  ...(open && {
    boxShadow:
      '0px 0px 2px rgba(145, 158, 171, 0.58), 0px 12px 24px -4px rgba(145, 158, 171, 0.24)',
    height: 'auto',
  }),
}));

export const CompanyButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<CompanyButtonProps>(({ active, theme }) => ({
  height: 58,
  color: theme.palette.text.primary,

  '& svg': {
    marginLeft: 'auto',
  },

  ...(active && {
    background: theme.palette.grey[200],
  }),
}));
