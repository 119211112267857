import { FormFieldState } from 'core/models/formFieldState';

export function condition(input: string, state: FormFieldState, value: any) {
  switch (state) {
    case 'COMECAR_COM':
      return input.startsWith(value);

    case 'CONTEM':
      return input.includes(value);

    case 'ESTIVER_PREENCHIDO':
      return !(!input || input.length === 0);

    case 'ESTIVER_VAZIO':
      return !input || input.length === 0;

    case 'FOR_IGUAL_A':
      return input === value;

    case 'NAO_COMECAR_COM':
      return !input.startsWith(value);

    case 'NAO_CONTEM':
      return !input.includes(value);

    case 'NAO_FOR_IGUAL_A':
      return input !== value;

    case 'NAO_TERMINAR_COM':
      return !input.endsWith(value);

    case 'TERMINAR_COM':
      return input.endsWith(value);

    default:
      return false;
  }
}
