import React from 'react';
import { Box, BoxProps } from '@mui/material';

import { HEADER, NAV } from 'core/config';
import useResponsive from 'core/hooks/useResponsive';
import { useSelector } from 'core/redux/store';

const SPACING = 8;

const Main: React.FC<BoxProps> = ({ children, sx, ...other }) => {
  const { themeDirection } = useSelector(state => state.layout);
  const isNavHorizontal = themeDirection === 'horizontal';
  const isDesktop = useResponsive('up', 'lg');

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 3,
            pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
            pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        mt: 1,
        flexGrow: 1,
        py: `${HEADER.H_MOBILE}px`,
        ...(isDesktop && {
          py: `${HEADER.H_DASHBOARD_DESKTOP}px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default Main;
