import React from 'react';
import { Typography } from '@mui/material';
import { validatePassword } from 'core/utils/validators';
import Iconify from '../Iconify';

import { PasswordRulesProps, RuleIconPros } from './props';
import { Rule, Rules, RulesContainer } from './styles';

const RuleIcon: React.FC<RuleIconPros> = ({ passed }) => {
  return passed ? (
    <Iconify icon="ic:round-check" color="success.main" />
  ) : (
    <Iconify icon="ic:outline-warning" color="warning.main" />
  );
};

const PasswordRules: React.FC<PasswordRulesProps> = ({
  password,
  passwordSize,
  numberChar = false,
  specialCharacter = false,
  upperCaseChar = false,
}) => {
  const {
    hasSpecialCharacter,
    hasUpperCaseChar,
    isLengthValid,
    hasNumberChar,
  } = validatePassword(
    password,
    passwordSize,
    numberChar,
    specialCharacter,
    upperCaseChar,
  );

  return (
    <RulesContainer>
      <Typography color="text.secondary" variant="body2">
        Sua senha deve conter:
      </Typography>

      <Rules>
        <Rule>
          <RuleIcon passed={isLengthValid} />
          <Typography component="div">
            <strong>
              {passwordSize[0]}-{passwordSize[1]}
            </strong>{' '}
            caracteres
          </Typography>
        </Rule>

        {upperCaseChar && (
          <Rule>
            <RuleIcon passed={hasUpperCaseChar} />
            <Typography component="div">
              Pelo menos uma <strong>letra maiúscula</strong>
            </Typography>
          </Rule>
        )}

        {numberChar && (
          <Rule>
            <RuleIcon passed={hasNumberChar} />
            <Typography component="div">
              Pelo menos <strong>um número</strong>
            </Typography>
          </Rule>
        )}

        {specialCharacter && (
          <Rule>
            <RuleIcon passed={hasSpecialCharacter} />
            <Typography component="div">
              Pelo menos um <strong>caracter especial</strong>{' '}
              <span>(#,@,!,?)</span>
            </Typography>
          </Rule>
        )}
      </Rules>
    </RulesContainer>
  );
};

export default PasswordRules;
