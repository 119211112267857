import { Pagamento } from 'core/models/orderPaymentConfig';
import { ParcelaPayment } from 'core/models/paymentMethod';

// eslint-disable-next-line no-shadow
export enum PaymentGateway {
  CIELO = 'CIELO',
}

export interface PaymentBuilderProps {
  paymentID: string;
  type: 'CARTAO_CREDITO' | 'PIX';
  parcelas?: ParcelaPayment[];
  parcela?: ParcelaPayment;
  paymentGateway?: PaymentGateway;
  onTokenGenerated?(payment: Pagamento): void;
  onSelectedParcela?(parcela: ParcelaPayment): void;
  savingOrder?: boolean;
}

export interface PaymentProps {
  paymentID: string;
  parcelas?: ParcelaPayment[];
  parcela?: ParcelaPayment;
  onTokenGenerated(payment: Pagamento): void;
  onSelectedParcela?(parcela: ParcelaPayment): void;
  savingOrder?: boolean;
}
