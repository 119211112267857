import { useCallback, useEffect, useRef } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { BaseField } from 'core/types/field';
import { Option } from 'core/types/option';
import { fieldDefaultValue } from 'core/utils/form';

import useFieldDependencies from './useFieldDependencies';
import useFieldOptions from './useFieldOptions';
import useDeepCompareEffect from './useDeepCompareEffect';

const useField = <T>(
  { name, rules, configs, formArguments, ...otherFieldProps }: BaseField<T>,
  requestData?: object,
  publicFormField = false,
) => {
  const { options: manualOptions } = otherFieldProps as Record<string, unknown>;

  const { dependeDe, tipo } = configs || {};

  const { control, getFieldState, setValue, ...context } = useFormContext();
  const { isDirty, dirtyFields } = useFormState();
  const { isDirty: fieldIsDirty } = getFieldState(name);

  const {
    dependsOnFieldsId,
    dependsOnFieldsValues,
    dependencyFieldsNotFilled,
  } = useFieldDependencies(dependeDe);

  const { getOptions, options, isLoading, error } = useFieldOptions({
    requestData,
    dependsOnFields: dependsOnFieldsId,
    options: manualOptions as Option[],
    publicFormField,
    configs,
  });

  const getFieldOptions = useCallback(() => {
    if (!dependencyFieldsNotFilled) {
      if (!requestData) return;

      const argumentos = dependsOnFieldsId.map((fieldId, index) => {
        return {
          chave: formArguments?.find(a => a.campoID === fieldId)?.argumento,
          valor: dependsOnFieldsValues[index],
        };
      });

      getOptions({
        ...requestData,
        argumentos,
      });
    }
  }, [
    getOptions,
    dependencyFieldsNotFilled,
    dependsOnFieldsValues,
    dependsOnFieldsId,
    requestData,
    formArguments,
  ]);

  const clearDependentField = useCallback(() => {
    setValue(name, tipo ? fieldDefaultValue(tipo) : '');
  }, [name, setValue, tipo]);

  const hasTriggerChanges = useRef(false);

  useEffect(() => {
    if (dependsOnFieldsId.some(d => dirtyFields[d])) {
      hasTriggerChanges.current = true;
    } else if (
      dependsOnFieldsId.length > 0 &&
      [...dependsOnFieldsId, name].every(d => dirtyFields[d])
    ) {
      hasTriggerChanges.current = true;
    } else {
      hasTriggerChanges.current = false;
    }
  }, [name, dependsOnFieldsId, dirtyFields, fieldIsDirty]);

  useEffect(() => {
    if (
      (!isDirty ||
        dependsOnFieldsValues.length === 0 ||
        dependsOnFieldsId.every(d => !dirtyFields[d])) &&
      !hasTriggerChanges.current
    ) {
      return;
    }

    clearDependentField();
  }, [
    isDirty,
    dirtyFields,
    dependsOnFieldsId,
    dependsOnFieldsValues,
    clearDependentField,
  ]);

  useDeepCompareEffect(() => {
    if (dependsOnFieldsId.length > 0) getFieldOptions();
  }, [getFieldOptions, dependsOnFieldsId]);

  useDeepCompareEffect(() => {
    if (options.length === 1) setValue(name, options[0].value);
  }, [options, isDirty]);

  return {
    ...context,
    setValue,
    controller: { control, name, rules },
    disabled: isLoading || dependencyFieldsNotFilled,
    options,
    isLoading,
    error,
    otherFieldProps,
    dependencyFieldsNotFilled,
  };
};

export default useField;
