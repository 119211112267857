import { lazy } from 'react';

import loadable from 'core/utils/loadable';
import Iconify from 'components/elements/Iconify';

import { NavConfig } from '../types';

const navConfig: NavConfig[] = [
  {
    subheader: 'geral',
    items: [
      {
        title: 'Início',
        path: '/',
        Element: loadable(lazy(() => import('views/@admin/Home'))),
        icon: <Iconify icon="ic:outline-home" />,
      },
      {
        title: 'Primeiros passos',
        path: '/first-steps',
        icon: <Iconify icon="ic:outline-mouse" />,
      },
      {
        title: 'Configurações',
        path: '/configs',
        icon: <Iconify icon="ic:outline-settings" />,
        children: [
          {
            title: 'Aparência',
            path: '/configs/appearance',
          },
          {
            title: 'Meios de pagamento',
            path: '/configs/payments-methods',
          },
          {
            title: 'Domínio',
            path: '/configs/domain',
          },
          {
            title: 'Atendimento',
            path: '/configs/attendance',
          },
          {
            title: 'Usuários',
            path: '/configs/users',
          },
        ],
      },
    ],
  },
  {
    subheader: 'módulos',
    items: [
      {
        title: 'Financeiro',
        path: '/financial',
        icon: <Iconify icon="ic:outline-attach-money" />,
      },
      {
        title: 'Pedido',
        path: '/orders',
        icon: <Iconify icon="ic:outline-shopping-cart" />,
        children: [
          {
            title: 'Meus pedidos',
            path: '/orders/my-orders',
          },
          {
            title: 'Meu pedido',
            path: '/orders/new-order',
          },
          {
            title: 'Vitrine',
            path: '/orders/showcase',
          },
        ],
      },
      {
        title: 'Ordem de serviço',
        path: '/service-order',
        icon: <Iconify icon="ic:baseline-post-add" />,
      },
      {
        title: 'Perguntas frequentes',
        path: '/common-questions',
        icon: <Iconify icon="ic:baseline-format-list-bulleted" />,
      },
      {
        title: 'Vendedor',
        path: '/seller',
        icon: <Iconify icon="ic:baseline-card-travel" />,
      },
      {
        title: 'Telas adicionais',
        path: '/additional-screens',
        icon: <Iconify icon="ic:round-add-to-queue" />,
      },
      {
        title: 'Comunicação',
        path: '/communication',
        icon: <Iconify icon="ic:outline-textsms" />,
        children: [
          {
            title: 'Notificações',
            path: '/communication/notifications',
          },
          {
            title: 'Meu pedido',
            path: '/communication/campaigns',
          },
        ],
      },
    ],
  },
  {
    subheader: 'análises',
    items: [
      {
        title: 'Histórico de acessos',
        path: '/access-history',
        icon: <Iconify icon="ic:round-bar-chart" />,
      },
      {
        title: 'Pedidos',
        path: '/order-analysis',
        icon: <Iconify icon="ic:baseline-pie-chart-outline" />,
      },
      {
        title: 'Ordem de serviço',
        path: '/service-order-analysis',
        icon: <Iconify icon="ic:round-show-chart" />,
      },
    ],
  },
  {
    subheader: 'ajuda',
    items: [
      {
        title: 'Universidade',
        path: '/university',
        icon: <Iconify icon="ic:outline-video-library" />,
      },
      {
        title: 'Suporte',
        path: '/support',
        icon: <Iconify icon="ic:round-help-outline" />,
      },
      {
        title: 'Novidades',
        path: '/news',
        icon: <Iconify icon="ic:outline-new-releases" />,
      },
    ],
  },
  {
    subheader: 'TESTE',
    items: [
      {
        title: 'Formulários',
        path: '/forms',
        icon: <Iconify icon="material-symbols:post-add" />,
        Element: loadable(lazy(() => import('views/@admin/Form'))),
      },
    ],
  },
];

export default navConfig;
