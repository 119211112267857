import { Item } from 'core/models/cart';
import { CartItem } from 'core/types/cartItem';
import { OrderConfig } from 'core/models/orderConfig';
import sort from './sort';
import { roundIfNecessary } from './number';

export function convertItemToCartItem(items: Item[]) {
  const cartItems: CartItem[] = sort(items || [], 'descricao').map(item => {
    const percentDiscount =
      item.preco.promocao?.isPromocao && item.preco.promocao.percentualDesconto
        ? `${roundIfNecessary(item.preco.promocao.percentualDesconto)}%`
        : undefined;

    const discountPerQuantity = item.preco.promocao?.descontoQuantidade || [];
    const maxDiscountPerQuantity =
      item.preco.promocao?.isPromocao && discountPerQuantity.length > 0
        ? `ATÉ ${roundIfNecessary(
            Math.max(...discountPerQuantity.map(d => d.percentualDesconto)),
          )}%`
        : undefined;
    return {
      key: `${item.id}.${item.unidade?.id}.${item.controle}.${
        item.preco.valorDesconto || 0
      }.${item.preco.percentualDesconto || 0}`,
      productId: item.id,
      control: item.controle,
      discountPercentage: item.preco.percentualDesconto,
      discountValue: item.preco.valorDesconto,
      price: {
        value: item.preco.precoUnitarioDesconto || item.preco.precoUnitario,
        totalValue: item.preco.precoTotalDesconto || item.preco.precoTotal,
        unitPriceDefaultUnit: item.preco.precoUnitarioUnidadePadrao,
        unitaryValue: item.preco.precoUnitario,
        promotion: item.preco.promocao,
      },
      productName: item.descricao,
      quantity: item.quantidade.quantidade,
      unit: item.unidade,
      unityId: item.unidade?.id,
      units: [],
      tag: maxDiscountPerQuantity || percentDiscount,
      stock: item.estoque?.estoque,
    };
  });

  return cartItems;
}

export function convertCartItemToItem(cartItem: CartItem) {
  return {
    id: cartItem.productId,
    descricao: cartItem.productName || '',
    controle: cartItem.control,
    quantidade: cartItem.quantity || 0,
    unidade: cartItem.unit?.id,
    precoUnitario: cartItem.price.value,
    precoTotal: cartItem.price.totalValue,
    percentualDesconto: cartItem.discountPercentage,
    valorDesconto: cartItem.discountValue,
  };
}

export function formatProjectValues(
  orderConfig?: OrderConfig,
  projectValues?: { id: string; valor: unknown }[],
) {
  const formattedValues = {};
  const labels = {
    QUANTIDADE: 'Quantidade',
    COMPRIMENTO: 'Comprimento',
    ALTURA: 'Altura',
    LARGURA: 'Largura',
  };

  if (orderConfig?.campoFixosProjeto && projectValues) {
    orderConfig.campoFixosProjeto.forEach(field => {
      const fieldValue = projectValues.find(
        item => item.id === field.formularioCampoID,
      );
      if (fieldValue) {
        const fieldName = labels[field.referencia] || field.referencia;
        formattedValues[fieldName] = fieldValue.valor;
      }
    });
  }
  return formattedValues;
}
