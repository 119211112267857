import React from 'react';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import Iconify from 'components/elements/Iconify';
import Scrollbar from 'components/wrappers/Scrollbar';
import useCart from 'core/hooks/useCart';
import useOrder from 'core/hooks/useOrder';
import useToggle from 'core/hooks/useToggle';
import {
  NEW_ORDER_PATH,
  NEW_ORDER_TYPE_SELECTION_PATH,
} from 'core/routes/@portal/dashboardRoutes';
import { fCurrencyBRL } from 'core/utils/formatNumber';
import useResponsive from 'core/hooks/useResponsive';

import CartItem from '../CartItem';

import { CartButton, CartButtonIcon } from './styles';

const Cart: React.FC = () => {
  const isMobile = useResponsive('down', 'md');

  const location = useLocation();
  const navigate = useNavigate();
  const { quantityItems, clearCart, clearCloseCart, cartTotal, items } =
    useCart();
  const {
    orderConfigId,
    cartOpen = false,
    goToCheckoutStep,
    goToSelectionProductsStep,
    updateOrderStateStoredValues,
    clearCurrentOrder,
  } = useOrder();
  const [openConfirm, toggleOpenConfirm] = useToggle();

  const handleOpen = () => updateOrderStateStoredValues('cartOpen', true);

  const handleClose = () => updateOrderStateStoredValues('cartOpen', false);

  const handleClickCheckout = () => {
    if (!orderConfigId) return;

    goToCheckoutStep();

    navigate(NEW_ORDER_PATH, {
      state: { orderConfigId },
    });
    handleClose();
  };

  const handleClickAddMore = () => {
    if (!orderConfigId) return;

    goToSelectionProductsStep();

    navigate(NEW_ORDER_PATH, {
      state: { orderConfigId },
    });
    handleClose();
  };

  const handleClickClearCart = () => {
    toggleOpenConfirm();
    handleClose();
    clearCart();
  };

  const handleClickClearOrder = () => {
    toggleOpenConfirm();
    handleClose();
    clearCurrentOrder();
    clearCloseCart();

    if (location.pathname.includes(NEW_ORDER_PATH)) {
      navigate(NEW_ORDER_TYPE_SELECTION_PATH, {
        replace: true,
      });
    }
  };

  const theme = useTheme();

  return (
    <>
      {isMobile ? (
        <Badge
          color="secondary"
          badgeContent={quantityItems()}
          overlap="circular"
          sx={{ '& .MuiBadge-badge': { backgroundColor: 'primary.main' } }}
        >
          <CartButtonIcon onClick={handleOpen}>
            <Iconify icon="ic:outline-shopping-cart" />
          </CartButtonIcon>
        </Badge>
      ) : (
        <CartButton
          onClick={handleOpen}
          variant="outlined"
          sx={{
            borderRadius: 5,
            justifyContent: 'flex-start',
            px: 3,
          }}
          startIcon={
            <Iconify icon="ic:outline-shopping-cart" color="primary.main" />
          }
        >
          <Stack>
            <Typography
              variant="caption"
              sx={{ lineHeight: '14px' }}
              textAlign="left"
            >
              <b>{fCurrencyBRL(cartTotal())}</b>
            </Typography>
            <Typography
              variant="caption"
              sx={{ lineHeight: '14px' }}
              textAlign="left"
              color="grey.800"
            >
              {quantityItems()} {quantityItems() > 1 ? 'itens' : 'item'}
            </Typography>
          </Stack>
        </CartButton>
      )}
      <Drawer
        anchor="right"
        open={cartOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { width: '100%', maxWidth: 772 },
        }}
      >
        <Stack height={1}>
          <Stack py={2.75} px={2.5} gap={1} direction="row" alignItems="center">
            <Typography variant="subtitle1" flex={1}>
              Carrinho
            </Typography>
            {!location.pathname.includes(NEW_ORDER_PATH) && (
              <Button
                variant="outlined"
                onClick={handleClickAddMore}
                startIcon={<Iconify icon="material-symbols:add" />}
              >
                Adicionar mais itens
              </Button>
            )}
            <IconButton onClick={handleClose}>
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>

          <Divider />

          <Scrollbar
            sx={{
              px: 2.5,
              pt: 3.25,
              height: 1,
              '& .simplebar-content': {
                height: items.length === 0 ? 1 : undefined,
              },
            }}
            forceVisible
          >
            <Stack
              gap={2}
              display="grid"
              gridAutoRows="1fr"
              alignContent="stretch"
              gridTemplateColumns="repeat(1, 1fr)"
              flex={1}
            >
              {items.length > 0 ? (
                items.map((item, index) => (
                  <CartItem key={item.key} index={index} item={item} />
                ))
              ) : (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height={1}
                  textAlign="center"
                >
                  <Iconify
                    icon="ic:outline-shopping-cart"
                    color="#E7E7E7"
                    width={80}
                  />
                  <Typography variant="h6" mt={2}>
                    Seu carrinho está vazio.
                  </Typography>
                  <Typography variant="body2" maxWidth={211}>
                    Os itens adicionados ao carrinho serão mostrados neste
                    local.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Scrollbar>

          <Stack
            py={2}
            mt={1}
            px={2.5}
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ boxShadow: theme.shadows[24] }}
          >
            <Button
              color="error"
              variant="soft"
              onClick={toggleOpenConfirm}
              sx={{
                '& div div': {
                  display: 'none',
                },

                '&:hover, &:focus': {
                  '& div div': {
                    display: 'block',
                  },
                  '& svg': {
                    ml: '6px',
                  },
                },
              }}
            >
              <Stack gap={1} direction="row" alignItems="center">
                <Iconify icon="eva:trash-2-outline" />
                <div>Encerrar / Limpar carrinho</div>
              </Stack>
            </Button>

            <Stack direction="row" alignItems="center" gap={2}>
              <Typography color="primary.main" variant="subtitle1">
                Total: {fCurrencyBRL(cartTotal())}
              </Typography>

              <Button
                variant="contained"
                onClick={handleClickCheckout}
                disabled={items.length === 0}
              >
                Fechar pedido
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openConfirm}
        onClose={toggleOpenConfirm}
        scroll="paper"
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: 379,
          },
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>O que você gostaria de fazer?</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          Você pode optar por apenas limpar o carrinho ou limpar e encerrar esta
          compra.
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleClickClearOrder}
          >
            Limpar e encerrar
          </Button>

          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleClickClearCart}
          >
            Apenas limpar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cart;
