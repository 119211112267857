import { FieldMask } from 'core/models/fieldMask';
import { fCurrencyBRL } from './formatNumber';
import { fDate, fDateTime } from './formatTime';

export const maskCpfCnpj = (value = ''): string => {
  if (!value) return value;

  if (value.length === 11)
    return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(
      6,
      9,
    )}-${value.slice(9, 12)}`;

  if (value.length === 14)
    return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
      5,
      8,
    )}/${value.slice(8, 12)}-${value.slice(12, 15)}`;

  return value;
};

export const maskCurrency = (value: number | number[]): string | number => {
  if (!value && value !== 0) return value;

  if (Array.isArray(value)) {
    return value.map(item => fCurrencyBRL(item)).join(' - ');
  }

  return fCurrencyBRL(value);
};

export const phoneMask = (value = '') => {
  const formattedValue = value.replaceAll(/\s/g, '');

  if (
    formattedValue.length === 14 &&
    formattedValue.startsWith('55') &&
    formattedValue.endsWith('-')
  ) {
    const formattedValueWithoutSuffix = formattedValue.slice(0, -1);
    const formattedValueWithoutPrefix = formattedValueWithoutSuffix.replace(
      /^55/,
      '',
    );
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 14 && formattedValue.startsWith('550')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^550/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 14 && formattedValue.startsWith('055')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^055/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 13 && formattedValue.startsWith('550')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^550/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{4})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 13 && formattedValue.startsWith('0')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^055/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{4})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (
    formattedValue.length === 13 &&
    formattedValue.startsWith('55') &&
    formattedValue.endsWith('-')
  ) {
    const formattedValueWithoutSuffix = formattedValue.slice(0, -1);
    const formattedValueWithoutPrefix = formattedValueWithoutSuffix.replace(
      /^55/,
      '',
    );
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{4})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 13 && formattedValue.startsWith('55')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^55/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 12 && formattedValue.startsWith('0')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^0/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{5})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 12 && formattedValue.startsWith('55')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^55/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{4})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 11 && formattedValue.startsWith('0')) {
    const formattedValueWithoutPrefix = formattedValue.replace(/^0/, '');
    return formattedValueWithoutPrefix.replace(
      /^(\d{2})(\d{4})(\d{4}).*/,
      '($1) $2-$3',
    );
  }

  if (formattedValue.length === 11)
    return formattedValue.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');

  if (formattedValue.length === 10)
    return formattedValue.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');

  return formattedValue.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
};

export const formatterWhatsappNumber = (value = '') => {
  const phoneNumberFormatted = value.replaceAll(/[()\s-]/g, '');
  if (
    (phoneNumberFormatted.length === 12 ||
      phoneNumberFormatted.length === 13) &&
    (phoneNumberFormatted.startsWith('0') ||
      phoneNumberFormatted.startsWith('55'))
  ) {
    const phoneNumberWithoutPrefix = phoneNumberFormatted.replace(
      /^(00|0|55)/,
      '',
    );
    return `+55${phoneNumberWithoutPrefix}`;
  }
  return `+55${phoneNumberFormatted}`;
};

const mask = (value: any, fieldMask: FieldMask) => {
  try {
    switch (fieldMask) {
      case 'DATA':
        return fDate(value);
      case 'DATA_HORA':
        return fDateTime(value);
      case 'CPF_CNPJ':
        return maskCpfCnpj(value);
      case 'TELEFONE':
        return phoneMask(value);
      case 'WHATSAPP':
        return phoneMask(value);
      case 'MOEDA':
        return Number(value) ? maskCurrency(value) : value;
      default:
        return value;
    }
  } catch {
    return value;
  }
};

export default mask;
