import { ApiRequestWithUrl } from 'core/types/apiRequest';
import api from './base/api';

export const imageApi = api.injectEndpoints({
  endpoints: build => ({
    getImage: build.query<{ imagem: string }, object>({
      query: body => ({
        url: 'Imagem',
        method: 'POST',
        body,
      }),
    }),
    getImages: build.query<{ imagem: string }, Partial<ApiRequestWithUrl>>({
      query: ({ name, method, body = {}, url = '' }) => ({
        url,
        method: 'POST',
        params: {
          name,
          method,
        },
        body,
      }),
    }),
  }),
});

export const { useGetImageQuery, useLazyGetImagesQuery } = imageApi;
