// eslint-disable-next-line no-shadow
export enum Icon {
  LIST = 'ic:round-list-alt',
  FINANCEIRO = 'ic:outline-attach-money',
  CHECKLIST = 'ic:outline-playlist-add-check',
  CARRINHO = 'ic:outline-shopping-cart',
  CHECK = 'ic:baseline-check-circle-outline',
  EDICAO = 'ic:outline-edit-note',
  INICIO = 'ic:outline-home',
  PESSOAS = 'ic:outline-people-alt',
  ADICIONAR = 'ic:baseline-add-circle-outline',
  CARTEIRA = 'ic:round-account-balance-wallet',
  INTERROGACAO = 'ic:outline-question-mark',
  GRAFICO_COLUNA = 'ic:outline-bar-chart',
  GRAFICO_LINHA = 'ic:outline-show-chart',
  REFAZER_PEDIDO = 'ic:outline-refresh',
  EDITAR_PEDIDO = 'ic:outline-edit',
  DOWNLOAD = 'ic:outline-cloud-download',
  CAMINHAO = 'bi:truck',
  BRASIL = 'carbon:delivery-parcel',
  INFO = 'mdi:information-variant-circle-outline',
  CODIGO_BARRA = 'ion:barcode-outline',
  SEGURANCA = 'carbon:security',
  IMAGEM = 'material-symbols:image-outline',
  TROFEU = 'mdi:trophy-variant-outline',
  CONFIRMAR_PEDIDO = 'ic:round-check',
}
