import {
  Auth,
  PasswordChange,
  PasswordPeform,
  PasswordRequest,
  Tokens,
  Response,
} from 'core/models/auth';

import api from './base/api';

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<Tokens, Auth>({
      query: credentials => ({
        url: 'oauth/token',
        method: 'POST',
        body: {
          usuario: credentials.username,
          senha: credentials.password,
        },
      }),
    }),
    passwordChangeRequest: build.mutation<Response, PasswordRequest>({
      query: body => ({
        url: 'services/public',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'passwordChangeRequest',
        },
        body,
      }),
    }),
    passwordChangePeform: build.mutation<Response, PasswordPeform>({
      query: body => ({
        url: 'services/public',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'passwordChangePerform',
        },
        body,
      }),
    }),
    passwordChangeSave: build.mutation<Response, PasswordChange>({
      query: body => ({
        url: 'services/public',
        method: 'POST',
        params: {
          name: 'Usuario',
          method: 'passwordChangeSave',
        },
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  usePasswordChangeRequestMutation,
  usePasswordChangePeformMutation,
  usePasswordChangeSaveMutation,
} = authApi;
