import React from 'react';
import { Box, useTheme } from '@mui/material';

import { LabelProps } from './props';
import { RootStyle } from './styles';

const Label: React.FC<LabelProps> = ({
  children,
  color = '#000',
  variant = 'ghost',
  startIcon,
  endIcon,
  sx,
  ...others
}) => {
  const theme = useTheme();

  const style = {
    width: 16,
    height: 16,
    '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
  };

  return (
    <RootStyle
      ownerState={{ color, variant }}
      sx={{
        ...(startIcon && { pl: 0.75 }),
        ...(endIcon && { pr: 0.75 }),
        ...sx,
      }}
      theme={theme}
      {...others}
    >
      {startIcon && <Box sx={{ mr: 0.75, ...style }}>{startIcon}</Box>}

      {children}

      {endIcon && <Box sx={{ ml: 0.75, ...style }}>{endIcon}</Box>}
    </RootStyle>
  );
};

export default Label;
