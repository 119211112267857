import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';

export const RootStyle = styled(m.div, {
  shouldForwardProp: prop => prop !== 'showLoading',
})(({ theme }) => ({
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  opacity: 1,
  visibility: 'visible',
  zIndex: 99999,
  transition: theme.transitions.create(['opacity', 'visibility'], {
    duration: 350,
  }),
}));
