import { ParcelaPayment, PaymentMethod } from 'core/models/paymentMethod';
import {
  useGetPaymentConditionsQuery,
  useGetUserPreferencesQuery,
} from 'core/services/newOrderApi';
import { useGetTableConfigQuery } from 'core/services/tablesApi';
import { objectToArrayObject } from 'core/utils/array';
import { formattedPayment, paymentOption } from 'core/utils/payment';

import { Parcela } from 'core/models/orderPaymentConfig';
import { PARCELAS_LABEL } from 'core/config';
import sort from 'core/utils/sort';
import useArguments from './useArguments';
import useOrder from './useOrder';
import useDeepCompareEffect from './useDeepCompareEffect';

const usePaymentTypes = () => {
  const {
    contextOfArguments,
    orderConfig,
    preOrderFormValues,
    updateOrderStateStoredValues,
    selectedPaymentMethod,
  } = useOrder();
  const { checkout, id, formularioPrePedidoID } = orderConfig || {};
  const { tabelaTipoNegociacaoID } = checkout || {};

  const handleSelect = (value: PaymentMethod) => {
    updateOrderStateStoredValues('selectedPaymentMethod', value);
  };

  const validValueNumber = (value: undefined | number) => {
    if (value !== undefined && value > 0) {
      return true;
    }
    return false;
  };

  const { isLoading: loadingConfigs, data: configResponse } =
    useGetTableConfigQuery(
      { id: tabelaTipoNegociacaoID || '' },
      {
        refetchOnMountOrArgChange: true,
        skip: !tabelaTipoNegociacaoID,
      },
    );
  const config = configResponse;

  const { requestArguments, blockRequestThatNeedsTheArgument } = useArguments(
    contextOfArguments,
    config?.argumentos,
  );

  const { isLoading, isSuccess, data } = useGetPaymentConditionsQuery(
    {
      contexto: {
        id,
        carrinho: {
          prePedido: {
            formularioID: formularioPrePedidoID,
            dados: objectToArrayObject(
              preOrderFormValues,
              'campo',
              'valor',
            ).filter(i => i.valor),
          },
          items: [],
        },
      },
      contextoTabela: {
        tabelaID: tabelaTipoNegociacaoID,
        filtros: [],
        argumentos: requestArguments || [],
        onlyCamposIDs: [],
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !config || blockRequestThatNeedsTheArgument,
    },
  );

  const { data: userPreferences } = useGetUserPreferencesQuery(
    {
      novoPedidoID: orderConfig?.id || '',
      argumentos: requestArguments || [],
    },
    {
      skip: !orderConfig,
    },
  );

  const fixedFields = orderConfig?.checkout.camposFixosTipoNegociacao || [];
  const paymentDefault = data?.padrao || [];
  const paymentOnline = data?.pagamentoOnline || [];

  const FORMATTED_DATA = paymentDefault.map(item => {
    const fixedValues = formattedPayment(item, fixedFields, config);

    return {
      ...fixedValues,
    };
  });

  const formattedParcela = (parcelas: Parcela[]) => {
    const parcelasFormatted = parcelas.map(parcela => {
      const paymentParcelaFormatted = formattedPayment(
        parcela.tipoNegociacao,
        fixedFields,
        config,
      );
      const typeNegociacaoParcela = paymentOption(paymentParcelaFormatted);
      return {
        parcela: parcela.parcela,
        label: PARCELAS_LABEL[parcela.parcela] || parcela.parcela,
        tipoNegociacao: typeNegociacaoParcela,
      };
    });
    return sort(parcelasFormatted, 'label', 'asc');
  };

  const PAYMENT_OPTIONS_ONLINE: PaymentMethod[] = paymentOnline.map(d => ({
    id: d.codPagamentoOnline,
    parcelaSelecionada:
      formattedParcela(d.parcelas).length > 0
        ? formattedParcela(d.parcelas)[0].parcela
        : undefined,
    descricao: d.nome,
    tipo: d.tipo,
    parcelas: formattedParcela(d.parcelas),
    permiteParcelamento: d.permiteParcelamento,
  }));

  const PAYMENT_OPTIONS_DEFAULT: PaymentMethod[] = FORMATTED_DATA.map(d => {
    return paymentOption(d);
  });

  const PAYMENT_OPTIONS: PaymentMethod[] = [
    ...PAYMENT_OPTIONS_ONLINE,
    ...PAYMENT_OPTIONS_DEFAULT,
  ];

  useDeepCompareEffect(() => {
    if (
      isSuccess &&
      selectedPaymentMethod &&
      !PAYMENT_OPTIONS.find(option => option.id === selectedPaymentMethod.id)
    ) {
      updateOrderStateStoredValues('selectedPaymentMethod', undefined);
    }
  }, [PAYMENT_OPTIONS, isSuccess]);

  return {
    loading: loadingConfigs || isLoading,
    options: PAYMENT_OPTIONS,
    config,
    ...userPreferences,
    handleSelect,
    validValueNumber,
  };
};

export default usePaymentTypes;
