import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

import TableHeadCustom from 'components/elements/table/TableHeadCustom';
import TableNoData from 'components/elements/table/TableNoData';
import Scrollbar from 'components/wrappers/Scrollbar';
import useOrder from 'core/hooks/useOrder';
import useCart from 'core/hooks/useCart';

import Row from './Row';

const TABLE_HEAD = [
  { id: 'PRODUCT', label: 'Produtos', minWidth: 140 },
  { id: 'STOCK', label: 'Estoque', minWidth: 140 },
  {
    id: 'QUANTITIY',
    label: 'Quantidade',
    minWidth: 220,
    align: 'center',
  },
  { id: 'DISCOUNT', label: 'Desconto', minWidth: 240, align: 'center' },
  { id: 'SUBTOTAL', label: 'Sub Total', minWidth: 140 },
  { id: 'button', label: '', minWidth: 64 },
];

const CheckoutItemsViewer: React.FC = () => {
  const { orderConfig } = useOrder();
  const { habilitaDecontoPercentual, habilitaDecontoValor } = orderConfig || {};
  const { items } = useCart();

  const COLUMNS = TABLE_HEAD.filter(th => {
    if (
      th.id === 'DISCOUNT' &&
      !habilitaDecontoPercentual &&
      !habilitaDecontoValor
    ) {
      return false;
    }

    if (th.id === 'STOCK' && !orderConfig?.exibeEstoque) {
      return false;
    }

    return true;
  });

  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Scrollbar>
        <Table>
          <TableHeadCustom headLabel={COLUMNS} />

          <TableBody
            sx={{
              '& .MuiTableRow-root+.MuiTableRow-root': {
                borderTop: '2px solid',
                borderColor: 'grey.200',
              },
            }}
          >
            {items.map((item, index) => (
              <Row key={item.key} index={index} item={item} />
            ))}

            {items.length === 0 && (
              <TableNoData
                isNotFound
                title="Você não tem nenhum produto no carrinho"
              />
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );
};

export default CheckoutItemsViewer;
