import { Config, Field } from 'core/models/config';
import { FixedFields } from 'core/models/orderConfig';
import { DefaultPayment } from 'core/models/orderPaymentConfig';
import { PaymentMethod } from 'core/models/paymentMethod';
import { getValue } from './tableData';

const formattedPayment = (
  paymentDefault: DefaultPayment,
  fixedFields: FixedFields[],
  config: Config | undefined,
) => {
  const fixedValues: Record<string | number, any> = fixedFields.reduce(
    (acc, curr) => {
      const configField = config?.campos.find(
        c => c.id === curr.mapeamentoCampo,
      ) as Field;

      const value = paymentDefault.valores.find(
        v => v.mapeamentoCampo === curr.mapeamentoCampo,
      );

      if (
        curr.referencia === 'PEDIDO_MINIMO' ||
        curr.referencia === 'PEDIDO_MAXIMO'
      ) {
        return {
          ...acc,
          [curr.referencia]: value?.valor,
        };
      }
      if (curr.referencia === 'CHAVE') {
        return {
          ...acc,
          [curr.referencia]:
            paymentDefault.indices.find(i => i.nomeCampo === value?.nomeCampo)
              ?.valor || value?.valor,
        };
      }

      return {
        ...acc,
        [curr.referencia]: getValue(value?.valor, configField),
      };
    },
    {},
  );

  return {
    ...fixedValues,
  };
};

const paymentOption = (formattedData: any) => {
  return {
    id: formattedData.CHAVE,
    descricao: formattedData.DESCRICAO,
    discount: formattedData.DESCONTO,
    minimumOrder: formattedData.PEDIDO_MINIMO,
    maximumOrder: formattedData.PEDIDO_MAXIMO,
  };
};

const paymentProcess = (selectedPayment: PaymentMethod) => {
  if (selectedPayment.tipo === 'CARTAO_CREDITO') {
    const paymentParcela = selectedPayment.parcelas?.find(
      p => p.parcela === selectedPayment.parcelaSelecionada,
    );
    if (paymentParcela) {
      return paymentParcela.tipoNegociacao;
    }
  }
  return selectedPayment;
};

export { formattedPayment, paymentOption, paymentProcess };
