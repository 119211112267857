import { createSlice } from '@reduxjs/toolkit';

import { fieldOptionsApi } from 'core/services/fieldOptionsApi';
import { DynamicFormFieldState } from './types';

const initialState: DynamicFormFieldState = {
  currentRequests: [],
};

const slice = createSlice({
  name: 'dynamicFormField',
  initialState,
  reducers: {
    resetMenus: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(
      fieldOptionsApi.endpoints.getOptions.matchPending,
      ({ currentRequests }, { meta }) => {
        const { campo: field, formulario: form } =
          (meta.arg.originalArgs.body as any) || {};

        return {
          currentRequests: [
            ...currentRequests,
            {
              form: form?.toString(),
              field,
            },
          ],
        };
      },
    );
    builder.addMatcher(
      fieldOptionsApi.endpoints.getOptions.matchFulfilled,
      ({ currentRequests }, { meta }) => {
        const { campo: field, formulario: form } =
          (meta.arg.originalArgs.body as any) || {};

        return {
          currentRequests: currentRequests
            .slice()
            .filter(f => f.form !== form && f.field !== field),
        };
      },
    );
    builder.addMatcher(
      fieldOptionsApi.endpoints.getOptions.matchRejected,
      ({ currentRequests }, { meta }) => {
        const { campo: field, formulario: form } =
          (meta.arg.originalArgs.body as any) || {};

        return {
          currentRequests: currentRequests
            .slice()
            .filter(f => f.form !== form && f.field !== field),
        };
      },
    );
  },
});

export const { resetMenus } = slice.actions;

export default slice.reducer;
