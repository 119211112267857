import { Stack } from '@mui/material';

import ClientLogo from 'components/elements/ClientLogo';
import Page from 'components/wrappers/Page';
import { getImageByType } from 'core/utils/image';
import LogoB2B from 'components/elements/LogoB2B';

import { Content, Wallpapper } from './styles';
import useLogin from './useLogin';

type Props = {
  title: string;
  children: JSX.Element;
  login?: boolean;
};

const ContainerLogin = ({ title, children, login = false }: Props) => {
  const { layout } = useLogin();

  const {
    data: { tipoImagemLogin, imagemLogin },
  } = layout;

  return (
    <Page title={title}>
      <Stack direction="row" minHeight="100vh">
        {imagemLogin && tipoImagemLogin && login && (
          <Wallpapper
            background={getImageByType(tipoImagemLogin, imagemLogin)}
          />
        )}

        <Content boxed={!imagemLogin || !tipoImagemLogin || !login}>
          <Stack gap={3} width="100%">
            <Stack mx="auto" mb={2}>
              <ClientLogo
                sx={{
                  '& svg, & img': {
                    width: '100%',
                    maxWidth: 180,
                    height: 'auto',
                    maxHeight: 100,
                  },
                }}
              />
            </Stack>

            {children}
          </Stack>

          <Stack spacing={1} mt={5.75} sx={{ color: 'grey.500' }}>
            <LogoB2B />
          </Stack>
        </Content>
      </Stack>
    </Page>
  );
};

export default ContainerLogin;
