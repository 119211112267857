import { useSnackbar } from 'notistack';
import { useEventCartMutation } from 'core/services/cartApi';
import { convertCartItemToItem, convertItemToCartItem } from 'core/utils/cart';
import { serverCart } from 'core/redux/slices/cart';
import { dispatch, useSelector } from 'core/redux/store';
import { CartItem } from 'core/types/cartItem';
import { useCreateRegistroMutation } from 'core/services/formsApi';
import { objectToArrayObject } from 'core/utils/array';
import { paymentProcess } from 'core/utils/payment';
import useOrder from './useOrder';

const useServerCart = (novoPedidoID?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const [eventCart, { isLoading: loadingEvent }] = useEventCartMutation();
  const [createRegistro] = useCreateRegistroMutation();
  const cartState = useSelector(state => state.cart);
  const {
    preOrderFormValues,
    selectedPaymentMethod,
    additionalFormValues,
    orderConfig,
    updateOrderState,
    cartKey,
    cartExists,
    orderConfigId,
  } = useOrder();

  const { checkout } = orderConfig || {};

  const { formularioPrePedidoID } = orderConfig || {};
  const addItem = async (item: CartItem) => {
    try {
      await eventCart({
        id: cartKey,
        novoPedidoID,
        evento: 'ADICIONAR_ITEM',
        item: convertCartItemToItem(item),
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();
    } catch (err) {
      console.log(
        `Falha ao ao adicionar o produto ${item.productName} ao carrinho do servidor, produto adicionaado localmente será criado apenas localmente`,
      );
    }
  };

  const removeItem = async (item: CartItem) => {
    try {
      await eventCart({
        id: cartKey,
        novoPedidoID,
        evento: 'REMOVER_ITEM',
        item: convertCartItemToItem(item),
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();
    } catch (err) {
      console.log(
        `Falha ao ao remover o produto ${item.productName} do carrinho do servidor`,
      );
    }
  };

  const updateItem = async (item: CartItem) => {
    if (cartExists && cartKey) {
      const keyItem = `${item.productId}.${item.unityId}.${item.control}.${item.discountValue}.${item.discountPercentage}`;
      const itemUpdate = cartState[cartKey].items.find(i => i.key === keyItem);
      if (itemUpdate) {
        const quantityUpdate =
          (item.quantity || 0) - (itemUpdate.quantity || 0);
        if (quantityUpdate > 0) {
          await addItem({ ...item, quantity: quantityUpdate });
        } else if (quantityUpdate < 0) {
          await removeItem({ ...item, quantity: quantityUpdate * -1 });
        } else {
          await removeItem(item);
          await addItem(item);
        }
      } else {
        await addItem(item);
      }
    }
  };

  const newCart = async (item: CartItem) => {
    try {
      let formulario;

      if (formularioPrePedidoID && preOrderFormValues) {
        const formRequest = {
          formulario: formularioPrePedidoID,
          dados: objectToArrayObject(
            preOrderFormValues,
            'campo',
            'valor',
          ).filter(i => i.valor),
          name: 'Formulario',
          method: 'createRegistro',
        };

        const { id: registroID } = await createRegistro(formRequest).unwrap();
        formulario = {
          formularioID: formularioPrePedidoID,
          registroID,
        };
      }

      const response = await eventCart({
        novoPedidoID,
        evento: 'NOVO',
        novo: {
          formulario,
          item: convertCartItemToItem(item),
          formaPagamento: {
            id: selectedPaymentMethod?.id,
          },
        },
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();
      updateOrderState('cartKey', response.id);
      updateOrderState('cartExists', response.existsCarrinho);
      dispatch(
        serverCart({
          cartKey: response.id,
          items: convertItemToCartItem(response.items),
        }),
      );
    } catch (err) {
      console.log(
        'Falha ao criar carrinho no servidor, o carrinho será criado apenas localmente',
      );
    }
  };

  const changePreOrder = async values => {
    try {
      let formulario;

      if (formularioPrePedidoID && values) {
        const formRequest = {
          formulario: formularioPrePedidoID,
          dados: objectToArrayObject(values, 'campo', 'valor').filter(
            i => i.valor,
          ),
          name: 'Formulario',
          method: 'createRegistro',
        };

        const { id: registroID } = await createRegistro(formRequest).unwrap();
        formulario = {
          formularioID: formularioPrePedidoID,
          registroID,
        };
      }
      const response = await eventCart({
        id: cartKey,
        novoPedidoID,
        evento: 'ALTERAR_PRE_PEDIDO',
        formulario,
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();

      dispatch(
        serverCart({
          cartKey: response.id,
          items: convertItemToCartItem(response.items),
        }),
      );
    } catch (err) {
      enqueueSnackbar('Falha ao alterar o formulário de pre-pedido', {
        variant: 'error',
      });
    }
  };

  const changePayment = async (paymentID: string) => {
    try {
      if (cartExists) {
        const response = await eventCart({
          id: cartKey,
          novoPedidoID,
          evento: 'FORMA_PAGAMENTO',
          formaPagamento: {
            id: paymentID,
          },
          contexto: {
            carrinho: {
              items: [],
              prePedido: {
                formularioID: orderConfig?.formularioPrePedidoID,
                dados: objectToArrayObject(
                  preOrderFormValues,
                  'campo',
                  'valor',
                ).filter(i => i.valor),
              },
              checkout: {
                tipoNegociacaoID:
                  selectedPaymentMethod &&
                  paymentProcess(selectedPaymentMethod)?.id,
              },
            },
            id: orderConfigId,
          },
        }).unwrap();
        dispatch(
          serverCart({
            cartKey: response.id,
            items: convertItemToCartItem(response.items),
          }),
        );
      }
    } catch (err) {
      enqueueSnackbar('Falha ao alterar a forma de pagamento do carrinho', {
        variant: 'error',
      });
    }
  };

  const clearCart = async () => {
    try {
      await eventCart({
        id: cartKey,
        novoPedidoID,
        evento: 'LIMPAR',
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();
    } catch (err) {
      enqueueSnackbar('Falha ao limpar o carrinho', { variant: 'error' });
    }
  };

  const clearCloseCart = async () => {
    try {
      await eventCart({
        id: cartKey,
        novoPedidoID,
        evento: 'DESCARTAR',
        contexto: {
          carrinho: {
            items: [],
            prePedido: {
              formularioID: orderConfig?.formularioPrePedidoID,
              dados: objectToArrayObject(
                preOrderFormValues,
                'campo',
                'valor',
              ).filter(i => i.valor),
            },
            checkout: {
              tipoNegociacaoID:
                selectedPaymentMethod &&
                paymentProcess(selectedPaymentMethod)?.id,
            },
          },
          id: orderConfigId,
        },
      }).unwrap();
    } catch (err) {
      enqueueSnackbar('Falha ao encerrar o carrinho', { variant: 'error' });
    }
  };
  const changeCheckout = async () => {
    try {
      let formulario = {};

      if (
        checkout?.formularioID &&
        additionalFormValues &&
        cartExists &&
        cartKey
      ) {
        const formRequest = {
          formulario: checkout.formularioID,
          dados: objectToArrayObject(
            additionalFormValues,
            'campo',
            'valor',
          ).filter(i => i.valor),
          name: 'Formulario',
          method: 'createRegistro',
        };

        const { id: registroID } = await createRegistro(formRequest).unwrap();
        formulario = {
          formularioID: checkout?.formularioID,
          registroID,
        };
        const response = await eventCart({
          id: cartKey,
          novoPedidoID,
          evento: 'ALTERAR_CHECKOUT',
          formulario,
          contexto: {
            carrinho: {
              items: [],
              prePedido: {
                formularioID: orderConfig?.formularioPrePedidoID,
                dados: objectToArrayObject(
                  preOrderFormValues,
                  'campo',
                  'valor',
                ).filter(i => i.valor),
              },
              checkout: {
                tipoNegociacaoID:
                  selectedPaymentMethod &&
                  paymentProcess(selectedPaymentMethod)?.id,
              },
            },
            id: orderConfigId,
          },
        }).unwrap();
        dispatch(
          serverCart({
            cartKey: response.id,
            items: convertItemToCartItem(response.items),
          }),
        );
      }
    } catch (err) {
      console.log('Falha ao alterar o formulário de checkout do carrinho', {
        variant: 'error',
      });
    }
  };

  return {
    isLoading: loadingEvent,
    addItem,
    removeItem,
    updateItem,
    newCart,
    changePreOrder,
    changePayment,
    clearCart,
    clearCloseCart,
    changeCheckout,
  };
};

export default useServerCart;
