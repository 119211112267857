import packageJson from 'views/../../package.json';

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

export const MUI_PREMIUM_KEY = process.env.REACT_APP_MUI_PREMIUM_KEY || '';

export const SWK_HOST_API_KEY = process.env.REACT_APP_SKW_HOST_API_KEY || '';
export const SWK_MGE_SESSION = process.env.REACT_APP_SKW_MGE_SESSION || '';

export const ENVIROMENT_KEY = 'environment';

export const ENVIROMENT_SKW = 'skw';

export const DASHBOARD_PORTAL_PATH = '/portal';
export const ADMIN_DASHBOARD_PATH = '';

export const APP_TITLE = 'zydon b2b';
export const VERSION = packageJson.version;
export const B2B_LINK = 'https://zydon.com.br/b2b';

export const DEFAULT_PRODUCT_IMAGE = '/assets/images/b2b-noimage.svg';
export const DEFAULT_IMAGE = '/assets/images/b2b-noimage-white.svg';
export const CIELO_API_SANDBOX_URL =
  'https://apisandbox.cieloecommerce.cielo.com.br';
export const CIELO_API_PRODUCAO_URL = 'https://api.cieloecommerce.cielo.com.br';

export const PARCELAS_LABEL = {
  UM: '1 parcela',
  DOIS: '2 parcelas',
  TRES: '3 parcelas',
  QUATRO: '4 parcelas',
  CINCO: '5 parcelas',
  SEIS: '6 parcelas',
  SETE: '7 parcelas',
  OITO: '8 parcelas',
  NOVE: '9 parcelas',
  DEZ: '10 parcelas',
  ONZE: '11 parcelas',
  DOZE: '12 parcelas',
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const HOST_API = process.env.REACT_APP_API_URL || '/api';
export const ZDN_INGRESS = process.env.REACT_APP_ZDN_INGRESS || '';

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,

  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,

  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
