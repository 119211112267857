import React from 'react';
import { Skeleton } from '@mui/material';

import useImage from 'core/hooks/useImage';
import Image from '../Image';

import { DynamicServerImageProps } from './props';

const DynamicServerImage: React.FC<DynamicServerImageProps> = ({
  type,
  urlImage,
  instance,
  indexes,
  field,
  height,
  width,
  id,
  defaultImage,
  loadingProps,
  ...other
}) => {
  const { image, isLoading } = useImage({
    type,
    urlImage,
    instance,
    indexes,
    field,
    height,
    width,
    id,
    defaultImage,
  });

  if (isLoading)
    return (
      <Skeleton
        width="100%"
        height="100%"
        sx={{ position: 'absolute', transform: 'none' }}
        {...loadingProps}
      />
    );

  return <Image src={image} {...other} />;
};

export default DynamicServerImage;
