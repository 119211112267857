import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import Iconify from 'components/elements/Iconify';
import useDeepCompareEffect from 'core/hooks/useDeepCompareEffect';
import useIsMounting from 'core/hooks/useIsMounting';
import apiErrorMessage from 'core/utils/apiErrorMessage';
import { fCurrencyBRL } from 'core/utils/formatNumber';

import { ActionsProps } from './props';

const Actions: React.FC<ActionsProps> = ({
  cartItem,
  actionButtonText,
  modifyingItem,
  error,
  alert,
  formDisabled,
  onClickAction,
  onClickCancel,
}) => {
  const { price, projectValues } = cartItem;

  const isMounting = useIsMounting();
  const {
    formState: { isValid, submitCount, isSubmitSuccessful },
    watch,
  } = useFormContext();
  const allFields = watch();

  const [formHasChanges, setFormHasChanges] = useState(false);

  const handleClickAddToCart = () => onClickAction();

  const handleClickCancel = () => onClickCancel();

  const renderValue = (value: string) => {
    if (modifyingItem) return <CircularProgress size={16} />;

    if (!price?.calculadora) return '-';

    return value;
  };

  useDeepCompareEffect(() => {
    if (isMounting) return;

    setFormHasChanges(true);
  }, [allFields]);

  useEffect(() => {
    if (isSubmitSuccessful) setFormHasChanges(false);
  }, [submitCount, isSubmitSuccessful]);

  const showProjectValues = !error && projectValues && !formHasChanges;

  return (
    <>
      <Stack gap={1} py={2}>
        <Stack gap={1}>
          <LoadingButton
            type="submit"
            variant="soft"
            startIcon={<Iconify icon="ic:outline-calculate" />}
            loading={modifyingItem}
          >
            Calcular
          </LoadingButton>

          {error && !formHasChanges && (
            <Alert severity="error">
              {apiErrorMessage(error, 'Erro ao calcular valores')}
            </Alert>
          )}

          {alert && !formHasChanges && (
            <Alert severity="warning">{alert}</Alert>
          )}
        </Stack>

        <Stack direction="row" sx={{ textAlign: 'center' }} mt={1}>
          <Typography flex={1} component="div">
            Volume Total
            <Typography variant="inherit" component="div" color="grey.400">
              {showProjectValues
                ? renderValue(
                    `${price?.calculadora?.total} ${price?.calculadora?.descricao}`,
                  )
                : '-'}
            </Typography>
          </Typography>

          <Typography flex={1} component="div">
            Valor
            <Typography variant="inherit" component="div" color="grey.400">
              {showProjectValues ? renderValue(fCurrencyBRL(price.value)) : '-'}
            </Typography>
          </Typography>

          <Typography flex={1} component="div">
            Valor Total
            <Typography variant="inherit" component="div" color="grey.400">
              {showProjectValues
                ? renderValue(fCurrencyBRL(price.totalValue))
                : '-'}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button onClick={handleClickCancel}>Cancelar</Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleClickAddToCart}
          disabled={
            modifyingItem ||
            !cartItem.projectValues ||
            !isValid ||
            !!error ||
            !!alert ||
            formHasChanges ||
            formDisabled
          }
        >
          {actionButtonText}
        </Button>
      </Stack>
    </>
  );
};

export default Actions;
