import React, { useState, memo, useEffect } from 'react';
import { Box, Container, Divider, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';
import ScrollToTop from 'components/elements/ScrollToTop';
import WhatsAppButton from 'components/elements/WhatsAppButton';
import { useAuth } from 'core/hooks/useAuth';
import useLayout from 'core/hooks/useLayout';
import usePortalNavigation from 'core/hooks/usePortalNavigation';
import useRedirection from 'core/hooks/useRedirection';
import useResponsive from 'core/hooks/useResponsive';
import { NEW_ORDER_PATH } from 'core/routes/@portal/dashboardRoutes';
import { useGetPortalConfigQuery } from 'core/services/portalApi';
import useActiveLink from 'core/hooks/useActiveLink';
import LogoB2B from 'components/elements/LogoB2B';

import Header from './Header';
import Main from './Main';
import PasswordModal from './PasswordModal';
import NavHorizontal from './nav/NavHorizontal';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';

const DashboardLayout: React.FC = () => {
  const {
    auth: { needToChangePassword },
  } = useAuth();
  const { data } = useGetPortalConfigQuery();
  const { enableWhatsapp, msgWhatsapp, foneWhatsapp } = data || {};
  const whatsAppLink = `https://wa.me/${foneWhatsapp?.replace(
    / /g,
    '',
  )}?text=${msgWhatsapp}`;

  const { active: newOrderPage } = useActiveLink(NEW_ORDER_PATH);

  const isDesktop = useResponsive('up', 'lg');
  const { loading } = useRedirection(true);
  const navConfig = usePortalNavigation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    themeStretch,
    themeDirection,
    isNavMini,
    toogleNavMini,
    setNavAsMini,
  } = useLayout();
  const isNavHorizontal = themeDirection === 'horizontal';

  const navMini = newOrderPage ? isDesktop && true : isNavMini;

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      navConfig={navConfig}
      onClickNavToggle={toogleNavMini}
    />
  );

  useEffect(() => {
    if (newOrderPage) setNavAsMini();
  }, [newOrderPage, setNavAsMini]);

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? (
          <NavHorizontal navConfig={navConfig} />
        ) : (
          renderNavVertical
        )}

        <Stack minHeight="100vh">
          <Main>
            <Container maxWidth="xl">
              <Outlet />
            </Container>
          </Main>

          <Container maxWidth="xl" sx={{ mt: 'auto' }}>
            <Divider />
          </Container>

          <Stack
            spacing={1}
            alignItems="center"
            sx={{
              color: 'grey.500',
            }}
          >
            <Stack direction="row" margin={2} spacing={2} alignItems="center">
              <LogoB2B divider />
            </Stack>
          </Stack>
        </Stack>

        <LoadingScreen showLoading={loading} />

        {enableWhatsapp && <WhatsAppButton whatsAppLink={whatsAppLink} />}

        <PasswordModal open={needToChangePassword} />
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} forceNavMini={navMini} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {isDesktop && isNavMini ? (
          <>
            <NavMini
              navConfig={navConfig}
              onClickNavToggle={newOrderPage ? handleOpen : toogleNavMini}
            />

            {newOrderPage && (
              <NavVertical
                openNav={open}
                onCloseNav={handleClose}
                navConfig={navConfig}
                type="OVERLAY"
                onClickNavToggle={toogleNavMini}
              />
            )}
          </>
        ) : (
          renderNavVertical
        )}

        <Main>
          <Container maxWidth="xl">
            <Outlet />
          </Container>
        </Main>
      </Box>

      <LoadingScreen showLoading={loading} />

      {enableWhatsapp && <WhatsAppButton whatsAppLink={whatsAppLink} />}

      <PasswordModal open={needToChangePassword} />

      <ScrollToTop />
    </>
  );
};

export default memo(DashboardLayout);
