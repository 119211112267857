import React from 'react';
import { Box, Stack, Step, StepLabel, Stepper } from '@mui/material';
import Iconify from 'components/elements/Iconify';

import { StepIconProps, StepsProps } from './props';
import { StepConnector } from './styles';

const StepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 24,
        height: 24,
        color: 'text.disabled',
        ...(active && {
          color: 'primary.main',
        }),
      }}
    >
      {completed ? (
        <Iconify icon="eva:checkmark-fill" sx={{ color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      )}
    </Stack>
  );
};

const Steps: React.FC<StepsProps> = ({
  steps,
  activeStep,
  showLabels = true,
  alternativeFirstLabelStep,
  sx,
  ...other
}) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StepConnector />}
      sx={{ mb: 5, ...sx }}
      {...other}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          {index === 0 && alternativeFirstLabelStep ? (
            alternativeFirstLabelStep
          ) : (
            <StepLabel
              StepIconComponent={StepIcon}
              sx={{
                '& .MuiStepLabel-label': {
                  typography: 'subtitle2',
                },
              }}
            >
              {showLabels && label}
            </StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default Steps;
