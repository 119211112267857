import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { decode } from 'core/utils/jwt';
import { Auth, PasswordChange } from 'core/models/auth';
import { dispatch, useSelector } from 'core/redux/store';
import {
  useLoginMutation,
  usePasswordChangeRequestMutation,
  usePasswordChangePeformMutation,
  usePasswordChangeSaveMutation,
} from 'core/services/authApi';
import { setCredentials } from 'core/redux/slices/auth';
import { User } from 'core/models/user';
import { normalizeString } from 'core/utils/string';

const useLogin = () => {
  const layout = useSelector(state => state.layout);
  const navigate = useNavigate();
  const [login, { isLoading: loggingIn }] = useLoginMutation();
  const [passwordChangeRequest, { isLoading: requesting }] =
    usePasswordChangeRequestMutation();
  const [passwordChangePeform, { isLoading: peforming }] =
    usePasswordChangePeformMutation();
  const [passwordChangeSave, { isLoading: passwordSaving }] =
    usePasswordChangeSaveMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm<Auth>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: Auth) => {
    try {
      const credentials = await login({
        username: normalizeString(data.username),
        password: data.password,
      }).unwrap();
      if (credentials.statusSA && credentials.statusSA !== 'APROVADO') {
        navigate('/status-registro', {
          state: { status: credentials.statusSA },
        });
      } else {
        const { usuario, parceiro, vendedor, sub } = decode<User>(
          credentials.accessToken,
        );

        dispatch(
          setCredentials({
            ...credentials,
            userData: {
              sub,
              user: usuario,
              partner: parceiro,
              seller: vendedor,
            },
          }),
        );
      }
    } catch (err) {
      enqueueSnackbar('Usuário ou senha inválido', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };

  const onResetPassword = async (data: { email: string }) => {
    try {
      await passwordChangeRequest({
        email: normalizeString(data.email),
      }).unwrap();
      navigate('/email-enviado-sucesso');
    } catch (err) {
      enqueueSnackbar('E-mail inválido', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };

  const onPasswordChangePeform = async (keyAccess: string) => {
    try {
      await passwordChangePeform({ keyAccess }).unwrap();
    } catch (err) {
      navigate('/404');
      enqueueSnackbar('Link invalido ou token expirado', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
  const onNewPassword = async (data: PasswordChange) => {
    try {
      await passwordChangeSave(data).unwrap();
      enqueueSnackbar('Senha alterada com sucesso');
      navigate('/');
    } catch (err) {
      enqueueSnackbar('Erro ao alterar senha', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };

  return {
    showPassword,
    loggingIn,
    peforming,
    requesting,
    passwordSaving,
    onSubmit,
    onResetPassword,
    onNewPassword,
    onPasswordChangePeform,
    setShowPassword,
    handleSubmit,
    methods,
    layout,
  };
};

export default useLogin;
