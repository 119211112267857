import { FormControlLabel, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PartnerProps {
  selected: boolean;
}

export const Partner = styled(Paper, {
  shouldForwardProp: prop => prop !== 'selected',
})<PartnerProps>(({ theme, selected }) => ({
  padding: 1,
  cursor: 'pointer',
  flex: 1,
  width: '100%',

  ...(selected && {
    borderColor: theme.palette.primary.main,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
  }),
}));

export const PartnerLabel = styled(FormControlLabel)({
  height: 72,
  padding: '0 20px',
  flexGrow: 1,
  margin: 0,
  width: '100%',

  '& .MuiFormControlLabel-label': {
    width: '100%',
    paddingRight: 16,
  },
});

export const PartnerLabelContent = styled(Stack)({
  margin: 16,
  width: '100%',
  flexGrow: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
