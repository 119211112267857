import {
  MyData,
  MyDataUpdate,
  MyDataUpdateImage,
  NewPassword,
} from 'core/models/myData';
import api from './base/api';

export const myDataApi = api.injectEndpoints({
  endpoints: build => ({
    getMyData: build.query<MyData, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'getConfigBasica',
        },
        body: {},
      }),
    }),
    getConfigDetalhe: build.query<{ tabelaID: string }, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'getConfigDetalhe',
        },
        body: {},
      }),
    }),
    updatePassword: build.mutation<object, NewPassword>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'changePassword',
        },
        body,
      }),
    }),
    changeAccessPassword: build.mutation<object, string>({
      query: newPassword => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'changeAccessPassword',
        },
        body: {
          newPassword,
        },
      }),
    }),
    updateMyData: build.mutation<MyData, MyDataUpdate>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'updateConfigBasica',
        },
        body,
      }),
    }),
    updateImageMyData: build.mutation<{ response: string }, MyDataUpdateImage>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeusDados',
          method: 'updateImage',
        },
        body,
      }),
    }),
  }),
});

export const {
  useGetConfigDetalheQuery,
  useGetMyDataQuery,
  useUpdateImageMyDataMutation,
  useUpdateMyDataMutation,
  useUpdatePasswordMutation,
  useChangeAccessPasswordMutation,
} = myDataApi;
