import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { IconButtonAnimate } from 'components/animate';
import { base64Image } from 'core/utils/image';
import Iconify from 'components/elements/Iconify';
import MenuPopover from 'components/elements/MenuPopover';
import { useSnackbar } from 'components/widgets/snackbar';
import { useAuth } from 'core/hooks/useAuth';
import CustomAvatar from 'components/elements/CustomAvatar';
import useToggle from 'core/hooks/useToggle';
import { useGetPartnersQuery } from 'core/services/userApi';

import { MY_DATA_PATH } from 'core/routes/@portal/dashboardRoutes';
import { AccountProps } from './props';
import PartnerChange from '../PartnerChange';

const AccountPopover: React.FC<AccountProps> = ({ data }) => {
  const navigate = useNavigate();
  const { handleLogout, userType } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { data: partners, isLoading } = useGetPartnersQuery(undefined, {
    skip: userType === 'VENDEDOR',
    refetchOnMountOrArgChange: true,
  });

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [partnerModalOpen, togglePartnerModal] = useToggle();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickLogout = async () => {
    try {
      handleLogout();
      navigate('/login', { replace: true });
      handleClosePopover();
    } catch (error) {
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickMyData = () => {
    handleClosePopover();
    navigate(MY_DATA_PATH);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {data.foto || data.nome ? (
          <CustomAvatar
            src={base64Image(data?.foto) || undefined}
            alt={data?.nome}
            name={data?.nome}
          />
        ) : (
          <Iconify
            icon="carbon:user-avatar-filled"
            width={40}
            color="primary.main"
          />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {data.nome && (
            <Typography variant="subtitle2" noWrap>
              {data?.nome}
            </Typography>
          )}

          {data?.email && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {data?.email}
            </Typography>
          )}
        </Box>

        {data.email || data.nome ? (
          <Divider sx={{ borderStyle: 'dashed' }} />
        ) : undefined}

        {data.email && (
          <MenuItem onClick={handleClickMyData} sx={{ m: 1 }}>
            Meus dados
          </MenuItem>
        )}
        {isLoading && (
          <Stack alignItems="center">
            <CircularProgress size={24} />
          </Stack>
        )}
        {partners && partners.parceiros.length > 0 && (
          <MenuItem onClick={togglePartnerModal} sx={{ m: 1 }}>
            Troca de parceiro
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleClickLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </MenuPopover>
      {partners && (
        <PartnerChange
          open={partnerModalOpen}
          onClose={togglePartnerModal}
          partners={partners.parceiros}
        />
      )}
    </>
  );
};

export default AccountPopover;
