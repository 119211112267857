export function isMultiple(
  possibleMultiple: number,
  reference: number,
  exponent = 0,
) {
  return (
    Math.round(10 ** (exponent || 0) * possibleMultiple) %
      Math.round(10 ** (exponent || 0) * reference) ===
    0
  );
}

export function roundIfNecessary(value = 0) {
  return Math.round(value * 100) / 100;
}
