import React, { useEffect } from 'react';
import { Box, Drawer, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

import ClientLogo from 'components/elements/ClientLogo';
import { NavSectionVertical } from 'components/nav-section';
import Scrollbar from 'components/wrappers/Scrollbar';
import { NAV } from 'core/config';
import useResponsive from 'core/hooks/useResponsive';
import useRedirection from 'core/hooks/useRedirection';
import NavToggleButton from './NavToggleButton';

import { Props as IProps } from './props';

interface Props extends IProps {
  openNav: boolean;
  onCloseNav: VoidFunction;
  onClickNavToggle: VoidFunction;
  type?: 'OVERLAY' | 'DEFAULT';
}

const NavVertical: React.FC<Props> = ({
  openNav,
  onCloseNav,
  onClickNavToggle,
  navConfig,
  type = 'DEFAULT',
}) => {
  const { pathname } = useLocation();
  const { pathToRedirect } = useRedirection(false, false);
  const isDesktop = useResponsive('up', 'lg');

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'grey.0',
        },
      }}
    >
      <Stack
        position="relative"
        minHeight={113}
        alignItems="center"
        justifyContent="center"
        px={4}
        py={1}
      >
        <ClientLogo
          link={pathToRedirect}
          sx={{
            maxHeight: 113,
            'svg, img': {
              width: '100%',
              height: '100%',
              maxWidth: '100%',
              maxHeight: 113,
              objectFit: 'contain',
            },
          }}
        />
      </Stack>

      <NavSectionVertical data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  useEffect(() => {
    if (openNav) onCloseNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      component="nav"
      sx={{
        ...(type === 'DEFAULT' && {
          flexShrink: { lg: 0 },
          width: { lg: NAV.W_DASHBOARD },
        }),
        backgroundColor: 'grey.0',
      }}
    >
      {type === 'DEFAULT' && <NavToggleButton onClick={onClickNavToggle} />}

      {isDesktop && type === 'DEFAULT' ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default NavVertical;
