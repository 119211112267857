import { Config, Field } from 'core/models/config';
import {
  ActionExecuteData,
  TableData,
  ReportData,
} from 'core/models/tableData';
import { ApiRequest } from 'core/types/apiRequest';
import { Search } from 'core/models/search';

import api from './base/api';

interface DataRequest extends ApiRequest {
  page: number;
  size: number;
  publico?: boolean;
}

export const tablesApi = api.injectEndpoints({
  endpoints: build => ({
    getTableConfig: build.query<Config, { id: string; publico?: boolean }>({
      query: ({ id, publico }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'getById',
        },
        body: {
          id,
        },
      }),
    }),
    getTableProductConfig: build.query<
      Config,
      { id: string; publico?: boolean }
    >({
      query: ({ id, publico }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'getById',
        },
        body: {
          id,
        },
      }),
    }),
    getTableData: build.query<TableData, DataRequest>({
      query: ({ name, method, page, size, body, publico }) => ({
        url: publico ? 'services/public' : 'services',
        method: 'POST',
        params: {
          name,
          method,
          page,
          size,
        },
        body,
      }),
    }),
    executeAction: build.mutation<ActionExecuteData, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'executarAcao',
        },
        body,
      }),
    }),
    printReport: build.mutation<ReportData, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'imprimirRelatorio',
        },
        body,
      }),
    }),
    getSearchHistory: build.query<
      {
        buscasRecentes: Search[];
        maisBuscados: Search[];
      },
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'getHistoricoBuscas',
        },
        body: {
          id,
        },
      }),
    }),
  }),
});

export const {
  useGetTableConfigQuery,
  useLazyGetTableProductConfigQuery,
  useGetTableDataQuery,
  useExecuteActionMutation,
  usePrintReportMutation,
  useGetSearchHistoryQuery,
} = tablesApi;
