import { useRef } from 'react';

const useIsMounting = () => {
  const isMounting = useRef(true);

  if (isMounting.current) {
    isMounting.current = false;

    return true;
  }

  return isMounting.current;
};

export default useIsMounting;
