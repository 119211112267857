import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from 'core/types/cartItem';

import {
  CartState,
  AddPayload,
  AddMultipleItemsPayload,
  RemovePayload,
  IncrementPayload,
  DecrementPayload,
  SetItemPayload,
  UpdatePayload,
} from './types';

const initialState: CartState = {};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (
      state,
      { payload: { cartKey, cartItem } }: PayloadAction<AddPayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: [...currentCartItems, cartItem],
        },
      };
    },
    addMultipleItems: (
      state,
      {
        payload: { cartKey, cartItems, type },
      }: PayloadAction<AddMultipleItemsPayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          type,
          items: [...currentCartItems, ...cartItems],
        },
      };
    },
    remove: (
      state,
      { payload: { cartKey, cartItem } }: PayloadAction<RemovePayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: currentCartItems.filter((_, index) => index !== cartItem),
        },
      };
    },
    update: (
      state,
      { payload: { cartKey, index, cartItem } }: PayloadAction<UpdatePayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: currentCartItems.map((item, currentIndex) => {
            if (index === currentIndex) return cartItem;

            return item;
          }),
        },
      };
    },
    increment: (
      state,
      {
        payload: { cartKey, index, quantity = 1, price },
      }: PayloadAction<IncrementPayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: currentCartItems.map((item, currentIndex) => {
            if (index === currentIndex) {
              return {
                ...item,
                quantity: (item.quantity || 0) + quantity,
                stock: item.stock,
                price: {
                  value: price.value,
                  totalValue: item.price.totalValue + price.totalValue,
                },
              };
            }

            return item;
          }),
        },
      };
    },
    decrement: (
      state,
      { payload: { cartKey, cartItem } }: PayloadAction<DecrementPayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: currentCartItems.map((item, currentIndex) => {
            if (cartItem === currentIndex) {
              return {
                ...item,
                quantity: (item.quantity || 0) - 1,
              };
            }

            return item;
          }),
        },
      };
    },
    setItem: (
      state,
      { payload: { cartKey, index, cartItem } }: PayloadAction<SetItemPayload>,
    ) => {
      const currentCart = state[cartKey];
      const currentCartItems = state[cartKey]?.items || [];

      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items: currentCartItems.map((item, currentIndex) => {
            if (index === currentIndex) return cartItem;

            return item;
          }),
        },
      };
    },
    serverCart: (
      state,
      {
        payload: { cartKey, items },
      }: PayloadAction<{ cartKey: string; items: CartItem[] }>,
    ) => {
      const currentCart = state[cartKey];
      return {
        ...state,
        [cartKey]: {
          ...currentCart,
          items,
        },
      };
    },
    clear: (state, { payload }: PayloadAction<string>) =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => key !== payload),
      ),
    resetState: () => initialState,
  },
});

export const {
  add,
  addMultipleItems,
  remove,
  increment,
  resetState,
  clear,
  setItem,
  update,
  serverCart,
} = slice.actions;

export default slice.reducer;
