import React from 'react';
import { IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Iconify from '../Iconify';

import { WhatsAppButtonProps } from './props';

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ whatsAppLink }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const positionButton =
    pathname.includes('novo-pedido') && smDown
      ? {
          bottom: 126,
          right: 42,
        }
      : {
          bottom: 42,
          right: 42,
        };

  return (
    <IconButton
      component="a"
      target="_blank"
      href={whatsAppLink}
      size="large"
      sx={{
        ...positionButton,
        position: 'fixed',
        backgroundColor: '#33CC33',
        color: '#fff',
        zIndex: 999,
        width: 50,
        height: 50,

        '&:hover, &:focus': {
          backgroundColor: '#33CC33',
          color: '#fff',
        },
      }}
    >
      <Iconify icon="bi:whatsapp" />
    </IconButton>
  );
};

export default WhatsAppButton;
