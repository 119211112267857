import { styled, Button as MuiButton, IconButton } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: 24,

  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.grey[0],
  },
}));

export const PaymentButtonMobile = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey[0],
}));
