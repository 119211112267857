import React from 'react';
import { Skeleton, Typography } from '@mui/material';

import Builder from './Builder';
import { DynamicFormProps } from './props';
import useDynamicForm from './useDynamicForm';

const DynamicForm: React.FC<DynamicFormProps> = ({
  formId,
  type,
  size,
  buttonSize,
  containerProps,
  formContainerProps,
  actions,
  defaultValues,
  formValuesParams,
  onFormLoded,
  onSubmit,
  dataFormattingType,
  contextOfArguments,
  wrapper,
  skeletonProps = {
    height: 200,
  },
  navigateToAfterSubmit,
  showTitle = false,
  titleProps,
  applyReset,
  autoSubmit,
  changeDirty,
}) => {
  const {
    configFormId,
    responseData,
    pagedFields,
    conditions,
    loading,
    pagesMode,
    formArguments,
  } = useDynamicForm(formId, type, onFormLoded);

  if (loading || !pagesMode || pagedFields.length === 0 || !configFormId) {
    return <Skeleton variant="rounded" {...skeletonProps} />;
  }

  return (
    <>
      {showTitle && responseData && (
        <Typography variant="h1" {...titleProps}>
          {responseData.titulo}
        </Typography>
      )}

      <Builder
        id={configFormId}
        pagedFields={pagedFields}
        conditions={conditions}
        pagesMode={pagesMode}
        size={size}
        buttonSize={buttonSize}
        actions={actions}
        containerProps={containerProps}
        formContainerProps={formContainerProps}
        defaultValues={defaultValues}
        formValuesParams={formValuesParams}
        onSubmit={onSubmit}
        dataFormattingType={dataFormattingType}
        navigateToAfterSubmit={navigateToAfterSubmit}
        formArguments={formArguments}
        contextOfArguments={contextOfArguments}
        publicForm={type !== 'DEFAULT'}
        wrapper={wrapper}
        applyReset={applyReset}
        autoSubmit={autoSubmit}
        changeDirty={changeDirty}
      />
    </>
  );
};

export default DynamicForm;
