import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ChangePageOptions,
  SetFilterPayload,
  SetSearchPayload,
  SetSortPayload,
  State,
} from './types';

const initialState: State = {};

const slice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    changePageOptions: (
      state,
      { payload: { table, pageOptions } }: PayloadAction<ChangePageOptions>,
    ) => ({
      ...state,
      [table]: {
        ...state[table],
        pageOptions,
      },
    }),
    setSearch: (
      state,
      { payload: { table, search } }: PayloadAction<SetSearchPayload>,
    ) => ({
      ...state,
      [table]: {
        ...state[table],
        search,
      },
    }),
    setSort: (
      state,
      { payload: { table, sort } }: PayloadAction<SetSortPayload>,
    ) => ({
      ...state,
      [table]: {
        ...state[table],
        sort,
      },
    }),
    setFilter: (
      state,
      { payload: { table, filters } }: PayloadAction<SetFilterPayload>,
    ) => ({
      ...state,
      [table]: {
        ...state[table],
        filters,
        pageOptions: undefined,
      },
    }),
    resetTable: (state, { payload }: PayloadAction<string>) =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => key !== payload),
      ),
    resetTablesState: () => initialState,
  },
});

export const {
  changePageOptions,
  setSearch,
  setSort,
  setFilter,
  resetTablesState,
  resetTable,
} = slice.actions;

export default slice.reducer;
