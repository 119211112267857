import { Popper, PopperProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { bgBlur } from 'core/utils/cssStyles';

export const StyledPopper = styled((props: PopperProps) => (
  <Popper {...props} />
))(({ theme }) => {
  return {
    '& .MuiAutocomplete-paper': {
      padding: theme.spacing(1, 0),
    },
    '& .MuiListSubheader-root': {
      '&.MuiAutocomplete-groupLabel': {
        ...bgBlur({ color: theme.palette.background.neutral }),
        ...theme.typography.overline,
        top: 0,
        margin: 0,
        lineHeight: '48px',
        borderRadius: theme.shape.borderRadius,
      },
    },
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option': {
        padding: theme.spacing(1.15, 2),
        margin: 0,
        display: 'block',
        border: `dashed 1px transparent`,
        borderBottomColor: theme.palette.divider,
        '&:last-of-type': {
          borderBottomColor: 'transparent',
        },
        '&:hover': {
          borderColor: theme.palette.primary.main,
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity,
          ),
        },
      },
    },
  };
});
