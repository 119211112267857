import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { APP_TITLE } from 'core/config';
import { Layout } from 'core/models/layout';
import { layoutApi } from 'core/services/layoutApi';

interface LayoutState {
  readonly title: string;
  readonly data: Layout;
  readonly isNavMini: boolean;
  readonly primaryColor?: string;
  readonly themeDirection?: 'horizontal' | 'vertical' | string;
}
const initialState: LayoutState = {
  title: APP_TITLE,
  isNavMini: false,
  data: {
    habilitaSolicitacaoAcesso: false,
    habilitaVitrine: false,
    exibirRecuperarSenha: true,
  } as Layout,
  themeDirection: 'horizontal',
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    resetToDefault: () => initialState,
    toogleNavMini: state => ({ ...state, isNavMini: !state.isNavMini }),
    setNavAsMini: state => ({ ...state, isNavMini: true }),
    setTitle: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        title: action.payload,
      };
    },
    setThemeDirection: (
      state,
      action: PayloadAction<'horizontal' | 'vertical'>,
    ) => {
      return {
        ...state,
        themeDirection: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      layoutApi.endpoints.getLayout.matchFulfilled,
      (state, action) => {
        if (Object.keys(action.payload).length > 0) {
          return {
            ...state,
            data: action.payload,
            title: action.payload.titulo || APP_TITLE,
            primaryColor: action.payload?.corPrimaria,
            themeDirection: action.payload.orientacaoMenu.toLocaleLowerCase(),
          };
        }

        return state;
      },
    );
  },
});

export const {
  resetToDefault,
  setTitle,
  setThemeDirection,
  toogleNavMini,
  setNavAsMini,
} = slice.actions;

export default slice.reducer;
