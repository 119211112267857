import React, { lazy, useMemo } from 'react';

import { FieldType } from 'core/models/formFieldType';
import { Validation } from 'core/models/validation';
import loadable from 'core/utils/loadable';
import { isMultiple } from 'core/utils/number';
import validatorsRules from 'core/utils/validatorsRules';
import sort from 'core/utils/sort';

import { FormFieldProps } from './props';

const OptionDetailed = loadable(
  lazy(() => import('components/hook-form/OptionDetailed')),
);
const Incrementer = loadable(
  lazy(() => import('components/hook-form/Incrementer')),
);
const Autocomplete = loadable(
  lazy(() => import('components/hook-form/Autocomplete')),
);
const DatePicker = loadable(
  lazy(() => import('components/hook-form/DatePicker')),
);
const DateRangePicker = loadable(
  lazy(() => import('components/hook-form/DateRangePicker')),
);
const DateTimePicker = loadable(
  lazy(() => import('components/hook-form/DateTimePicker')),
);
const Field = loadable(lazy(() => import('components/hook-form/Field')));
const Typography = loadable(
  lazy(() => import('components/hook-form/Typography')),
);
const NumberInput = loadable(
  lazy(() => import('components/hook-form/NumberInput')),
);
const TimePicker = loadable(
  lazy(() => import('components/hook-form/TimePicker')),
);
const SubForm = loadable(
  lazy(() => import('components/hook-form/DynamicForm/SubForm')),
);
const RadioGroup = loadable(
  lazy(() => import('components/hook-form/RadioGroup')),
);
const Switch = loadable(lazy(() => import('components/hook-form/Switch')));
const MultiCheckbox = loadable(
  lazy(() =>
    import('components/hook-form/Checkbox').then(module => ({
      default: module.MultiCheckbox,
    })),
  ),
);
const MaskedInput = loadable(
  lazy(() => import('components/hook-form/MaskedInput')),
);
const UploadForm = loadable(lazy(() => import('components/upload/UploadForm')));
const Address = loadable(lazy(() => import('components/hook-form/Address')));

const FormField: React.FC<FormFieldProps> = ({
  field,
  size,
  formId,
  formArguments,
  publicForm,
  hidden = false,
  onLoaded,
}) => {
  const {
    id,
    incremento,
    limiteMaximo,
    limiteMinimo,
    mascara,
    qtdCasasDecimais,
    requerido,
    somenteLeitura,
    textoAjuda,
    tipo,
    titulo,
    validacao,
    valorPadrao,
  } = field;

  const identifier = id.toString();
  const originalId = id.split('.').pop();
  const onlyMultiples =
    qtdCasasDecimais !== undefined && incremento !== undefined;

  const rules = useMemo(() => {
    if (hidden) return undefined;

    const validation =
      validacao && validacao !== Validation.NENHUM
        ? validatorsRules[validacao]
        : undefined;

    return {
      required: {
        value: requerido || false,
        message: `Campo ${titulo} é obrigatório.`,
      },
      validate: { ...validation },

      ...(field.tipo === FieldType.SELECAO_MULTIPLA && {
        validate: {
          validNumber: (value: string[]) => {
            if (limiteMinimo !== undefined && value.length < limiteMinimo)
              return `Selecione ao menos ${limiteMinimo} opções`;

            if (limiteMaximo !== undefined && value.length > limiteMaximo)
              return `Selecione no máximo ${limiteMaximo} opções`;

            return true;
          },
          ...validation,
        },
      }),

      ...((field.tipo === FieldType.TEXTO_CURTO ||
        field.tipo === FieldType.TEXTO_LONGO) && {
        validate: {
          validNumber: (value: string[]) => {
            if (limiteMinimo !== undefined && value.length < limiteMinimo)
              return `Limite mínimo de caracteres é ${limiteMinimo}`;

            if (limiteMaximo !== undefined && value.length > limiteMaximo)
              return `Limite máximo de caracteres é ${limiteMaximo}`;

            return true;
          },
          ...validation,
        },
      }),

      ...(field.tipo === FieldType.CONTADOR && {
        validate: {
          validNumber: (value: number) => {
            if (limiteMinimo !== undefined && value < limiteMinimo)
              return `O valor mínimo é ${limiteMinimo}`;

            if (limiteMaximo !== undefined && value > limiteMaximo)
              return `O valor máximo é ${value}`;

            if (onlyMultiples) {
              if (!isMultiple(value, incremento, qtdCasasDecimais))
                return `O valor deve ser um múltiplo de ${incremento}`;
            }

            return true;
          },
          ...validation,
        },
      }),
    };
  }, [
    field.tipo,
    hidden,
    incremento,
    limiteMaximo,
    limiteMinimo,
    onlyMultiples,
    qtdCasasDecimais,
    requerido,
    titulo,
    validacao,
  ]);

  const selectionComponentPros = {
    formArguments,
    ...((field.tipo === FieldType.MENU_SUSPENSO ||
      field.tipo === FieldType.SELECAO_UNICA ||
      field.tipo === FieldType.PESQUISA ||
      field.tipo === FieldType.SELECAO_MULTIPLA) && {
      ...(field.tipoOpcoes === 'MANUAL' && {
        options:
          sort(field.opcoes || [], 'descricao').map(o => ({
            label: o.descricao,
            value: o.valor,
          })) || [],
      }),

      ...(field.tipoOpcoes === 'TABELA' && {
        requestData: {
          formulario: formId,
          campo: originalId,
        },
        publicFormField: publicForm,
      }),
    }),
  };

  if (hidden) return null;

  if (validacao === Validation.MOEDA) {
    return (
      <NumberInput
        name={identifier}
        label={titulo}
        rules={rules}
        configs={field}
        required={requerido}
        size={size}
        disabled={somenteLeitura}
        helperText={textoAjuda}
        prefix="R$ "
        decimalScale={2}
      />
    );
  }

  if (mascara) {
    const maskArray = mascara.split(',');

    return (
      <MaskedInput
        name={identifier}
        label={titulo}
        rules={rules}
        disabled={somenteLeitura}
        configs={field}
        required={requerido}
        size={size}
        helperText={textoAjuda}
        mask={maskArray.map(mask => ({ mask: mask.trim() }))}
        fullWidth
      />
    );
  }

  const FIELD = {
    [FieldType.ANEXO]: (
      <UploadForm
        name={identifier}
        actionUrl="UploadFileFormServlet"
        multiple={false}
        label={titulo}
        maxSize={limiteMaximo}
        required={requerido}
      />
    ),
    [FieldType.DATA]: (
      <DatePicker
        name={identifier}
        label={titulo}
        rules={rules}
        slotProps={{
          textField: {
            required: requerido,
            size,
            disabled: somenteLeitura,
            helperText: textoAjuda,
          },
        }}
      />
    ),
    [FieldType.DATA_HORA]: (
      <DateTimePicker
        name={identifier}
        label={titulo}
        rules={rules}
        slotProps={{
          textField: {
            required: requerido,
            size,
            disabled: somenteLeitura,
            helperText: textoAjuda,
          },
        }}
      />
    ),
    [FieldType.DESCRICAO]: typeof valorPadrao === 'string' && (
      <Typography
        name={identifier}
        label={titulo}
        rules={rules}
        configs={field}
      />
    ),
    [FieldType.HORA]: (
      <TimePicker
        name={identifier}
        label={titulo}
        rules={rules}
        slotProps={{
          textField: {
            required: requerido,
            size,
            disabled: somenteLeitura,
            helperText: textoAjuda,
          },
        }}
      />
    ),
    [FieldType.IMAGEM]: <div>{titulo}</div>,
    [FieldType.INTERVALO_DATA]: (
      <DateRangePicker
        name={identifier}
        label={titulo}
        rules={rules}
        slotProps={{
          textField: { required: requerido, size, disabled: somenteLeitura },
        }}
      />
    ),
    [FieldType.MENU_SUSPENSO]: (
      <Autocomplete
        name={identifier}
        label={titulo}
        rules={rules}
        size={size}
        multiple={false}
        configs={field}
        required={requerido}
        disabled={somenteLeitura}
        helperText={textoAjuda}
        {...selectionComponentPros}
      />
    ),
    [FieldType.PESQUISA]: (
      <OptionDetailed
        name={identifier}
        label={titulo}
        rules={rules}
        size={size}
        multiple={false}
        configs={field}
        required={requerido}
        disabled={somenteLeitura}
        helperText={textoAjuda}
        {...selectionComponentPros}
      />
    ),
    [FieldType.NUMERO_DECIMAL]: (
      <NumberInput
        name={identifier}
        label={titulo}
        rules={rules}
        decimalScale={qtdCasasDecimais || 2}
        fixedDecimalScale={false}
        size={size}
        required={requerido}
        disabled={somenteLeitura}
        helperText={textoAjuda}
      />
    ),
    [FieldType.NUMERO_INTEIRO]: (
      <NumberInput
        name={identifier}
        label={titulo}
        rules={rules}
        configs={field}
        required={requerido}
        size={size}
        disabled={somenteLeitura}
        helperText={textoAjuda}
      />
    ),
    [FieldType.SELECAO_MULTIPLA]: (
      <MultiCheckbox
        name={identifier}
        label={titulo}
        filter
        rules={rules}
        configs={field}
        {...selectionComponentPros}
      />
    ),
    [FieldType.SELECAO_UNICA]: (
      <RadioGroup
        name={identifier}
        label={titulo}
        filter
        rules={rules}
        configs={field}
        {...selectionComponentPros}
      />
    ),
    [FieldType.TEXTO_CURTO]: (
      <Field
        name={identifier}
        label={titulo}
        rules={rules}
        disabled={somenteLeitura}
        configs={field}
        required={requerido}
        size={size}
        helperText={textoAjuda}
        inputProps={{ minLength: limiteMinimo, maxLength: limiteMaximo }}
      />
    ),
    [FieldType.TEXTO_LONGO]: (
      <Field
        name={identifier}
        label={titulo}
        rules={rules}
        multiline
        rows={4}
        disabled={somenteLeitura}
        configs={field}
        required={requerido}
        size={size}
        helperText={textoAjuda}
        inputProps={{ minLength: limiteMinimo, maxLength: limiteMaximo }}
      />
    ),
    [FieldType.SIM_NAO]: (
      <Switch
        name={identifier}
        label={titulo}
        rules={rules}
        disabled={somenteLeitura}
        configs={field}
        valueType="string"
      />
    ),
    [FieldType.CONTADOR]: (
      <Incrementer
        name={identifier}
        label={titulo}
        rules={rules}
        disabled={somenteLeitura}
        configs={field}
        required={requerido}
        size={size}
        fullWidth
        decimalScale={qtdCasasDecimais}
        increment={incremento}
        updadteForMultiple={onlyMultiples}
        allowNegative
        helperText={textoAjuda}
      />
    ),
    [FieldType.ENDERECO]: (
      <Address
        name={identifier}
        label={titulo}
        rules={rules}
        disabled={somenteLeitura}
        configs={field}
        publicForm={publicForm}
        formId={formId?.toString()}
        required={requerido}
      />
    ),
    // TODO: Fazer esse componente dentro do DynamicForm para de passar a prop 'onLoaded'
    [FieldType.SUB_FORM]: (
      <SubForm field={field} size={size} onLoaded={onLoaded} />
    ),
  };

  return FIELD[tipo];
};

export default FormField;
