import { useScroll } from 'framer-motion';
import { useState, useEffect } from 'react';

type ReturnType = boolean;

interface UseScrollOptions extends Omit<ScrollOptions, 'container' | 'target'> {
  container?: React.RefObject<HTMLElement>;
  target?: React.RefObject<HTMLElement>;
}

const useOffSetTop = (top = 100, options?: UseScrollOptions): ReturnType => {
  const { scrollY } = useScroll(options);

  const [value, setValue] = useState(false);

  useEffect(
    () =>
      scrollY.onChange(scrollHeight => {
        if (scrollHeight > top) {
          setValue(true);
        } else {
          setValue(false);
        }
      }),
    [scrollY, top],
  );

  return value;
};

export default useOffSetTop;
