import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetPriceAndStockMutation } from 'core/services/orderApi';
import { CartItem } from 'core/types/cartItem';
import { CartItemHook } from 'core/types/cartitemHook';
import { roundIfNecessary } from 'core/utils/number';

import { objectToArrayObject } from 'core/utils/array';
import { paymentProcess } from 'core/utils/payment';
import useArguments from './useArguments';
import { useAuth } from './useAuth';
import useCart from './useCart';
import useDeepCompareEffect from './useDeepCompareEffect';
import useOrder from './useOrder';

const useCartItem = (
  product: CartItem,
  index?: number,
  addToCartAfterCalculatingPrice = false,
  cartKey?: string,
  onDelete?: () => void,
): CartItemHook => {
  const { enqueueSnackbar } = useSnackbar();

  const { userType } = useAuth();
  const {
    orderConfig,
    preOrderFormValues,
    updateOrderStateStoredValues,
    selectedPaymentMethod,
    contextOfArguments,
    tableConfig,
  } = useOrder(cartKey);
  const {
    id,
    tabelaProdutoID,
    formularioPrePedidoID,
    validaEstoque,
    habilitaDecontoPercentual,
    habilitaDecontoValor,
  } = orderConfig || {};
  const hasDiscount = habilitaDecontoPercentual || habilitaDecontoValor;

  const [getPriceAndStock, { isLoading, error }] =
    useGetPriceAndStockMutation();
  const errorRef = useRef(error);
  const alertRef = useRef<string>();

  const {
    updateItem,
    addItem,
    items: cartItems,
    isLoadingServer,
  } = useCart(cartKey);

  const { units } = product;
  const modifyingItem = useRef(false);
  const modifyingProject = useRef(false);
  const calculatingDiscount = useRef<'discountPercentage' | 'discountValue'>();
  const backupCartItem = useRef(product);
  const productReference = useRef(product);
  const deleting = useRef(false);

  const [cartItem, setCartItem] = useState(product);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);

  const KEY = `${cartItem.productId}.${cartItem.unityId}.${cartItem.control}.0.0`;
  const itemInCart =
    !hasDiscount && !cartItem.discountPercentage && !cartItem.discountValue
      ? cartItems.find(ci => ci.key === KEY)
      : undefined;

  const { requestArguments = [] } = useArguments(
    contextOfArguments,
    tableConfig?.argumentos,
  );

  const validateStock = useCallback(
    (item: CartItem) => {
      if (validaEstoque && item.unit?.seletor === 'CONTADOR') {
        const quantityInCart = cartItems
          .filter(
            i =>
              i.productId ===
                (index !== undefined
                  ? item.product
                  : item.productIdTemporary) &&
              i.unityId === item.unityIdTemporary,
          )
          .reduce((acc, curr) => acc + (curr.quantity || 0), 0);

        const insufficientStock = itemInCart
          ? (item.quantity || 0) > (item.stock || 0)
          : (item.quantity || 0) + quantityInCart > (item.stock || 0);

        if (insufficientStock) {
          const message =
            index === undefined ? (
              <>
                Não é possível adicionar
                <strong>{` ${item.productName} `}</strong> ao carrinho, estoque
                insuficiente.
              </>
            ) : (
              <>
                Não é possível atualizar
                <strong>{` ${item.productName} `}</strong> no carrinho, estoque
                insuficiente.
              </>
            );

          enqueueSnackbar(<div>{message}</div>, {
            variant: 'error',
          });

          setCartItem(state => ({
            ...(index !== undefined ? productReference.current : state),
            quantity:
              index !== undefined
                ? productReference.current.quantity
                : itemInCart?.quantity || 0,
          }));

          return false;
        }
      }

      return true;
    },
    [cartItems, validaEstoque, index, itemInCart, enqueueSnackbar],
  );

  const handleAddToCart = useCallback(
    (item: CartItem) => {
      if (!validateStock(item)) return;

      addItem(
        {
          ...item,
          unityId: item.unityIdTemporary,
          unit: item.unitTemporary,
          productId: item.productIdTemporary as string,
        },
        itemInCart ? 'UPDATE' : 'INCREMENT',
      );
      enqueueSnackbar(`${item.productName} adicionado ao carrinho`, {
        variant: 'success',
      });

      if (userType === 'PARCEIRO' && cartItems.length === 0) {
        updateOrderStateStoredValues('cartOpen', true);
      }
    },
    [
      cartItems,
      userType,
      itemInCart,
      validateStock,
      addItem,
      updateOrderStateStoredValues,
      enqueueSnackbar,
    ],
  );

  const handleChangesCartItem = useCallback(
    (item: CartItem) => {
      if (index !== undefined && !item.projectValues) {
        if (!validateStock(item)) return;

        updateItem(index, item);

        return;
      }

      if (
        addToCartAfterCalculatingPrice &&
        !item.projectValues &&
        item.quantity &&
        item.quantity > 0
      ) {
        handleAddToCart(item);
      }
    },
    [
      index,
      addToCartAfterCalculatingPrice,
      validateStock,
      updateItem,
      handleAddToCart,
    ],
  );

  const updateInCart = () => {
    if (index === undefined) return;

    updateItem(index, cartItem);
  };

  const reset = useCallback(() => {
    modifyingItem.current = false;
    errorRef.current = undefined;
    alertRef.current = undefined;

    setCartItem(state => ({
      ...backupCartItem.current,
      productId: product.productId,
      unit: state.unit,
      unityId: state.unityId,
      productName: state.productName,
      ...(state.key === '0' && { projectValues: undefined }),
    }));
  }, [product]);

  const updateValuesCartItem = useCallback(
    async (item: CartItem) => {
      try {
        const { item: itemResponse, grade } = await getPriceAndStock({
          contexto: {
            id,
            carrinho: {
              prePedido: {
                formularioID: formularioPrePedidoID,
                dados: objectToArrayObject(
                  preOrderFormValues,
                  'campo',
                  'valor',
                ).filter(i => i.valor),
              },
              items: [],
              checkout: {
                tipoNegociacaoID:
                  selectedPaymentMethod &&
                  paymentProcess(selectedPaymentMethod)?.id,
              },
            },
          },
          contextoTabela: {
            tabelaID: tabelaProdutoID,
            filtros: [],
            argumentos: requestArguments,
            onlyCamposIDs: [],
            paginado: true,
            sort: null,
          },
          produtoSelecionado: {
            id: item.productId,
            quantidade: item.quantity,
            valorDesconto:
              item.discountType === 'VALUE' ? item.discountValue : undefined,
            valorPercentual:
              item.discountType === 'PERCENT'
                ? item.discountPercentage
                : undefined,
            unidade: item.unityId,
            registro: item.product ? item.product['@original'] : undefined,
            valoresProjeto: item.projectValues,
            controle: item.control,
          },
        }).unwrap();

        const price = itemResponse.preco;

        const percentDiscount =
          price.promocao?.isPromocao && price.promocao.percentualDesconto
            ? `${roundIfNecessary(price.promocao.percentualDesconto)}%`
            : undefined;

        const discountPerQuantity = price.promocao?.descontoQuantidade || [];
        const maxDiscountPerQuantity =
          price.promocao?.isPromocao && discountPerQuantity.length > 0
            ? `ATÉ ${roundIfNecessary(
                Math.max(...discountPerQuantity.map(d => d.percentualDesconto)),
              )}%`
            : undefined;

        const newValues = {
          control: itemResponse.controle || '',
          discountPercentage: price.percentualDesconto || 0,
          discountValue: price.valorDesconto || 0,
          price: {
            value: price.precoUnitarioDesconto || price.precoUnitario,
            totalValue: price.precoTotalDesconto || price.precoTotal,
            unitaryValue: price.precoUnitario,
            unitPriceDefaultUnit: price.precoUnitarioUnidadePadrao,
            promotion: price.promocao,
            calculadora: price.calculadora,
          },
          projectValues: price.calculadora?.projeto?.map(c => ({
            id: c.id,
            value: c.valor,
          })),
          quantity: itemResponse.quantidade.quantidade,
          unityIdTemporary: itemResponse.unidade.id,
          unitTemporary: itemResponse.unidade,
          productIdTemporary: itemResponse.id,
          stock: itemResponse.estoque.estoque,
          expectedDate: itemResponse.estoque.dtEntregaPrevista,
          productName: itemResponse.descricao,
          productGrid: grade,
          tag: maxDiscountPerQuantity || percentDiscount,
        };

        alertRef.current = itemResponse.mensagem || undefined;

        backupCartItem.current = {
          ...item,
          ...newValues,
        };

        setCartItem(state => ({
          ...state,
          ...newValues,
        }));

        handleChangesCartItem({
          ...item,
          ...newValues,
        });

        modifyingItem.current = false;
        modifyingProject.current = false;
        calculatingDiscount.current = undefined;
      } catch {
        calculatingDiscount.current = undefined;
        modifyingItem.current = false;
        modifyingProject.current = false;

        setCartItem(state => ({
          ...backupCartItem.current,
          ...(state.unit?.seletor === 'PROJETO' && {
            unit: state.unit,
            unityId: state.unityId,
          }),
          ...(state.key === '0' && { projectValues: undefined }),
        }));
      }
    },
    [
      id,
      tabelaProdutoID,
      formularioPrePedidoID,
      preOrderFormValues,
      selectedPaymentMethod,
      getPriceAndStock,
      handleChangesCartItem,
      requestArguments,
    ],
  );

  const updateCartItem = (key: keyof CartItem, value: unknown) => {
    modifyingItem.current = true;
    errorRef.current = undefined;
    alertRef.current = undefined;
    deleting.current = false;

    calculatingDiscount.current =
      key === 'discountPercentage' || key === 'discountValue' ? key : undefined;

    modifyingProject.current = key === 'projectValues' || false;

    let newValues = {};

    if (key === 'unityId') {
      const newUnity = units.find(unit => unit.id === value);

      if (newUnity && newUnity.seletor === 'PROJETO') {
        newValues = {
          quantity: undefined,
        };
      } else {
        newValues = { projectValues: undefined, quantity: 0 };
      }

      newValues = { ...newValues, unit: newUnity };
    }
    const cartItemRequest: CartItem = {
      ...cartItem,
      ...(key === 'discountPercentage' && {
        discountValue: 0,
        discountType: 'PERCENT',
      }),
      ...(key === 'discountValue' && {
        discountPercentage: 0,
        discountType: 'VALUE',
      }),
      ...(key === 'quantity' && { projectValues: undefined }),
      ...(key === 'projectValues' && { quantity: undefined }),
      ...newValues,
      [key]: value,
    };

    setCartItem(state => ({
      ...state,
      ...(!state.discountType &&
        (key === 'discountPercentage' || key === 'discountValue') && {
          discountType: key === 'discountPercentage' ? 'PERCENT' : 'VALUE',
        }),
      ...(key === 'discountPercentage' && { discountValue: 0 }),
      ...(key === 'discountValue' && { discountPercentage: 0 }),
      ...(key === 'quantity' && { projectValues: undefined }),
      ...(key === 'projectValues' && { quantity: undefined }),
      ...newValues,
      lastUpdated: Date.now(),
      [key]: value,
    }));

    if (key === 'quantity' && value === 0 && onDelete) {
      deleting.current = true;
      onDelete();
    }

    if (delayTimer.current) {
      clearTimeout(delayTimer.current);
    }

    delayTimer.current = setTimeout(() => {
      if (
        !modifyingItem.current ||
        !cartItemRequest.unityId ||
        deleting.current ||
        (cartItemRequest.quantity === undefined &&
          cartItemRequest.projectValues === undefined)
      )
        return;

      updateValuesCartItem(cartItemRequest);
    }, 500);
  };

  const insertInCart = () => handleAddToCart(cartItem);

  useEffect(() => {
    return () => {
      if (delayTimer.current) {
        clearTimeout(delayTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    errorRef.current = error;
  }, [error]);

  useDeepCompareEffect(() => {
    setCartItem(product);
    productReference.current = product;
  }, [product, cartItems]);

  return {
    cartItem:
      itemInCart && !modifyingItem.current
        ? {
            ...cartItem,
            quantity: itemInCart.quantity,
            price: itemInCart.price,
          }
        : cartItem,
    updateCartItem,
    resetProduct: reset,
    insertInCart,
    updateInCart,
    calculatingValues: isLoading || isLoadingServer,
    modifyingItem: modifyingItem.current,
    calculatingDiscount: calculatingDiscount.current,
    modifyingProject: modifyingProject.current,
    error: errorRef.current,
    alert: alertRef.current,
  };
};

export default useCartItem;
