import { useCallback, useMemo } from 'react';

import { OrderConfig } from 'core/models/orderConfig';
import { PaymentMethod } from 'core/models/paymentMethod';
import {
  useCalculateOrderValueMutation,
  useSaveOrderMutation,
} from 'core/services/orderApi';
import { CartItem } from 'core/types/cartItem';
import { fCurrencyBRL } from 'core/utils/formatNumber';

import { Orders } from 'core/types/orderCalculation';
import useDeepCompareEffect from './useDeepCompareEffect';

export interface Props {
  additionalFormValues?: object[];
  preOrderFormValues?: object[];
  address?: string;
  labelAddress?: string;
  cartKey?: string;
  pagamento?: object;
  argumentos: unknown[] | undefined;
  paymentMethod?: PaymentMethod;
  orderConfig?: OrderConfig;
  orderKey?: string;
  type: 'NEW_ORDER' | 'REORDER';
  checkoutFormHasRequiredFields: boolean;
  items: CartItem[];
  cartTotal: number;
  onOrderSucess: (orders: Orders) => void;
  onOrderFail: (message: string) => void;
}
const useOrderCheckout = ({
  additionalFormValues,
  preOrderFormValues,
  address,
  labelAddress,
  paymentMethod,
  pagamento,
  argumentos,
  cartKey,
  orderConfig,
  type,
  checkoutFormHasRequiredFields,
  items,
  cartTotal,
  onOrderSucess,
  onOrderFail,
}: Props) => {
  const { id, prePedidoType, formularioPrePedidoID, checkout } =
    orderConfig || {};
  const checkoutForm = checkout?.formularioID;

  const [calculateOrderValue, { data, isLoading: calculatingSummary, error }] =
    useCalculateOrderValueMutation();

  const [saveOrder, { isLoading: savingOrder }] = useSaveOrderMutation();

  const checkPaymentMethod = () => {
    const totalInCart = cartTotal;
    if (!paymentMethod) return undefined;

    if (
      paymentMethod.minimumOrder !== undefined &&
      paymentMethod.minimumOrder > 0 &&
      paymentMethod.minimumOrder > totalInCart
    ) {
      return `O pedido mínimo para essa forma de pagamento é de ${fCurrencyBRL(
        paymentMethod.minimumOrder,
      )}. É necessário alterar a forma de pagamento ou adicionar mais itens no carrinho.`;
    }

    if (
      paymentMethod.maximumOrder !== undefined &&
      paymentMethod.maximumOrder > 0 &&
      paymentMethod.maximumOrder < totalInCart
    ) {
      return `O pedido máximo para essa forma de pagamento é de ${fCurrencyBRL(
        paymentMethod.maximumOrder,
      )}. É necessário alterar a forma de pagamento ou alterar os itens no carrinho.`;
    }

    return undefined;
  };

  const paymentMethodMessage = checkPaymentMethod();
  const paymentMethodValid = paymentMethodMessage === undefined;

  const summary = paymentMethodValid ? data?.pedidos[0] : undefined;
  const totalOrderAmount = data?.pedidos.reduce(
    (acc, curr) => acc + curr.total || 0,
    0,
  );

  const requestData = useMemo(
    () => ({
      id,
      carrinho: {
        id: cartKey,
        prePedido: {
          formularioID: formularioPrePedidoID,
          dados: preOrderFormValues,
        },
        checkout: {
          pagamento,
          tipoNegociacaoID: paymentMethod?.id,
          enderecoID: address,
          endereco: labelAddress,
          formularioID: checkoutForm,
          desconto: 0,
          dados: additionalFormValues,
        },
        items: items.map(item => ({
          id: item.productId,
          unidade: item.unityId,
          quantidade: item.quantity,
          percentualDesconto: item.discountPercentage,
          valorDesconto: item.discountValue,
          valoresProjeto: item.projectValues?.map(v => ({
            id: v.id,
            valor: v.value,
          })),
        })),
      },
      argumentos,
    }),
    [
      additionalFormValues,
      address,
      checkoutForm,
      formularioPrePedidoID,
      id,
      cartKey,
      pagamento,
      items,
      paymentMethod,
      preOrderFormValues,
      labelAddress,
      argumentos,
    ],
  );

  const callSaveOrder = useCallback(async () => {
    try {
      const request = {
        name: 'SalvarPedido',
        method: 'salvar',
        body: {
          ...requestData,
        },
      };

      const orders = await saveOrder(request).unwrap();

      onOrderSucess(orders);
    } catch (err: any) {
      onOrderFail(err?.data?.message || 'Erro ao realizar solicitação');
    }
  }, [saveOrder, requestData, onOrderSucess, onOrderFail]);

  const callCalculateOrderValue = useCallback(() => {
    if (requestData.carrinho.items.length === 0) return;

    calculateOrderValue({ ...requestData });
  }, [calculateOrderValue, requestData]);

  useDeepCompareEffect(() => {
    if (
      !address ||
      !paymentMethod ||
      (prePedidoType !== 'NENHUM' &&
        (!preOrderFormValues || preOrderFormValues.length === 0) &&
        type === 'NEW_ORDER') ||
      (checkoutForm &&
        (!additionalFormValues ||
          (additionalFormValues.length === 0 &&
            checkoutFormHasRequiredFields))) ||
      !paymentMethodValid
    )
      return;
    if (pagamento) {
      callSaveOrder();
    } else {
      callCalculateOrderValue();
    }
  }, [
    additionalFormValues,
    address,
    checkoutForm,
    paymentMethod,
    preOrderFormValues,
    prePedidoType,
    requestData,
    type,
    checkoutFormHasRequiredFields,
    paymentMethodValid,
  ]);

  return {
    callSaveOrder,
    summary,
    totalOrderAmount,
    calculatingSummary,
    error,
    savingOrder,
    paymentMethodMessage,
    paymentMethodValid,
  };
};

export default useOrderCheckout;
