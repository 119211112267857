import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import Image from 'components/elements/Image';
import { NavSectionMini } from 'components/nav-section';
import { NAV } from 'core/config';
import { useSelector } from 'core/redux/store';
import { hideScrollbarX } from 'core/utils/cssStyles';
import { getImageByType } from 'core/utils/image';
import NavToggleButton from './NavToggleButton';

import { Props } from './props';

interface NavMiniProps extends Props {
  onClickNavToggle: VoidFunction;
}

const NavMini: React.FC<NavMiniProps> = ({ navConfig, onClickNavToggle }) => {
  const { tipoImagemFavicon, imagemFavicon } = useSelector(
    state => state.layout.data,
  );

  const favicon = useMemo(
    () => getImageByType(tipoImagemFavicon, imagemFavicon),
    [imagemFavicon, tipoImagemFavicon],
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
        backgroundColor: 'grey.0',
      }}
    >
      <NavToggleButton
        onClick={onClickNavToggle}
        sx={{
          top: 32,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          backgroundColor: 'grey.0',
          ...hideScrollbarX,
        }}
      >
        <Stack
          position="relative"
          minHeight={60}
          alignItems="center"
          justifyContent="center"
          p={1}
        >
          <Link to="/portal" style={{ padding: 12 }}>
            <Image
              src={favicon || '/assets/images/b2b-symbol.svg'}
              sx={{
                '& img': {
                  objectFit: 'contain',
                  maxWidth: '100%',
                  width: '100%',
                  height: 'auto',
                },
              }}
            />
          </Link>
        </Stack>

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
};

export default NavMini;
