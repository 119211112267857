/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import EmptyContent from 'components/elements/EmptyContent';
import Iconify from 'components/elements/Iconify';
import MenuPopover from 'components/elements/MenuPopover';
import TextMaxLine from 'components/elements/TextMaxLine';
import Scrollbar from 'components/wrappers/Scrollbar';
import { Notification } from 'core/models/notification';
import { NOTIFICATIONS_PATH } from 'core/routes/@portal/dashboardRoutes';
import {
  useGetBellNotificationsQuery,
  useGetNextNotificationQuery,
} from 'core/services/notificationsApi';
import { fDate } from 'core/utils/formatTime';
import { Icon } from 'core/types/icon';
import { unEscape } from 'core/utils/html';
import { NotificationProps } from './props';

const FIVE_MINUTES = 300000;

const Notifications: React.FC<NotificationProps> = ({
  receiveNotification,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const { data, isLoading } = useGetBellNotificationsQuery(undefined, {
    pollingInterval: FIVE_MINUTES,
    refetchOnMountOrArgChange: true,

    skip: !receiveNotification,
  });
  const notifications = data?.items || [];
  const total = data?.total || 0;

  const { data: notify } = useGetNextNotificationQuery(undefined, {
    skip: !data || !receiveNotification,
    pollingInterval: FIVE_MINUTES,
    refetchOnMountOrArgChange: true,
  });

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => setOpenPopover(null);

  const handleClickNotification = (notification: Notification) => {
    navigate(NOTIFICATIONS_PATH, { state: { notification } });
    handleClosePopover();
  };

  useEffect(() => {
    if (notify) {
      enqueueSnackbar(
        <Stack maxWidth={320}>
          <Typography
            variant="body2"
            fontWeight="700"
            dangerouslySetInnerHTML={{ __html: unEscape(notify.titulo) }}
          />

          <TextMaxLine
            line={3}
            variant="body2"
            fontWeight="grey.600"
            dangerouslySetInnerHTML={{ __html: unEscape(notify.descricao) }}
          />
        </Stack>,
        {
          autoHideDuration: 10000,
          variant: 'warning',
          action: key => (
            <>
              <Button
                size="small"
                variant="soft"
                color="warning"
                onClick={() => closeSnackbar(key)}
              >
                Ok
              </Button>
            </>
          ),
        },
      );
    }
  }, [notify, enqueueSnackbar, closeSnackbar]);

  return (
    <>
      <IconButton
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
      >
        <Badge badgeContent={total} color="primary">
          <Iconify icon="eva:bell-fill" width={24} color="grey.800" />
        </Badge>
      </IconButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 380, p: 0 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar
          sx={{
            height: {
              sm: '25vh',
              md: 'auto',
            },
            maxHeight: 350,
          }}
        >
          {isLoading && (
            <>
              <Skeleton
                variant="rectangular"
                height={64}
                sx={{ transform: 'none' }}
              />
              <Skeleton
                variant="rectangular"
                height={64}
                sx={{ transform: 'none', my: 0.15 }}
              />
              <Skeleton
                variant="rectangular"
                height={64}
                sx={{ transform: 'none' }}
              />
            </>
          )}

          <List disablePadding>
            {notifications.map(notification => (
              <ListItemButton
                key={notification.id}
                alignItems="flex-start"
                sx={{
                  py: 1.5,
                  px: 2.5,
                  mt: '1px',
                }}
                onClick={() => handleClickNotification(notification)}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      borderColor: 'primary.main',
                      border: '1px solid',
                      color: 'primary.main',
                      background: 'transparent',
                    }}
                  >
                    <Iconify
                      icon={
                        notification.icone
                          ? Icon[notification.icone]
                          : 'material-symbols:info-outline-rounded'
                      }
                    />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  disableTypography
                  primary={
                    <>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Typography
                          component="div"
                          variant="subtitle2"
                          sx={{
                            color: notification.visualizada
                              ? 'text.secondary'
                              : 'text.primary',
                            fontWeight: notification.visualizada
                              ? '400'
                              : '600',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: unEscape(notification.titulo),
                          }}
                        />
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="caption" color="grey.500">
                            #{notification.id}
                          </Typography>

                          {!notification.visualizada && (
                            <Box
                              sx={{
                                backgroundColor: 'primary.main',
                                height: '10px',
                                width: '10px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <TextMaxLine
                        line={5}
                        component="div"
                        variant="body2"
                        sx={{
                          color: notification.visualizada
                            ? 'text.secondary'
                            : 'text.primary',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: unEscape(notification.descricao),
                        }}
                      />
                    </>
                  }
                  secondary={
                    <Stack
                      direction="row"
                      sx={{
                        mt: 0.5,
                        typography: 'caption',
                        color: 'text.disabled',
                      }}
                    >
                      <Iconify
                        icon="eva:clock-outline"
                        width={16}
                        sx={{ mr: 0.5 }}
                      />
                      <Typography variant="caption">
                        {fDate(
                          notification.dtCriacao || '',
                          `dd/MM/yyyy 'às' HH:mm`,
                        )}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            ))}
          </List>

          {!isLoading && notifications.length === 0 && (
            <EmptyContent
              title=""
              description="Nenhuma notificação para ser apresentada"
              sx={{
                height: 'auto',
                '& > span': {
                  height: 'auto',
                },
                '& img': {
                  height: 140,
                  mb: 0,
                },
              }}
            />
          )}
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Link component={RouterLink} to={NOTIFICATIONS_PATH} underline="none">
            <Button
              fullWidth
              disableRipple
              onClick={handleClosePopover}
              sx={{ textTransform: 'none' }}
            >
              Ver todas
            </Button>
          </Link>
        </Box>
      </MenuPopover>
    </>
  );
};

export default Notifications;
