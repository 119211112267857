import { styled, Stack } from '@mui/material';

interface SearchContainerProps {
  searching: boolean;
}

export const SearchContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'searching',
})<SearchContainerProps>(({ theme, searching }) => ({
  flex: 1,

  [theme.breakpoints.down('sm')]: {
    width: 44,

    ...(!searching && {
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
      },
      input: {
        opacity: '0 !important',
      },
      '& .MuiInputBase-sizeSmall': {
        maxWidth: 44,
      },
    }),

    ...(searching && {
      position: 'absolute',
      top: -1,
      right: -1,
      minWidth: '100%',
      zIndex: 999,
      paddingLeft: 'inherit',
      paddingRight: 'inherit',
    }),
  },
}));
