import { lazy } from 'react';

import loadable from 'core/utils/loadable';
import buildDashRoutes from '../buildDashRoutes';
import navConfig from './nav';

const NotFound = {
  path: '*',
  Element: loadable(lazy(() => import('views/NotFound'))),
};

const paths = buildDashRoutes(navConfig);

export default [...paths, NotFound];
