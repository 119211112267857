import numeral from 'numeral';

type InputValue = string | number | null;

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export const fCurrencyBRL = (value = 0): string =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    value,
  );

export const fPercentBRL = (value = 0): string =>
  new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value);

export const fWithDecimalPlacesOrNot = (value: string | number) => {
  return +Number(value).toFixed(12);
};

export const fDecimalNumber = (value = 0): string =>
  value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

export const fThousandIntegerNumber = (value = 0): string =>
  value.toLocaleString('pt-BR');
