import React from 'react';
import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';

import { ForbiddenIllustration } from 'assets/illustrations';
import { MotionContainer, varBounce } from 'components/animate';

import { RoleBasedGuardProp } from './types';

const RoleBasedGuard: React.FC<RoleBasedGuardProp> = ({
  hasContent = false,
  roles,
  children,
}) => {
  const { user } = { user: { displayName: 'Marcos Vinicius', role: 'admin' } };

  const currentRole = user?.role;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Acesso negado
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Você não tem permissão para acessar essa página
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
