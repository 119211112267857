import React from 'react';

import { ErrorPros } from './props';

const Error: React.FC<ErrorPros> = ({ error, resetError }) => {
  return (
    <div>
      <h1>Error</h1>

      <button type="button" onClick={resetError}>
        Tentar novamente
      </button>

      <br />
      <br />

      <details>{error.message}</details>
    </div>
  );
};

export default Error;
