import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { LicenseInfo } from '@mui/x-license-pro';

import { MUI_PREMIUM_KEY, SENTRY_DSN } from 'core/config';

import App from './App';
import reportWebVitals from './reportWebVitals';

import packageInfo from '../package.json';

const { host } = window.location;
const subdomain = host.split('.')[0];

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
});

Sentry.setTag('namespace', subdomain);
Sentry.setTag('version', packageInfo.version);

LicenseInfo.setLicenseKey(MUI_PREMIUM_KEY);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

reportWebVitals();
