import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { HOST_API, ZDN_INGRESS } from 'core/config';
import { User } from 'core/models/user';
import { Tokens } from 'core/models/auth';
import { ApplicationState } from 'core/redux/store';
import { logout, refreshCredentials } from 'core/redux/slices/auth';
import { decode } from 'core/utils/jwt';

interface ResponseError {
  status?: number;
  originalStatus?: number;
}

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: HOST_API,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as ApplicationState).auth;
    const isDevelopment = process.env.NODE_ENV === 'development';

    headers.set('token', token || '');

    if (isDevelopment) headers.set('X-Zdn-Ingress', ZDN_INGRESS);

    return headers;
  },
  mode: 'cors',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const { status, originalStatus } = (result.error || {}) as ResponseError;

  if (result.error && (status === 401 || originalStatus === 401)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { refreshToken } = (api.getState() as ApplicationState).auth;

        const call = fetchBaseQuery({ baseUrl: HOST_API });

        const refreshResult = await call(
          {
            url: '/oauth/refresh',
            body: `refresh_token=${refreshToken}&grant_type=refresh_token`,
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              ...(process.env.NODE_ENV === 'development' && {
                'X-Zdn-Ingress': ZDN_INGRESS,
              }),
            },
            method: 'POST',
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          const tokens = refreshResult.data as Tokens;

          const { usuario, parceiro, vendedor, sub } = decode<User>(
            tokens.accessToken,
          );

          api.dispatch(
            refreshCredentials({
              ...tokens,
              userData: {
                sub,
                user: usuario,
                partner: parceiro,
                seller: vendedor,
              },
            }),
          );

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } catch (err) {
        api.dispatch(logout());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [] as string[],
});
