import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Button, Stack } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import Iconify from 'components/elements/Iconify';
import Steps from 'components/elements/Steps';
import FormField from 'components/widgets/FormField';
import useActiveElement from 'core/hooks/useActiveElement';
import useCalculatedField from 'core/hooks/useCalculatedField';
import useDeepCompareEffect from 'core/hooks/useDeepCompareEffect';
import { FormField as IFormField } from 'core/models/formField';
import { useSelector } from 'core/redux/store';

import { ActiveElement, FormProps, WrapperProps } from './props';
import { Step } from './styles';
import useForm from './useForm';

const Wrapper: React.FC<PropsWithChildren<WrapperProps>> = ({
  children,
  wrapper: WrapperContainer,
}) => {
  return WrapperContainer ? (
    <WrapperContainer>{children}</WrapperContainer>
  ) : (
    <>{children}</>
  );
};

const Form: React.FC<FormProps> = ({
  id,
  pagedFields,
  conditions,
  type,
  fieldId,
  index,
  size,
  pagesMode,
  containerProps = {
    gap: 1,
  },
  formContainerProps = { direction: 'row', py: 2, gap: 1.5, flexWrap: 'wrap' },
  buttonSize,
  actions,
  formArguments,
  onSubFormLoaded,
  publicForm,
  wrapper,
  applyReset,
  autoSubmit,
  onSubmit,
}) => {
  const {
    STEPS,
    STEP_INDEX,
    activeStep,
    form: { steps, nav },
    goStep,
    handleClickNextStep,
  } = useForm(pagedFields, conditions, fieldId, index, applyReset);

  const { currentRequests } = useSelector(state => state.dynamicFormField);
  const { calculatingConditions } = useCalculatedField(
    id,
    conditions,
    publicForm || false,
  );

  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const triggerSubmit = useRef(autoSubmit || false);
  const { activeElement } = useActiveElement();
  const {
    formState: { isValid },
    trigger,
    clearErrors,
    handleSubmit,
  } = useFormContext();
  const watch = useWatch();

  const activeItemInsideForm =
    (activeElement as ActiveElement)?.form?.id === id.toString();
  const formDisabled =
    calculatingConditions ||
    currentRequests.findIndex(cr => cr.form === id.toString()) !== -1;

  const FORM_HAS_PAGES =
    pagesMode === 'PUBLICO' ||
    pagesMode === 'PADRAO' ||
    pagesMode === 'PRE_PEDIDO';

  const buildId = (inititalId: string) =>
    fieldId !== undefined && index !== undefined
      ? `${fieldId}.${index}.${inititalId}`
      : inititalId;

  const renderActionButton = (lastStep: boolean, stepFields: IFormField[]) => {
    if (lastStep) {
      if (actions) {
        return actions({
          size: 'medium',
          formDisabled,
        });
      }

      return (
        <Button
          key="submitButton"
          type="submit"
          color="primary"
          variant="contained"
          endIcon={<Iconify icon="ic:round-chevron-right" />}
          size={buttonSize}
        >
          Enviar
        </Button>
      );
    }

    return (
      <Button
        key="nextButton"
        color="primary"
        variant="contained"
        endIcon={<Iconify icon="ic:round-chevron-right" />}
        onClick={() => handleClickNextStep(stepFields)}
        size={size}
        disabled={formDisabled}
      >
        Próximo passo
      </Button>
    );
  };

  const fieldWidht = {
    xs: '100%',
    md: 'calc(50% - 6px)',
  };

  useEffect(() => {
    return () => {
      if (delayTimer.current) {
        clearTimeout(delayTimer.current);
      }
    };
  }, []);

  useDeepCompareEffect(() => {
    triggerSubmit.current = true;
  }, [watch]);

  useDeepCompareEffect(() => {
    const executeSubmit = () => {
      if (delayTimer.current) clearTimeout(delayTimer.current);

      if (
        !autoSubmit ||
        !triggerSubmit.current ||
        !isValid ||
        formDisabled ||
        activeItemInsideForm ||
        !onSubmit
      ) {
        return;
      }

      delayTimer.current = setTimeout(async () => {
        const valid = await trigger(undefined, {
          shouldFocus: false,
        });

        if (!valid) clearErrors();
        else handleSubmit(onSubmit)();
      }, 1000);

      triggerSubmit.current = false;
    };

    executeSubmit();
  }, [watch, autoSubmit, isValid, formDisabled, activeItemInsideForm]);

  return (
    <>
      {type === 'FORM' && FORM_HAS_PAGES && STEPS.length > 1 && (
        <Steps steps={STEPS} activeStep={STEP_INDEX} />
      )}

      {steps.map(step => (
        <Step key={step.id} active={step.id === activeStep} {...containerProps}>
          <Wrapper wrapper={wrapper}>
            <Stack {...formContainerProps} direction="row" flexWrap="wrap">
              {step.fields.map(field => (
                <Stack
                  key={field.id}
                  sx={{
                    overflowWrap: 'anywhere',
                    display: field.ocultar ? 'none' : 'block',
                    minWidth: '100%',
                    maxWidth: '100%',

                    ...(field.comprimir && {
                      minWidth: fieldWidht,
                      maxWidth: fieldWidht,
                    }),

                    ...(field.moverNovaLinha && {
                      minWidth: '100%',
                      maxWidth: '100%',
                    }),

                    ...(field.moverNovaLinha &&
                      field.comprimir && {
                        minWidth: '100%',
                        maxWidth: '100%',

                        '> *': {
                          maxWidth: fieldWidht,
                        },
                      }),
                  }}
                >
                  <FormField
                    field={{
                      ...field,
                      id: buildId(field.id),
                      requerido:
                        !field.ocultar && step.id === activeStep
                          ? field.requerido
                          : false,
                    }}
                    size={size}
                    formId={id}
                    formArguments={formArguments}
                    onLoaded={onSubFormLoaded}
                    publicForm={publicForm}
                    hidden={field.ocultar}
                  />
                </Stack>
              ))}
            </Stack>
          </Wrapper>

          {STEPS.length <= 1 && !actions && (
            <Stack justifyContent="flex-end" direction="row">
              <Button
                key="submitButton"
                type="submit"
                color="primary"
                variant="contained"
                size={buttonSize}
                fullWidth
                disabled={formDisabled}
              >
                Salvar
              </Button>
            </Stack>
          )}

          {STEPS.length <= 1 &&
            actions &&
            actions({
              size: 'medium',
              formDisabled,
            })}

          {STEPS.length > 1 && (
            <Stack justifyContent="space-between" direction="row">
              <Button
                color="inherit"
                startIcon={<Iconify icon="ic:round-chevron-left" />}
                disabled={STEP_INDEX === 0}
                onClick={() => goStep(nav[STEP_INDEX - 1])}
                size={buttonSize}
              >
                Voltar
              </Button>

              {renderActionButton(STEP_INDEX + 1 === STEPS.length, step.fields)}
            </Stack>
          )}
        </Step>
      ))}
    </>
  );
};

export default Form;
