import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

import { FieldProps } from './props';

const NumberInput = React.forwardRef<HTMLDivElement, FieldProps>(
  (
    {
      decimalScale = 0,
      fixedDecimalScale = true,
      prefix,
      allowNegative,
      minValue,
      maxValue = Infinity,
      maxLength,
      value,
      inputProps,
      InputProps,
      size,
      disabled,
      name,
      helperText,
      error,
      fullWidth,
      label,
      required,
      onChange,
    },
    ref,
  ) => {
    const delayTimer = useRef<NodeJS.Timeout | null>(null);

    const handleChange = (values: NumberFormatValues) => {
      if (!onChange) return;

      if (delayTimer.current) clearTimeout(delayTimer.current);

      delayTimer.current = setTimeout(() => {
        const { floatValue } = values;
        const comparisonValue = floatValue || 0;

        if (minValue !== undefined && minValue > comparisonValue) {
          onChange({ target: { name, value: minValue } });
          return;
        }

        if (floatValue !== undefined && maxValue < comparisonValue) {
          onChange({ target: { name, value: maxValue } });
          return;
        }

        onChange({
          target: {
            name,
            value: floatValue,
          },
        });
      }, 600);
    };

    useEffect(() => {
      return () => {
        if (delayTimer.current) {
          clearTimeout(delayTimer.current);
        }
      };
    }, []);

    return (
      <NumericFormat
        getInputRef={ref}
        value={value}
        prefix={prefix}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        autoComplete="off"
        allowNegative={allowNegative}
        maxLength={maxLength}
        onValueChange={handleChange}
        customInput={TextField}
        InputProps={{
          ...InputProps,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={inputProps}
        disabled={disabled}
        size={size}
        helperText={helperText}
        error={error}
        fullWidth={fullWidth}
        label={label}
        required={required}
      />
    );
  },
);

export default NumberInput;
