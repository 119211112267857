/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { useGetSearchHistoryQuery } from 'core/services/tablesApi';
import useToggle from 'core/hooks/useToggle';
import useIsMounting from 'core/hooks/useIsMounting';

import QrCodeReader from 'components/products/QrReader/QrReader';
import Iconify from 'components/elements/Iconify';
import useResponsive from 'core/hooks/useResponsive';
import { SearchWithHistoryProps } from './props';
import { StyledPopper } from './styles';

const SearchWithHistory: React.FC<SearchWithHistoryProps> = ({
  tableId,
  value,
  onSearch,
  disabled,
  historicoPesquisa,
  habilitaQrCode,
  searching,
  ...other
}) => {
  const [openCamera, setOpenCamera] = useState(false);
  const isDesktop = useResponsive('up', 'md');
  const [
    modalOpenQRCodeReader,
    toggleModalOpenQRCodeReader,
    setToggleModalOpenQRCodeReader,
  ] = useToggle();
  const isMounting = useIsMounting();
  const [open, toggleOpen] = useToggle();
  const [searchQuery, setSearchQuery] = useState(
    typeof value === 'string' ? value : '',
  );

  const { isLoading, data, refetch, isUninitialized } =
    useGetSearchHistoryQuery(
      { id: tableId },
      {
        refetchOnMountOrArgChange: true,
        skip: disabled || !historicoPesquisa,
      },
    );

  const items = data
    ? [
        data.buscasRecentes.map((item, index) => ({
          key: `recentlyFetched-${index}`,
          group: 'Buscas recentes',
          label: item.termo,
          value: item.termo,
        })),
        data.maisBuscados.map((item, index) => ({
          key: `mostSearched-${index}`,
          group: 'Mais buscados',
          label: item.termo,
          value: item.termo,
        })),
      ].flat()
    : [];

  const handleClick = (label: string) => {
    setSearchQuery(label);
    onSearch(label);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleClick(searchQuery);
  };

  const handleOpenQrReader = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    toggleModalOpenQRCodeReader();
    setOpenCamera(true);
  };

  useEffect(() => {
    if (isMounting || isUninitialized) return;

    setTimeout(() => refetch(), 2500);
  }, [isMounting, isUninitialized, refetch]);

  return (
    <Stack direction="row" spacing={1}>
      <Autocomplete
        open={open && searchQuery === '' && items.length > 0}
        value={searchQuery}
        fullWidth={searching || isDesktop}
        disablePortal
        disableClearable
        popupIcon={null}
        PopperComponent={StyledPopper}
        onInputChange={(_, v) => {
          setSearchQuery(v);

          if (!v) onSearch(v);
        }}
        options={items}
        groupBy={option => option.group}
        disabled={isLoading || disabled}
        freeSolo={searchQuery.length > 0}
        onFocus={toggleOpen}
        onBlur={toggleOpen}
        renderInput={params => (
          <TextField
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ pl: 1.5, pr: 0.5 }}>
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    <>
                      {habilitaQrCode && (
                        <Iconify
                          icon="ic:outline-qrcode"
                          onClick={event =>
                            !disabled && handleOpenQrReader(event)
                          }
                          sx={{ cursor: `${!disabled ? 'pointer' : 'arrow'}` }}
                          color="primary.main"
                        />
                      )}
                    </>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={params.inputProps}
            fullWidth
            onKeyUp={handleKeyUp}
            disabled={isLoading || disabled}
            {...other}
          />
        )}
        renderOption={(props, option) => {
          const { label, key } = option;

          return (
            <Box
              key={key}
              component="li"
              {...props}
              onClick={() => handleClick(label)}
            >
              <Box
                component="span"
                sx={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                }}
              >
                {label}
              </Box>
            </Box>
          );
        }}
      />

      {openCamera && (
        <QrCodeReader
          open={modalOpenQRCodeReader}
          onClose={toggleModalOpenQRCodeReader}
          setToggleModalOpenQRCodeReader={setToggleModalOpenQRCodeReader}
          onSearch={onSearch}
        />
      )}
    </Stack>
  );
};

export default memo(SearchWithHistory);
