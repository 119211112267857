import React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import * as Sentry from '@sentry/react';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import { MotionLazyContainer } from 'components/animate';
import Error from 'components/elements/Error';
import SnackbarProvider from 'components/widgets/snackbar';
import Layout from 'components/wrappers/Layout';
import { persistor, store } from 'core/redux/store';
import Router from 'core/routes';
import ThemeProvider from 'theme';
import ThemeContrast from 'theme/ThemeContrast';

const fallbackComponent = (props: any) => <Error {...props} />;

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={fallbackComponent}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}
            >
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeContrast>
                    <SnackbarProvider>
                      <Layout>
                        <Router />
                      </Layout>
                    </SnackbarProvider>
                  </ThemeContrast>
                </ThemeProvider>
              </MotionLazyContainer>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
