import { styled, Button, IconButton } from '@mui/material';

export const CartButton = styled(Button)(() => ({
  borderRadius: 5,
  justifyContent: 'flex-start',
  px: 3,
}));

export const CartButtonIcon = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey[0],
}));
