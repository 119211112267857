import { PagedFields } from 'core/types/pagedFields';
import { FormField } from 'core/models/formField';
import { FieldType } from 'core/models/formFieldType';
import {
  FieldAction,
  FormConditional,
  FormPage,
} from 'core/models/formWithPagesAndFields';

import { FilterFieldType } from 'core/models/filterFieldType';
import { FormFields } from 'core/types/formFields';
import { FormsPageMode } from 'core/models/formPagesMode';
import { condition } from './conditions';
import { fDate, fDateTime } from './formatTime';
import { objectToArrayObject } from './array';

export function fieldDefaultValue(type: FieldType | FilterFieldType) {
  switch (type) {
    case FieldType.SELECAO_MULTIPLA:
      return [];

    case FieldType.DATA:
    case FieldType.DATA_HORA:
    case FieldType.CONTADOR:
      return null;

    case FieldType.INTERVALO_DATA:
      return { startDate: null, endDate: null };

    case FieldType.SUB_FORM:
      return [];

    case FieldType.SIM_NAO:
      return 'N';

    case FieldType.MENU_SUSPENSO:
    case FieldType.SELECAO_UNICA:
    case FieldType.PESQUISA:
      return null;

    case FieldType.ENDERECO:
      return {
        zipCode: '',
        publicPlace: '',
        number: '',
        district: '',
        complement: '',
        reference: '',
        state: null,
        city: '',
        CONTROL_DATA: null,
      };

    default:
      return '';
  }
}

function fieldValue(field: FormField, formValuesParams?: object) {
  if (field.valorPadrao) {
    const stringValue = field.valorPadrao.toString() || '';

    if (
      stringValue.startsWith('$PARAM[') &&
      stringValue.endsWith(']') &&
      formValuesParams &&
      formValuesParams[stringValue]
    ) {
      return formValuesParams[stringValue];
    }

    if (field.tipo === FieldType.CONTADOR) return +field.valorPadrao;

    return field.valorPadrao;
  }

  return fieldDefaultValue(field.tipo);
}

export function formDefaultValues(
  fields: FormField[],
  formValuesParams?: object,
) {
  const defaultValues = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: fieldValue(field, formValuesParams),
    };
  }, {});

  return defaultValues;
}

export function formatFormValue(field: FormField, value: any) {
  return formatValue(field.tipo, value);
}

export function formatValue(tipo: FieldType | FilterFieldType, value: any) {
  switch (tipo) {
    case FieldType.DATA:
      return fDate(value, 'dd/MM/yyyy');

    case FieldType.DATA_HORA:
      return fDateTime(value, 'dd/MM/yyyy HH:mm:ss');

    case FieldType.HORA:
      return fDateTime(value, 'HH:mm');

    case FieldType.INTERVALO_DATA:
      return {
        dtIni: fDateTime(value.startDate, 'dd/MM/yyyy'),
        dtFim: fDateTime(value.endDate, 'dd/MM/yyyy'),
      };

    case FieldType.ENDERECO:
      return typeof value === 'object' ? value.zipCode : null;

    default:
      return value;
  }
}

export const formattedFormValues = (
  formValues: Record<string | number, unknown>,
  formFields: FormFields,
) => {
  const formattedValues = formFields.fields.reduce((acc, field) => {
    if (field.tipo === 'SUB_FORMULARIO') {
      const subFormFields = formFields.subFormFields
        ? formFields.subFormFields[field.id]
        : undefined;

      if (subFormFields) {
        const subFormValues = formValues[field.id] as
          | Record<string | number, unknown>[]
          | undefined;

        if (subFormValues) {
          const subFormValuesFormatted = subFormValues.map(subFormValue =>
            formattedFormValues(subFormValue, { fields: subFormFields }),
          );

          return {
            ...acc,
            [field.id]: subFormValuesFormatted,
          };
        }

        return {
          ...acc,
          [field.id]: [],
        };
      }
    }

    return {
      ...acc,
      [field.id]: formatFormValue(field, formValues[field.id]),
    };
  }, {});

  return formattedValues;
};

export const formattedFormValuesToSave = (
  formValues: Record<string | number, unknown>,
  formFields: FormFields,
) => {
  const formattedValues = formFields.fields.reduce((acc: object[], field) => {
    if (field.tipo === 'SUB_FORMULARIO') {
      const subFormFields = formFields.subFormFields
        ? formFields.subFormFields[field.id]
        : undefined;

      if (subFormFields) {
        const subFormValues = formValues[field.id] as
          | Record<string | number, unknown>[]
          | undefined;

        if (subFormValues) {
          const subFormValuesFormatted = subFormValues.map(subFormValue =>
            formattedFormValues(subFormValue, { fields: subFormFields }),
          );

          return [
            ...acc,
            {
              campo: field.id,
              valores: subFormValuesFormatted.map((v, i) => ({
                indice: i + 1,
                dados: objectToArrayObject(v, 'campo', 'valor'),
              })),
            },
          ];
        }

        return [
          ...acc,
          {
            campo: field.id,
            valores: [],
          },
        ];
      }
    }

    return [
      ...acc,
      {
        campo: field.id,
        valor: formatFormValue(field, formValues[field.id]),
        controle: (formValues[field.id] as any)?.CONTROL_DATA,
      },
    ];
  }, []);

  return formattedValues;
};

export function buildPagedFields(
  fields: FormField[],
  pages: FormPage[],
  pagesMode?: FormsPageMode,
): PagedFields[] {
  if (!pagesMode) return [];

  if (
    (pagesMode === 'PADRAO' ||
      pagesMode === 'PUBLICO' ||
      pagesMode === 'PRE_PEDIDO') &&
    pages.length > 0
  ) {
    return pages.reduce((acc: PagedFields[], page) => {
      const fieldsOfPage = fields.filter(f => f.pagina === page.id);

      return [...acc, { ...page, fields: fieldsOfPage }];
    }, []);
  }

  return [
    {
      id: '-9999',
      ordem: 1,
      titulo: 'DEFATUL',
      validacao: false,
      fields,
    },
  ];
}

function addFieldPropsBasedConditions(
  field: FormField,
  conditionallyDisplayedFields: string[],
  visibleFields: string[],
  hiddenFields: string[],
  requiredFields: string[],
  nonRequiredFields: string[],
  activeFields: string[],
  disabledFields: string[],
  changeTitleFields: FieldAction[],
) {
  const fieldProps = {
    /* ...(conditionallyDisplayedFields.includes(field.id) && {
      ocultar: true,
    }), */

    ...(visibleFields.includes(field.id) && {
      ocultar: false,
    }),

    ...(hiddenFields.includes(field.id) && {
      ocultar: true,
      rules: undefined,
    }),

    ...(requiredFields.includes(field.id) && {
      requerido: true,
    }),

    ...(nonRequiredFields.includes(field.id) && {
      requerido: false,
    }),

    ...(activeFields.includes(field.id) && {
      somenteLeitura: false,
    }),

    ...(disabledFields.includes(field.id) && {
      somenteLeitura: true,
    }),

    ...(changeTitleFields.map(titleId => titleId.id).includes(field.id) && {
      titulo: changeTitleFields.find(title => title.id === field.id)?.titulo,
    }),
  };

  return { ...field, ...fieldProps };
}

export function getSceneryValue(
  conditionField: string,
  values?: Record<string, any>,
  fieldId?: string,
  fieldIndex?: number,
) {
  if (!values) return undefined;

  if (fieldId !== undefined && fieldIndex !== undefined) {
    const arrayValues = values[fieldId];
    const arrayValue = arrayValues[fieldIndex];

    if (!arrayValue) return undefined;

    return arrayValue[conditionField];
  }

  return values[conditionField];
}

function dynamicFields(
  fields: FormField[],
  conditions: FormConditional[],
  values?: Record<string, any>,
  fieldId?: string,
  fieldIndex?: number,
) {
  const conditionalFields = conditions.filter(
    c => c.tipo === 'EXIBIR_OCULTAR_CAMPO',
  );

  const actions = conditionalFields.map(c => c.acoes).flat();
  const conditionallyDisplayedFields = actions.map(a => a.campos || []).flat();

  const visibleFields: string[] = [];
  const hiddenFields: string[] = [];

  const requiredFields: string[] = [];
  const nonRequiredFields: string[] = [];

  const activeFields: string[] = [];
  const disabledFields: string[] = [];
  const changeTitle: FieldAction[] = [];

  conditions.forEach(c => {
    const conditionsResults = c.cenarios.map(scenery => {
      return condition(
        getSceneryValue(scenery.se, values, fieldId, fieldIndex),
        scenery.estado,
        scenery.valor,
      );
    });

    const executeActions =
      c.regra === 'TODAS'
        ? conditionsResults.every(cr => cr)
        : conditionsResults.some(cr => cr);

    if (executeActions) {
      c.acoes.forEach(a => {
        if (a.campos) {
          const fieldsId = a.campos.map(field => field.id);
          if (a.fazer === 'EXIBIR_CAMPO') {
            visibleFields.push(...fieldsId);
          }

          if (a.fazer === 'OCULTAR_CAMPO') {
            hiddenFields.push(...fieldsId);
          }

          if (a.fazer === 'ATIVAR') {
            activeFields.push(...fieldsId);
          }

          if (a.fazer === 'DESATIVAR') {
            disabledFields.push(...fieldsId);
          }

          if (a.fazer === 'EXIGIR') {
            requiredFields.push(...fieldsId);
          }

          if (a.fazer === 'NAO_EXIGIR') {
            nonRequiredFields.push(...fieldsId);
          }

          if (a.fazer === 'ALTERAR_TITULO') {
            changeTitle.push(...a.campos);
          }
        }
      });
    }
  });

  return fields.reduce((accFields: FormField[], currField) => {
    return [
      ...accFields,
      addFieldPropsBasedConditions(
        currField,
        conditionallyDisplayedFields.map(field => field.id),
        visibleFields,
        hiddenFields,
        requiredFields,
        nonRequiredFields,
        activeFields,
        disabledFields,
        changeTitle,
      ),
    ];
  }, []);
}

function dynamicNavigation(
  conditions: FormConditional[],
  values?: Record<string, any>,
) {
  const pagesConditions = conditions.filter(c => {
    const conditionsResults = c.cenarios.map(scenery =>
      condition(
        values ? values[scenery.se] : undefined,
        scenery.estado,
        scenery.valor,
      ),
    );

    const conditionsValid =
      c.regra === 'TODAS'
        ? conditionsResults.every(cr => cr)
        : conditionsResults.some(cr => cr);

    return (
      c.tipo === 'PULAR_OCULTAR_PAGINA' &&
      c.acoes.some(a => a.fazer === 'PULAR_PAGINA') &&
      conditionsValid
    );
  });

  const pages = pagesConditions
    .map(c =>
      c.acoes.filter(a => a.fazer === 'PULAR_PAGINA').map(a => a.paginas || []),
    )
    .flat(3);

  return pages;
}

export function dynamicForm(
  form: PagedFields[],
  conditions: FormConditional[],
  values?: Record<string, any>,
  fieldId?: string,
  fieldIndex?: number,
) {
  const conditionalPages = conditions.filter(
    c => c.tipo === 'PULAR_OCULTAR_PAGINA',
  );

  if (conditionalPages.length === 0) {
    const formattedForm = form.reduce((acc: PagedFields[], curr) => {
      const fields = dynamicFields(
        curr.fields,
        conditions,
        values,
        fieldId,
        fieldIndex,
      );

      return [...acc, { ...curr, fields }];
    }, []);

    return { steps: formattedForm, nav: form.map(f => f.id) };
  }

  const actions = conditionalPages.map(c => c.acoes).flat();
  const conditionallyDisplayedPages = actions.map(a => a.paginas || []).flat();

  const visiblePages: string[] = [];
  const hiddenPages: string[] = [];

  conditionalPages.forEach(c => {
    const conditionsResults = c.cenarios.map(scenery =>
      condition(
        values ? values[scenery.se] : undefined,
        scenery.estado,
        scenery.valor,
      ),
    );

    const executeActions =
      c.regra === 'TODAS'
        ? conditionsResults.every(cr => cr)
        : conditionsResults.some(cr => cr);

    if (executeActions) {
      c.acoes.forEach(a => {
        if (a.fazer === 'PULAR_PAGINA' && a.paginas) {
          visiblePages.push(...a.paginas);
        }

        if (a.fazer === 'OCULTAR_PAGINA' && a.paginas) {
          hiddenPages.push(...a.paginas);
        }
      });
    }
  });

  const formattedForm = form.reduce((acc: PagedFields[], curr) => {
    const page = {
      ...curr,
      visible: !conditionallyDisplayedPages.includes(curr.id),
    };

    const pageProps = {
      ...(visiblePages.includes(curr.id) && {
        visible: true,
      }),
      ...(hiddenPages.includes(curr.id) && {
        visible: false,
      }),
    };

    const fields = dynamicFields(
      curr.fields,
      conditions,
      values,
      fieldId,
      fieldIndex,
    );

    return [...acc, { ...page, ...pageProps, fields }];
  }, []);

  const nav = dynamicNavigation(conditions, values);
  const firsPage = formattedForm.find(f => f.visible)?.id || '';

  return { steps: formattedForm, nav: [firsPage, ...nav] };
}
