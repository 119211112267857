import React from 'react';
import {
  AppBar,
  CircularProgress,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ClientLogo from 'components/elements/ClientLogo';
import Iconify from 'components/elements/Iconify';
import Cart from 'components/products/Cart';
import Search from 'components/products/Search';
import { HEADER, NAV } from 'core/config';
import useOffSetTop from 'core/hooks/useOffSetTop';
import useOrder from 'core/hooks/useOrder';
import useRedirection from 'core/hooks/useRedirection';
import useResponsive from 'core/hooks/useResponsive';
import { useSelector } from 'core/redux/store';
import { useGetMyDataQuery } from 'core/services/myDataApi';

import AccountPopover from './AccountPopover';
import Notifications from './Notifications';
import OrderButtons from './OrderButtons';
import PaymentTypeSelection from './PaymentTypeSelection';

import { Props } from './props';

const Header: React.FC<Props> = ({ onOpenNav, forceNavMini }) => {
  const theme = useTheme();
  const { themeDirection, isNavMini } = useSelector(state => state.layout);
  const { pathToRedirect } = useRedirection(false, false);
  const navMini = forceNavMini || isNavMini;

  const myData = useSelector(state => state.myData);

  const { isLoading } = useGetMyDataQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isNavHorizontal = themeDirection === 'horizontal';
  const { orderConfigId } = useOrder();

  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && (
        <ClientLogo
          link={pathToRedirect}
          sx={{
            mr: 3,
            '& svg, & img': {
              width: '100%',
              height: 'auto',
              maxHeight: 32,
            },
          }}
        />
      )}

      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        width={1}
        gap={{
          xs: 1.5,
          md: 2,
        }}
      >
        {!isDesktop && (
          <IconButton
            onClick={onOpenNav}
            sx={{ mr: 1, minWidth: 44, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        {orderConfigId && (
          <>
            <Search />
            <PaymentTypeSelection />
          </>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={{
            xs: 0.5,
            md: 2,
          }}
          ml="auto"
        >
          {orderConfigId ? <Cart /> : <OrderButtons />}

          <Notifications receiveNotification={myData.recebeNotificacao} />

          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <AccountPopover data={myData} />
          )}
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'grey.0',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(navMini &&
            !isNavHorizontal && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 3 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
