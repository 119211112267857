import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'core/redux/store';
import {
  HOME_PAGE,
  NEW_ORDER_PATH,
  SCREEN_PATH,
} from 'core/routes/@portal/dashboardRoutes';
import { newOrderApi } from 'core/services/newOrderApi';
import { useGetMenuItemsQuery } from 'core/services/userMenuItemsApi';
import useDeepCompareEffect from './useDeepCompareEffect';
import useIsMounting from './useIsMounting';
import useOrder from './useOrder';

const useRedirection = (redirect = false, refetchMenus?: boolean) => {
  const [pathToRedirect, setPathToRedirect] = useState<string>();

  const isMouting = useIsMounting();
  const { menuItems } = useSelector(state => state.menuItems);
  const { orderConfigId } = useOrder();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isLoading, isUninitialized, isFetching } = useGetMenuItemsQuery(
    undefined,
    {
      refetchOnMountOrArgChange:
        refetchMenus !== undefined ? refetchMenus : true,
    },
  );
  const {
    isLoading: newOrderOptionsLoading,
    isFetching: newOrderOptionsIsFetching,
    data: newOrderOptionsData,
  } = newOrderApi.endpoints.getOptionsNewOrder.useQueryState();

  const loading = isLoading || isFetching || isUninitialized;

  const firstMenu = menuItems?.slice().sort((a, b) => a.ordem - b.ordem)[0]?.id;
  const ordersMenuItem = menuItems?.find(
    m =>
      m.tipoLancador === 'VENDEDOR_PEDIDOS' ||
      m.tipoLancador === 'PARCEIRO_MEUS_PEDIDOS',
  );

  useDeepCompareEffect(() => {
    if (isMouting) return;

    if (pathname === SCREEN_PATH || pathname === `${SCREEN_PATH}/`) {
      if (!firstMenu) return;

      if (redirect) navigate(`${SCREEN_PATH}/${firstMenu}`);

      return;
    }

    if (
      pathname === HOME_PAGE ||
      pathname === `${HOME_PAGE}/` ||
      pathname === NEW_ORDER_PATH ||
      pathname === `${NEW_ORDER_PATH}/`
    ) {
      if (redirect) {
        if (newOrderOptionsLoading || newOrderOptionsIsFetching) return;

        const newOrderOptions = newOrderOptionsData?.opcoes || [];

        if (newOrderOptions.length > 0 || orderConfigId) {
          if (newOrderOptions.length === 1) {
            navigate(NEW_ORDER_PATH, {
              state: { orderConfigId: newOrderOptions[0].id },
            });
          } else {
            navigate(NEW_ORDER_PATH, {
              state: { orderConfigId },
            });
          }
        } else {
          if (!firstMenu) return;

          navigate(`${SCREEN_PATH}/${firstMenu}`);
        }
      }
    }

    const newOrderOptions = newOrderOptionsData?.opcoes || [];

    if (newOrderOptions.length > 0 || orderConfigId) {
      if (newOrderOptions.length === 1) {
        setPathToRedirect(NEW_ORDER_PATH);
      } else {
        setPathToRedirect(NEW_ORDER_PATH);
      }
    } else {
      if (
        !newOrderOptionsLoading &&
        !newOrderOptionsIsFetching &&
        newOrderOptionsData?.opcoes.length === 0 &&
        menuItems.length === 0
      ) {
        navigate(SCREEN_PATH);
      }

      setPathToRedirect(`${SCREEN_PATH}/${firstMenu}`);
    }
  }, [
    isMouting,
    firstMenu,
    orderConfigId,
    newOrderOptionsData,
    newOrderOptionsIsFetching,
    newOrderOptionsLoading,
    pathname,
  ]);

  return { loading, firstMenu, ordersMenuItem, pathToRedirect };
};

export default useRedirection;
