import React from 'react';
import { Stack, Typography } from '@mui/material';

import Iconify from 'components/elements/Iconify';
import useOrder from 'core/hooks/useOrder';
import useResponsive from 'core/hooks/useResponsive';
import useToggle from 'core/hooks/useToggle';
import PaymentSelectionModal from 'components/widgets/PaymentSelectionModal';
import useActiveLink from 'core/hooks/useActiveLink';
import { NEW_ORDER_PATH } from 'core/routes/@portal/dashboardRoutes';
import { Button, PaymentButtonMobile } from './styles';

const PaymentTypeSelection: React.FC = () => {
  const { active: newOrderV2 } = useActiveLink(NEW_ORDER_PATH);
  const isMobile = useResponsive('down', 'md');
  const [
    modalSelectionOpen,
    toggleModalSelectionOpen,
    setToggleModalSelectionOpen,
  ] = useToggle();
  const { selectedPaymentMethod } = useOrder();

  if (!newOrderV2) return null;

  return (
    <>
      {isMobile ? (
        <PaymentButtonMobile onClick={toggleModalSelectionOpen}>
          <Iconify
            icon="heroicons:currency-dollar"
            color="inherit"
            width={24}
          />
        </PaymentButtonMobile>
      ) : (
        <Button
          variant="outlined"
          color="inherit"
          onClick={toggleModalSelectionOpen}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={{
              xs: 0,
              md: 1.25,
            }}
          >
            <Iconify
              icon="heroicons:currency-dollar"
              color="primary.main"
              width={24}
            />
            <Stack textAlign="left" maxWidth={160}>
              <Typography
                lineHeight={1.25}
                color="text.disabled"
                fontSize={12}
                noWrap
              >
                Forma de pagamento
              </Typography>
              <Typography
                lineHeight={1.25}
                color={
                  selectedPaymentMethod ? 'primary.main' : 'text.secondary'
                }
                fontSize={12}
                fontWeight={700}
                noWrap
              >
                {selectedPaymentMethod
                  ? selectedPaymentMethod.descricao
                  : 'Clique para selecionar'}
              </Typography>
            </Stack>
          </Stack>
        </Button>
      )}

      <PaymentSelectionModal
        open={modalSelectionOpen}
        onClose={toggleModalSelectionOpen}
        onSelect={() => setToggleModalSelectionOpen(false)}
      />
    </>
  );
};

export default PaymentTypeSelection;
