import React from 'react';
import { Dialog, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import Iconify from 'components/elements/Iconify';
import Form from 'components/hook-form/Form';
import PasswordField from 'components/hook-form/PasswordField';
import LogoB2B from 'components/elements/LogoB2B';
import { useAuth } from 'core/hooks/useAuth';
import { NewPassword } from 'core/models/myData';
import { setNeedToChangePassword } from 'core/redux/slices/auth';
import { dispatch } from 'core/redux/store';
import { useChangeAccessPasswordMutation } from 'core/services/myDataApi';
import PasswordRules from 'components/elements/PasswordRules';

import { PasswordModalProps } from './props';

const PasswordModal: React.FC<PasswordModalProps> = ({ open }) => {
  const {
    auth: {
      user: {
        user: { nome },
      },
    },
  } = useAuth();

  const methods = useForm<NewPassword>({
    defaultValues: {
      password: '',
      newPassword: '',
    },
  });
  const { handleSubmit, watch } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const [changePassword, { isLoading }] = useChangeAccessPasswordMutation();

  const onSubmit = async ({ password }: NewPassword) => {
    try {
      await changePassword(password).unwrap();
      enqueueSnackbar('Senha atualizada');

      dispatch(setNeedToChangePassword(false));
    } catch (err: any) {
      enqueueSnackbar(err?.data?.message || 'Erro ao realizar solicitação', {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'grey.100',
          maxWidth: '100%',
          maxHeight: '100%',
          margin: '0px !important',
          paddingY: '90px',
        },
      }}
    >
      <Stack alignItems="center" height={1} sx={{}}>
        <Stack
          sx={({ customShadows }) => ({
            width: '90%',
            maxWidth: 450,
            mx: 'auto',
            backgroundColor: 'grey.0',
            boxShadow: customShadows.card,
            borderRadius: 1,
            py: 6,
            px: 4,
          })}
        >
          <Iconify icon="ic:outline-lock" width={48} color="primary.main" />

          <Typography variant="h6" mt={2}>
            Olá {nome}
          </Typography>
          <Typography variant="body2" color="grey.600">
            Antes de prosseguir você precisa redefinir sua senha.
          </Typography>

          <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack mt={3} gap={2}>
              <PasswordField
                name="password"
                label="Nova senha"
                passwordSize={[8, 20]}
                numberChar
                specialCharacter
                upperCaseChar
                autoComplete="new-password"
              />
              <PasswordField
                name="confirmPassword"
                label="Confirme a nova senha"
                passwordSize={[8, 20]}
                rules={{
                  validate: (val: string) => {
                    if (watch('password') !== val)
                      return 'As senhas devem ser iguais';

                    return true;
                  },
                }}
              />
              <PasswordRules
                password={watch('password')}
                passwordSize={[8, 20]}
                numberChar
                specialCharacter
                upperCaseChar
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                startIcon={<Iconify icon="ic:outline-save" />}
                loading={isLoading}
              >
                Salvar
              </LoadingButton>
            </Stack>
          </Form>

          <Stack
            spacing={1}
            alignItems="center"
            mt={6}
            sx={{ color: 'grey.500' }}
          >
            <LogoB2B />
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PasswordModal;
