import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State, UpdateOrderData, UpdateOrderStoredValues } from './types';

const initialState: State = {};

const slice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    updateOrderData: (
      state,
      {
        payload: { orderIdentifier, key, value },
      }: PayloadAction<UpdateOrderData>,
    ) => {
      return {
        ...state,
        [orderIdentifier]: {
          ...state[orderIdentifier],
          [key]: value,
        },
      };
    },
    updateOrderStoredValues: (
      state,
      {
        payload: { orderIdentifier, key, value },
      }: PayloadAction<UpdateOrderStoredValues>,
    ) => {
      return {
        ...state,
        [orderIdentifier]: {
          ...state[orderIdentifier],
          storedValues: {
            ...state[orderIdentifier].storedValues,
            [key]: value,
          },
        },
      };
    },
    resetOrder: (state, { payload }: PayloadAction<string>) =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => key !== payload),
      ),
    resetState: () => initialState,
  },
});

export const {
  resetState,
  resetOrder,
  updateOrderData,
  updateOrderStoredValues,
} = slice.actions;

export default slice.reducer;
