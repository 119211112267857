import React, { useMemo, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import FormProvider from 'components/hook-form/Form';
import useArguments from 'core/hooks/useArguments';
import useIsMounting from 'core/hooks/useIsMounting';
import { useCreateRegistroMutation } from 'core/services/formsApi';
import { FormFields } from 'core/types/formFields';
import {
  formattedFormValues,
  formattedFormValuesToSave,
  formDefaultValues,
} from 'core/utils/form';
import useDeepCompareEffect from 'core/hooks/useDeepCompareEffect';
import Form from '../Form';

import { BuilderProps } from './props';

const Builder: React.FC<BuilderProps> = ({
  id,
  pagedFields,
  conditions,
  size,
  buttonSize,
  pagesMode,
  containerProps,
  formContainerProps,
  actions,
  defaultValues,
  formValuesParams,
  onSubmit,
  dataFormattingType,
  navigateToAfterSubmit,
  formArguments,
  contextOfArguments,
  publicForm,
  wrapper,
  applyReset,
  autoSubmit,
  changeDirty,
}) => {
  const isMounting = useIsMounting();
  const navigate = useNavigate();

  const { addArgumentValues } = useArguments(contextOfArguments);

  const formFields = useRef<FormFields>({
    fields: pagedFields.map(p => p.fields).flat(),
  });

  const fields = pagedFields.map(p => p.fields).flat();
  const { enqueueSnackbar } = useSnackbar();

  const defaultFieldValues = useMemo(
    () => formDefaultValues(fields, formValuesParams),
    [fields, formValuesParams],
  );

  const methods = useForm({
    defaultValues: useMemo(
      () => defaultValues || defaultFieldValues,
      [defaultValues, defaultFieldValues],
    ),
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = methods;

  const valuesChanged = watch();

  useDeepCompareEffect(() => {
    if (changeDirty && isDirty) changeDirty(isDirty);
  }, [valuesChanged]);

  const [createRegistro] = useCreateRegistroMutation();

  const handleSubFormFieldsLoaded = (formId: string, subFormFields) => {
    formFields.current = {
      ...formFields.current,
      subFormFields: {
        ...formFields.current.subFormFields,
        [formId]: subFormFields.map(p => p.fields).flat(),
      },
    };
  };

  const manageSubmit = async (values: Record<string | number, unknown>) => {
    const formattedValues = formattedFormValues(values, formFields.current);
    const formattedForSave = formattedFormValuesToSave(
      values,
      formFields.current,
    );

    const argumentValues = formArguments?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.argumento]: formattedValues[curr.campoID],
      }),
      {},
    );

    if (onSubmit) {
      onSubmit(
        dataFormattingType === 'FORMAT_FOR_SAVE'
          ? formattedForSave
          : formattedValues,
        values,
      );
    }

    if (contextOfArguments)
      addArgumentValues(contextOfArguments, argumentValues);

    if (!onSubmit) {
      try {
        const formRequest = {
          formulario: id,
          dados: formattedForSave,
          publico: publicForm,
          name: publicForm ? 'FormularioPublico' : 'Formulario',
          method: 'createRegistro',
        };

        const { id: recordId } = await createRegistro(formRequest).unwrap();

        if (navigateToAfterSubmit) {
          navigate(navigateToAfterSubmit, {
            state: {
              recordId,
            },
          });
        } else {
          enqueueSnackbar('Registro criado com sucesso');
        }
      } catch (err) {
        enqueueSnackbar('Falha ao criar registro', { variant: 'error' });
      }
    }
  };

  useDeepCompareEffect(() => {
    if (isMounting) return;

    reset(defaultValues || defaultFieldValues);
  }, [reset, defaultValues]);

  return (
    <FormProvider
      id={id}
      methods={methods}
      onSubmit={handleSubmit(manageSubmit)}
    >
      <Form
        id={id}
        pagedFields={pagedFields}
        conditions={conditions}
        type="FORM"
        size={size}
        buttonSize={buttonSize}
        pagesMode={pagesMode}
        containerProps={containerProps}
        formContainerProps={formContainerProps}
        actions={actions}
        formArguments={formArguments}
        onSubFormLoaded={handleSubFormFieldsLoaded}
        publicForm={publicForm}
        wrapper={wrapper}
        applyReset={applyReset}
        autoSubmit={autoSubmit}
        onSubmit={manageSubmit}
      />
    </FormProvider>
  );
};

export default Builder;
