import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'core/hooks/useAuth';
import { HOME_PAGE } from 'core/routes/@portal/dashboardRoutes';

import { GuestGuardProps } from './types';

const GuestGuard: React.FC<GuestGuardProps> = ({ children }) => {
  const { pathname } = useLocation();
  const {
    auth: { token },
    userType,
  } = useAuth();

  if (token)
    return (
      <Navigate
        to={HOME_PAGE}
        state={{
          partnerCameFromLogin: userType === 'PARCEIRO' && pathname === '/',
        }}
      />
    );

  return <>{children}</>;
};

export default GuestGuard;
