import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArgumentsState } from './types';

const initialState: ArgumentsState = {
  arguments: {},
};

interface Payload {
  context: string;
  value: object | undefined;
}

const slice = createSlice({
  name: 'arguments',
  initialState,
  reducers: {
    add: (state, { payload: { context, value } }: PayloadAction<Payload>) => {
      return {
        ...state,
        arguments: {
          ...state.arguments,
          [context]: {
            ...state.arguments[context],
            ...value,
          },
        },
      };
    },
    removeContext: (state, { payload }: PayloadAction<string>) => {
      delete state.arguments[payload];

      return state;
    },
    reset: () => initialState,
  },
});

export const { add, removeContext, reset } = slice.actions;

export default slice.reducer;
