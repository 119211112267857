import React, { memo } from 'react';
import { AppBar, Box, BoxProps, Toolbar } from '@mui/material';

import { HEADER } from 'core/config';
import { NavSectionHorizontal } from 'components/nav-section';

import { Props } from './props';

const Shadow: React.FC<BoxProps> = ({ sx, ...other }) => {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: theme => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
};

const NavHorizontal: React.FC<Props> = ({ navConfig }) => {
  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: 'grey.0',
        }}
      >
        <NavSectionHorizontal data={navConfig} />
      </Toolbar>

      <Shadow />
    </AppBar>
  );
};

export default memo(NavHorizontal);
