import React from 'react';

const PortalUnavailable: React.FC = () => {
  return (
    <svg
      width="287"
      height="261"
      viewBox="0 0 287 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.533 260.195C77.232 260.195 17 256.603 17 252.173C17 247.744 77.232 244.152 151.533 244.152C225.835 244.152 286.067 247.744 286.067 252.173C286.067 256.603 225.835 260.195 151.533 260.195Z"
        fill="#989898"
      />
      <path
        d="M20.3037 177.829V205.359C20.3037 207.993 22.457 210.141 25.089 210.141H277.978C280.614 210.141 282.764 207.993 282.764 205.359V177.829H20.3037Z"
        fill="#CFCFCF"
      />
      <path
        d="M282.764 25.7854C282.764 23.152 280.615 21 277.979 21H25.0893C22.456 21 20.3027 23.152 20.3027 25.7854V177.829H282.764L282.764 25.7854Z"
        fill="#989898"
      />
      <path
        d="M197.226 237.856H106.95V210.14H197.226V237.856Z"
        fill="#D8D7D5"
      />
      <path
        d="M217.68 250.833H85.2568V237.859H217.68V250.833Z"
        fill="#CFCFCF"
      />
      <path
        d="M197.226 220.157H106.95V210.14H197.226V220.157Z"
        fill="#BCBBB9"
      />
      <path d="M33.872 34.652H269.247V163.804H33.872V34.652Z" fill="white" />
      <ellipse
        cx="112.08"
        cy="94.1185"
        rx="5.56321"
        ry="7.64941"
        fill="#CFCFCF"
      />
      <ellipse
        cx="105.822"
        cy="109.331"
        rx="11.8218"
        ry="4.1724"
        fill="#EFEFEF"
        fillOpacity="0.59"
      />
      <ellipse
        cx="197.614"
        cy="109.331"
        rx="11.8218"
        ry="4.1724"
        fill="#EFEFEF"
        fillOpacity="0.59"
      />
      <ellipse
        cx="189.965"
        cy="94.1185"
        rx="5.56321"
        ry="7.64941"
        fill="#CFCFCF"
      />
      <path
        d="M139.896 109.417C145.459 120.544 157.281 118.458 162.148 109.417"
        stroke="#CFCFCF"
        strokeWidth="2.7816"
        strokeLinecap="round"
      />
      <path
        d="M185.792 73.9519C192.051 78.8197 192.746 79.5151 200.395 78.8197"
        stroke="#CFCFCF"
        strokeWidth="2.7816"
        strokeLinecap="round"
      />
      <path
        d="M116.947 73.9519C110.689 78.8197 109.993 79.5151 102.344 78.8197"
        stroke="#CFCFCF"
        strokeWidth="2.7816"
        strokeLinecap="round"
      />
      <path
        d="M13 28H1"
        stroke="#989898"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.4854 18.4854L8.00007 10.0001"
        stroke="#989898"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25 13L25 1"
        stroke="#989898"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PortalUnavailable;
