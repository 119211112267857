import React, { useState } from 'react';
import { Button, Link, MenuItem } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Iconify from 'components/elements/Iconify';
import MenuPopover from 'components/elements/MenuPopover';
import { useGetOptionsNewOrderQuery } from 'core/services/newOrderApi';
import { NEW_ORDER_PATH } from 'core/routes/@portal/dashboardRoutes';

const OrderButtons: React.FC = () => {
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const { data, isLoading } = useGetOptionsNewOrderQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const options = data?.opcoes || [];

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickOrderButton = () => {
    if (!options[0]?.id) return;

    navigate(NEW_ORDER_PATH, {
      state: {
        orderConfigId: options[0].id,
      },
    });
  };

  return (
    <>
      {options.length === 1 && (
        <Button
          variant="outlined"
          disabled={isLoading}
          startIcon={<Iconify icon="ic:outline-shopping-cart" />}
          onClick={handleClickOrderButton}
        >
          Novo pedido
        </Button>
      )}

      {options.length > 1 && (
        <>
          <Button
            variant="outlined"
            disabled={isLoading}
            startIcon={<Iconify icon="ic:outline-shopping-cart" />}
            endIcon={
              <Iconify
                icon="ic:round-arrow-drop-down"
                width={24}
                color="primary.main"
              />
            }
            onClick={handleOpenPopover}
          >
            Novo pedido
          </Button>

          <MenuPopover open={openPopover} onClose={handleClosePopover}>
            {options.map(opcao => (
              <Link
                key={opcao.id}
                component={RouterLink}
                to={NEW_ORDER_PATH}
                state={{
                  orderConfigId: opcao.id,
                }}
                underline="none"
              >
                <MenuItem sx={{ m: 1, color: 'grey.600' }}>
                  {opcao.descricao}
                </MenuItem>
              </Link>
            ))}
          </MenuPopover>
        </>
      )}
    </>
  );
};

export default OrderButtons;
