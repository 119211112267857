import { Data } from 'core/models/cart';
import { RequestArguments } from 'core/models/requestArguments';

export const objectToArrayObject = <T>(
  values = {},
  keyName: keyof T = 'key' as any,
  valueName = 'value',
) =>
  Object.entries(values).map(([key, value]) => ({
    [keyName]: key,
    [valueName]: value,
  }));

export const arrayObjectToObject = (values: Data[] = []) =>
  values.reduce((acc, value) => {
    acc[value.campoID] = value.valor;
    return acc;
  }, {});

export const arrayArgumentsToObject = (values: RequestArguments[] = []) =>
  values.reduce((acc, value) => {
    acc[value.chave] = value.valor;
    return acc;
  }, {});
