import React, { useMemo } from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useSelector } from 'core/redux/store';
import { getImageByType } from 'core/utils/image';
import Image from '../Image';
import Logo from '../Logo';
import { ClientLogoProps } from './props';

const ClientLogo: React.FC<ClientLogoProps> = ({ link, ...other }) => {
  const data = useSelector(state => state.layout.data);
  const logoImage = useMemo(
    () => getImageByType(data?.tipoImagemMarca, data?.imagemMarca),
    [data],
  );

  if (logoImage) {
    if (!link) return <Image src={logoImage} {...other} disabledEffect />;

    return (
      <Link to={link} component={RouterLink}>
        <Image src={logoImage} {...other} disabledEffect />
      </Link>
    );
  }

  return <Logo link={link} {...other} />;
};

export default ClientLogo;
