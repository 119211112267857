import { Notification } from 'core/models/notification';

import api from './base/api';

interface BellNotifications {
  items: Notification[];
  total: number;
  recebeNotificacao?: boolean;
}

interface ListNotificationsRequest {
  page: number;
  size: number;
}

interface ListNotificationsResponse {
  currentPage: number;
  items: Notification[];
  perPage: number;
  total: number;
}

const NOTIFICATIONS_TAGS = ['NOTIFICATIONS'];

export const notifications = api.injectEndpoints({
  endpoints: build => ({
    getBellNotifications: build.query<BellNotifications, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Notificacao',
          method: 'getSino',
        },
        body: {},
      }),
      providesTags: NOTIFICATIONS_TAGS,
    }),
    getNextNotification: build.query<Notification | null, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Notificacao',
          method: 'getProxima',
        },
        body: {},
      }),
    }),
    listNotifications: build.query<
      ListNotificationsResponse,
      ListNotificationsRequest
    >({
      query: ({ page, size }) => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Notificacao',
          method: 'findAllPaginado',
          page,
          size,
        },
        body: {},
      }),
    }),
    markAsRead: build.mutation<ListNotificationsResponse, string>({
      query: id => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Notificacao',
          method: 'saveLeitura',
        },
        body: {
          CODNTF: id,
        },
      }),
      invalidatesTags: NOTIFICATIONS_TAGS,
    }),
  }),
});

export const {
  useGetBellNotificationsQuery,
  useListNotificationsQuery,
  useGetNextNotificationQuery,
  useMarkAsReadMutation,
} = notifications;
