import { ItensMenu } from 'core/models/menuItem';

import api from './base/api';

export const userMenuItemsApi = api.injectEndpoints({
  endpoints: build => ({
    getMenuItems: build.query<ItensMenu, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        body: {},
        params: {
          name: 'Usuario',
          method: 'getItensMenu',
        },
      }),
    }),
  }),
});

export const { useGetMenuItemsQuery } = userMenuItemsApi;
