import React from 'react';
import { Box } from '@mui/material';
import { m, AnimatePresence } from 'framer-motion';

import Logo from 'components/elements/Logo';

import { LoadingScreenProps } from './props';
import { RootStyle } from './styles';

const LoadingScreen: React.FC<LoadingScreenProps> = ({ showLoading }) => {
  return (
    <AnimatePresence>
      {showLoading && (
        <RootStyle
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <Logo sx={{ width: 24, height: 24 }} color="primary.main" />
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: theme => `solid 3px ${theme.palette.primary.main}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: theme => `solid 8px ${theme.palette.primary.main}`,
            }}
          />
        </RootStyle>
      )}
    </AnimatePresence>
  );
};

export default LoadingScreen;
