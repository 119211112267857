export const normalizeString = (value = '') => {
  return value.trim().toLocaleLowerCase();
};

const removeAccents = (text = '') => {
  let value = normalizeString(text);

  value = value.replace(/ /g, '-');
  value = value.replace(/[/?&=]/g, '-');
  value = value.replace(/\\/g, '');
  value = value.replace(/[ÁÀÂÃ]+g/, 'a');
  value = value.replace(/[ÉÈÊ]+g/, 'e');
  value = value.replace(/[ÍÌÎ]+g/, 'i');
  value = value.replace(/[ÓÒÔÕ]+g/, 'o');
  value = value.replace(/[ÚÙÛ]+g/, 'u');
  value = value.replace(/[Ç]+g/, 'c');
  return value;
};

export const generateKeyUrlName = (id, nome) => {
  return `${id}-${removeAccents(nome)}`;
};

export const buildKeyUrlID = (value = '') => {
  return value.split('-')[0];
};
