import { m } from 'framer-motion';
import { Box } from '@mui/material';

import useResponsive from 'core/hooks/useResponsive';

import { varContainer } from './variants';
import { MotionViewportProps } from './props';

interface Props extends MotionViewportProps {
  children: React.ReactNode;
  disableAnimatedMobile?: boolean;
}

const MotionViewport = ({
  children,
  disableAnimatedMobile,
  ...other
}: Props) => {
  const isMobile = useResponsive('down', 'sm');

  if (isMobile && disableAnimatedMobile) {
    return <Box {...other}>{children}</Box>;
  }

  return (
    <Box
      component={m.div}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, amount: 0.3 }}
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  );
};

MotionViewport.defaultProps = {
  disableAnimatedMobile: true,
};

export default MotionViewport;
