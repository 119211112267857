import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, BoxProps, Link } from '@mui/material';

import { HEADER } from 'core/config';
import { bgBlur } from 'core/utils/cssStyles';
import Logo from 'components/elements/Logo';

type Props = {
  isOffset: boolean;
};

const Shadow: React.FC<BoxProps> = ({ sx, ...other }) => {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: theme => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
};

const Header: React.FC<Props> = ({ isOffset }) => {
  const theme = useTheme();

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Logo sx={{ display: 'flex' }} />

        <Link
          href="https://zydon.com.br/b2b"
          variant="subtitle2"
          color="inherit"
        >
          Precisa de ajuda?
        </Link>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
};

export default Header;
