import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

interface WallpapperProps {
  background?: string;
}

interface ContentProps {
  boxed: boolean;
}

export const Wallpapper = styled('div')<WallpapperProps>(
  ({ background, theme }) => ({
    flex: 1,
    minHeight: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
    backgroundColor: theme.palette.grey[50],

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
);

export const Content = styled(Stack, {
  shouldForwardProp: prop => prop !== 'boxed',
})<ContentProps>(({ theme, boxed }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  maxWidth: 450,
  padding: theme.spacing(4, 4),
  borderRadius: 8,
  margin: 'auto',

  '.logo': {
    margin: 'auto',
  },

  ...(boxed && {
    background: theme.palette.background.paper,
    border: '1px solid rgba(145, 158, 171, 0.03)',
    boxShadow:
      '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  }),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(4, 4),
    boxShadow: 'none',
    border: 'none',
    background: theme.palette.grey[100],

    '.logo': {
      margin: 'auto',
    },
  },

  [theme.breakpoints.down('sm')]: {
    border: 'none',
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.35rem',
  color: theme.palette.grey[700],
}));

export const SubTitle = styled('h2')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.85rem',
  color: theme.palette.grey[600],
}));
