import { Banner } from 'core/models/banner';
import { NewOrderOptions } from 'core/models/config';
import { NewOrder } from 'core/models/newOrder';
import { OrderAddressesConfig } from 'core/models/orderAddressesConfig';
import { TableData } from 'core/models/tableData';
import { UserPreferences } from 'core/models/orderConfig';
import {
  OrderPaymentConfig,
  PaymentMethodConfig,
} from 'core/models/orderPaymentConfig';
import { RequestArguments } from 'core/models/requestArguments';

import api from './base/api';

interface GetData {
  body: object;
  pagination: {
    page: number;
    size: number;
  };
  origem: string;
  publico?: boolean;
}

export const CONFIG_NEW_ORDER_TAGS: any = ['NEW_ORDER'];

export const newOrderApi = api.injectEndpoints({
  endpoints: build => ({
    getProductData: build.query<NewOrder, GetData>({
      query: ({ body, pagination, origem }) => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Produto',
          method: 'getProdutos',
          callID: crypto.randomUUID(),
          origem,
          ...pagination,
        },
        body,
      }),
    }),
    getAddresses: build.query<OrderAddressesConfig, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'NovoPedido',
          method: 'getEnderecos',
        },
        body,
      }),
    }),
    getTypesOfNegotiation: build.query<TableData, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'NovoPedido',
          method: 'getNegociacoes',
        },
        body,
      }),
    }),
    getPaymentMethod: build.mutation<PaymentMethodConfig, string>({
      query: id => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeioPagamento',
          method: 'getByID',
        },
        body: { id },
      }),
    }),
    getPaymentConditions: build.query<OrderPaymentConfig, object>({
      query: body => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'MeioPagamento',
          method: 'getCondicoesPagamento',
        },
        body,
      }),
    }),
    getOptionsNewOrder: build.query<NewOrderOptions, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'NovoPedido',
          method: 'list',
        },
        body: {},
      }),
    }),
    getAllBanners: build.query<{ items: Banner[] }, void>({
      query: () => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'NovoPedido',
          method: 'getBanners',
        },
        body: {},
      }),
    }),
    getUserPreferences: build.query<
      UserPreferences,
      { novoPedidoID: string; argumentos: RequestArguments[] }
    >({
      query: ({ novoPedidoID, argumentos }) => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'NovoPedido',
          method: 'getPreferenciasEnderecoAndNegociacao',
        },
        body: { novoPedidoID, argumentos },
      }),
    }),
  }),
});

export const {
  useGetProductDataQuery,
  useGetAddressesQuery,
  useGetTypesOfNegotiationQuery,
  useGetOptionsNewOrderQuery,
  useGetAllBannersQuery,
  useGetUserPreferencesQuery,
  useGetPaymentConditionsQuery,
  useGetPaymentMethodMutation,
} = newOrderApi;
