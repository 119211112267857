import React from 'react';
import { Box, BoxProps } from '@mui/material';

import { NAV } from 'core/config';

const Main: React.FC<BoxProps> = ({ children, sx, ...other }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default Main;
