import React from 'react';
import { Outlet } from 'react-router-dom';

import useOffSetTop from 'core/hooks/useOffSetTop';
import { HEADER } from 'core/config';

import Header from './Header';

const SimpleLayout: React.FC = () => {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

      <Outlet />
    </>
  );
};

export default SimpleLayout;
