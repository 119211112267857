import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

const GlobalStyles = () => {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
          '&[type=search]': {
            '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration':
              {
                WebkitAppearance: 'none',
              },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.SnackbarContainer-root': {
          transform: 'scale(1)',
          zIndex: '9999 !important',
          maxHeight: '0 !important',

          '& .MuiCollapse-wrapper': {
            maxHeight: '0 !important',
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
};
export default GlobalStyles;
