import React from 'react';
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { LogoProps } from './props';

const Logo: React.FC<LogoProps> = ({ link, ...other }) => {
  const logo = (
    <Box {...other}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0905 0L12.8724 6.43619H2.95639e-05V0H16.0905Z"
          fill="currentColor"
        />
        <path
          d="M25.5483 6.43699V12.8732H9.65897L12.8771 6.43699H25.5483Z"
          fill="currentColor"
        />
        <path
          d="M9.65428 12.8729L6.43619 19.3091H0V12.8729H9.65428Z"
          fill="currentColor"
        />
        <path
          d="M25.5453 19.3078V25.744H3.21973L6.43783 19.3078H25.5453Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );

  if (!link) return logo;

  return <RouterLink to="/">{logo}</RouterLink>;
};

export default Logo;
