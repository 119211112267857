import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment,
  Stack,
  Link,
  Typography,
  Button,
} from '@mui/material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from 'components/elements/Iconify';
import Field from 'components/hook-form/Field';
import Form from 'components/hook-form/Form';

import ContainerLogin from './ContainerLogin';
import useLogin from './useLogin';

const Login = () => {
  const navigate = useNavigate();
  const {
    showPassword,
    loggingIn,
    setShowPassword,
    handleSubmit,
    methods,
    onSubmit,
    layout,
  } = useLogin();
  const {
    data: { habilitaSolicitacaoAcesso, habilitaVitrine, exibirRecuperarSenha },
  } = layout;

  const handleClickShowCase = () => {
    navigate('/');
  };

  return (
    <ContainerLogin title="Login" login>
      <Stack gap={3} width="100%">
        <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={3}>
            <Field
              name="username"
              rules={{
                required: {
                  value: true,
                  message: 'Campo obrigatório',
                },
              }}
              label="Usuário"
              autoComplete="username"
              fullWidth
            />

            <Field
              name="password"
              rules={{
                required: {
                  value: true,
                  message: 'Campo obrigatório',
                },
              }}
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      aria-label="Mostrar/Ocultar senha"
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {exibirRecuperarSenha && (
              <Stack direction="row" alignItems="flex-end" justifyContent="end">
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to="/recuperar-senha"
                >
                  Esqueci minha senha
                </Link>
              </Stack>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loggingIn}
            >
              Acessar
            </LoadingButton>
            {habilitaSolicitacaoAcesso && (
              <Typography variant="body1" align="center">
                Não tem uma conta?{' '}
                <Link
                  variant="subtitle1"
                  component={RouterLink}
                  to="/formulario-registro"
                >
                  Solicite seu acesso
                </Link>
              </Typography>
            )}
            {habilitaVitrine && (
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="outlined"
                  onClick={handleClickShowCase}
                  size="small"
                >
                  Acessar vitrine de produtos
                </Button>
              </Stack>
            )}
          </Stack>
        </Form>
      </Stack>
    </ContainerLogin>
  );
};

export default Login;
