import React, { useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import EmptyContent from 'components/elements/EmptyContent';
import Iconify from 'components/elements/Iconify';
import Scrollbar from 'components/wrappers/Scrollbar';
import useOrder from 'core/hooks/useOrder';
import usePaymentTypes from 'core/hooks/usePaymentType';
import { PaymentMethod } from 'core/models/paymentMethod';
import useDeepCompareEffect from 'core/hooks/useDeepCompareEffect';
import SearchInput from 'components/elements/SearchInput';
import useServerCart from 'core/hooks/useServerCart';
import { fCurrencyBRL, fPercentBRL } from 'core/utils/formatNumber';
import { PaymentSelectionModalProps } from './props';

const PaymentSelectionModal: React.FC<PaymentSelectionModalProps> = ({
  open,
  onClose,
  onSelect,
  onChangeCheckout,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<string>();
  const {
    orderConfigId,
    selectedPaymentMethod,
    updateOrderStateStoredValues,
    orderConfig,
  } = useOrder();
  const { loading, options, tipoPagamentoID, handleSelect, validValueNumber } =
    usePaymentTypes();

  const { changePayment } = useServerCart(orderConfigId);

  const handleClickPaymentType = (value: PaymentMethod) => {
    onSelect();
    if (onChangeCheckout) {
      onChangeCheckout(value);
    } else {
      handleSelect(value);
      if (orderConfig?.salvarCarrinho) changePayment(value.id);
    }
  };

  const filteredPaymentOptions = filter
    ? options.filter(
        a =>
          a.id?.toString().toLocaleLowerCase().includes(filter) ||
          a.descricao?.toString().toLocaleLowerCase().includes(filter) ||
          a.discount?.toString().toLocaleLowerCase().includes(filter) ||
          a.minimumOrder?.toString().toLocaleLowerCase().includes(filter) ||
          a.maximumOrder?.toString().includes(filter),
      )
    : options;

  useDeepCompareEffect(() => {
    if (!open || loading || !options || !selectedPaymentMethod) return;

    setTimeout(() => {
      const offsetTop =
        document.getElementById(selectedPaymentMethod.id)?.offsetTop || 0;
      scrollRef.current?.scroll({ top: offsetTop, behavior: 'smooth' });
    }, 50);
  }, [loading, options, open, selectedPaymentMethod]);

  useDeepCompareEffect(() => {
    const selectPreference = options.find(
      option => option.id?.toString() === tipoPagamentoID,
    );
    if (selectPreference && !selectedPaymentMethod) {
      updateOrderStateStoredValues('selectedPaymentMethod', selectPreference);
    }
  }, [tipoPagamentoID, updateOrderStateStoredValues, options]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="body">
      <DialogTitle sx={{ pb: 2, border: '1px solid', borderColor: 'divider' }}>
        <Stack direction="row" justifyContent="space-between">
          Forma de pagamento
          <IconButton onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', pb: 4 }}>
        {options.length > 0 && (
          <Box
            sx={{
              backgroundColor: 'background.neutral',
              border: '1px solid',
              borderColor: 'divider',
              p: 1.5,
              borderRadius: 1,
              mt: 3,
            }}
          >
            <SearchInput
              value={filter}
              trigger="change"
              onSearch={search => setFilter(search?.toLocaleLowerCase())}
              fullWidth
              sx={theme => ({
                '& .MuiInputBase-root': {
                  background: theme.palette.grey[0],
                },
              })}
              size="small"
              placeholder="Buscar forma de pagamento"
              iconPosition="start"
            />
          </Box>
        )}

        {loading && (
          <Stack mt={3} gap={1.25}>
            <Skeleton variant="rounded" height={62} />
            <Skeleton variant="rounded" height={62} />
            <Skeleton variant="rounded" height={62} />
          </Stack>
        )}

        {!loading && filteredPaymentOptions.length > 0 && (
          <>
            <Stack mt={2} gap={1.25}>
              <Scrollbar
                scrollableNodeProps={{
                  ref: scrollRef,
                }}
                sx={{ maxHeight: 400 }}
              >
                <Stack gap={1}>
                  {filteredPaymentOptions.map(option => (
                    <Paper
                      key={`${option.id}${option.descricao}`}
                      id={option.id}
                      variant="outlined"
                      sx={{
                        ...(options.length === 1 ||
                        (selectedPaymentMethod &&
                          selectedPaymentMethod.id === option.id)
                          ? {
                              borderColor: 'primary.main',
                            }
                          : {}),
                        cursor: 'pointer',
                      }}
                    >
                      <FormControlLabel
                        value={option.id}
                        checked={
                          options.length === 1 ||
                          (selectedPaymentMethod &&
                            selectedPaymentMethod.id === option.id)
                        }
                        control={<Radio />}
                        label={
                          <Stack
                            direction="row"
                            alignItems="center"
                            ml={1}
                            gap={1}
                          >
                            <Typography variant="subtitle2" flex={1}>
                              {option.descricao}
                            </Typography>

                            <Stack ml="auto" textAlign="right">
                              {!!option.discount && (
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="grey.500"
                                  sx={{ fontStyle: 'italic' }}
                                >
                                  Desconto:{' '}
                                  {fPercentBRL((option.discount || 0) / 100)}
                                </Typography>
                              )}

                              {validValueNumber(option.minimumOrder) && (
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="grey.500"
                                  sx={{ fontStyle: 'italic' }}
                                >
                                  Pedido mínimo:{' '}
                                  {fCurrencyBRL(option.minimumOrder)}
                                </Typography>
                              )}

                              {validValueNumber(option.maximumOrder) && (
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="grey.500"
                                  sx={{ fontStyle: 'italic' }}
                                >
                                  Pedido máximo:{' '}
                                  {fCurrencyBRL(option.maximumOrder)}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        }
                        sx={{
                          display: 'flex',
                          py: 1.875,
                          px: 2.5,
                          flexGrow: 1,
                          m: 0,
                          width: '100%',

                          '& .MuiFormControlLabel-label': {
                            flex: 1,
                          },
                        }}
                        onClick={() => handleClickPaymentType(option)}
                      />
                    </Paper>
                  ))}
                </Stack>
              </Scrollbar>
            </Stack>
          </>
        )}

        {!loading && filteredPaymentOptions.length === 0 && (
          <EmptyContent
            title=""
            description={
              filter
                ? 'Nenhum resultado encontrado'
                : 'Nenhuma forma de pagamento para ser apresentada'
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentSelectionModal;
