import { useState } from 'react';
import { Box, Button, Drawer } from '@mui/material';

import { NAV } from 'core/config';
import useToggle from 'core/hooks/useToggle';
import Image from 'components/elements/Image';
import Iconify from 'components/elements/Iconify';
import Scrollbar from 'components/wrappers/Scrollbar';
import { NavSectionVertical } from 'components/nav-section';

import navConfig from 'core/routes/@admin/nav';
import { OptionsContainer, BoxCompanies, CompanyButton } from './styles';

type Company = {
  id: number;
  name: string;
  image: string;
};

const COMPANIES = [
  {
    id: 1,
    name: 'Oscorp Tech',
    image: '/assets/images/company-1.png',
  },
  {
    id: 12,
    name: 'Industrias Stark',
    image: '/assets/images/company-2.png',
  },
  {
    id: 13,
    name: 'PYM Tecnologia',
    image: '/assets/images/company-3.png',
  },
];

const NavVertical: React.FC = () => {
  const [boxCompaniesOpen, toggleBoxCompaniesOpen] = useToggle();
  const [selectedCompany, setSelectedCompany] = useState<Company>(COMPANIES[0]);

  const handleClickCompanyButton = (company: Company) => {
    setSelectedCompany(company);
    toggleBoxCompaniesOpen();
  };

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: NAV.W_DASHBOARD,
      }}
    >
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            width: NAV.W_DASHBOARD,
            bgcolor: 'transparent',
            borderRightStyle: 'dashed',
          },
        }}
      >
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          forceVisible
        >
          <OptionsContainer
            sx={{
              pt: 2.15,
              pb: 1,
              px: 2.5,
            }}
          >
            <BoxCompanies open={boxCompaniesOpen}>
              <CompanyButton
                key={selectedCompany.id}
                fullWidth
                size="large"
                active={selectedCompany.id === selectedCompany?.id}
                onClick={toggleBoxCompaniesOpen}
              >
                <Image
                  src={selectedCompany.image}
                  sx={{ width: 40, height: 40, mr: 2 }}
                />
                {selectedCompany.name}
                {boxCompaniesOpen ? (
                  <Iconify icon="eva:chevron-up-fill" />
                ) : (
                  <Iconify icon="eva:chevron-down-fill" />
                )}
              </CompanyButton>

              {boxCompaniesOpen &&
                COMPANIES.filter(
                  company => company.id !== selectedCompany.id,
                ).map(company => (
                  <CompanyButton
                    key={company.id}
                    fullWidth
                    size="large"
                    active={company.id === selectedCompany.id}
                    onClick={() => handleClickCompanyButton(company)}
                  >
                    <Image
                      src={company.image}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    {company.name}
                    <Iconify icon="eva:chevron-right-fill" />
                  </CompanyButton>
                ))}
            </BoxCompanies>

            <Button
              variant="outlined"
              size="small"
              fullWidth
              startIcon={<Iconify icon="ic:twotone-open-in-new" />}
            >
              Acessar meu portal
            </Button>
          </OptionsContainer>

          <NavSectionVertical data={navConfig} />

          <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
      </Drawer>
    </Box>
  );
};

export default NavVertical;
