import { Navigate, createBrowserRouter } from 'react-router-dom';

import External from 'core/layouts/External';
import Dashboard from 'core/layouts/@portal/Dashboard';
import AuthGuard from 'core/guards/AuthGuard';
import GuestGuard from 'core/guards/GuestGuard';
import { DASHBOARD_PORTAL_PATH } from 'core/config';
import SimpleLayout from 'core/layouts/SimpleLayout';
import Payment from 'views/@portal/Payment';

import dashboardRoutes from './dashboardRoutes';
import {
  EXTERNAL_ROUTES,
  EXTERNAL_GUEST_ROUTES,
  ServerError,
} from './externalRoutes';

const { Element: ServerErrorComponent, path: serverErrorPath } = ServerError;

const router = createBrowserRouter([
  {
    path: `/pagamento`,
    element: <Payment />,
  },
  {
    path: DASHBOARD_PORTAL_PATH,
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes.map(({ path, Element }) => ({
        path,
        element: <Element />,
      })),
    ],
  },
  {
    path: '*',
    element: <External />,
    children: [
      ...EXTERNAL_ROUTES.map(({ path, Element }) => ({
        path,
        element: <Element />,
      })),
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '*',
    element: <SimpleLayout />,
    children: [
      {
        path: serverErrorPath,
        element: <ServerErrorComponent />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <GuestGuard>
        <External />
      </GuestGuard>
    ),
    children: [
      ...EXTERNAL_GUEST_ROUTES.map(({ path, Element }) => ({
        path,
        element: <Element />,
      })),
    ],
  },
]);

export default router;
