import { Validation } from 'core/models/validation';
import { cnpjIsValid, cpfIsValid } from './validators';

export default {
  [Validation.ALFABETICA]: {
    validation: (value: string) => {
      if (!value || /^[A-Za-z\s]*$/.test(value)) return true;

      return 'Informe somente letras';
    },
  },
  [Validation.CEP]: {
    validation: (value: string) => {
      if (
        !value ||
        /^\d{2}\d{3}[-]\d{3}$/.test(value) ||
        /^\d{2}\d{3}\d{3}$/.test(value)
      )
        return true;

      return 'Informe um CEP válido';
    },
  },
  [Validation.CPF_CNPJ]: {
    validation: (value: string) => {
      if (!value) return true;

      const v = value.replace(/\D/g, '');

      if (v.length === 11) return cpfIsValid(v) || 'CPF inválido';
      if (v.length === 14) return cnpjIsValid(v) || 'CNPJ inválido';

      return 'Número inválido';
    },
  },
  [Validation.EMAIL]: {
    validation: (value: string) => {
      if (
        !value ||
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
          value,
        )
      )
        return true;

      return 'Informe um e-mail válido';
    },
  },
  [Validation.MOEDA]: {
    validation: (value = '') => {
      const isNumeric = /^-?[0-9]+(?:\.[0-9]+)?$/.test(value.toString());

      if (!value || isNumeric) return true;

      return 'Somente valores monetários';
    },
  },
  [Validation.NUMERICO]: {
    validation: (value: string | number) => {
      const isNumeric = /^-?[0-9]+(?:\.[0-9]+)?$/.test(value.toString());

      if (!value || isNumeric) return true;

      return 'Somente valores numéricos';
    },
  },
  [Validation.SEM_VALORES_DECIMAIS]: {
    validation: (value: string | number) => {
      if (!value || /^-?[0-9]+$/.test(value.toString())) return true;

      return 'Somente números inteiros';
    },
  },
  [Validation.TELEFONE]: {
    validation: (value: string) => {
      if (!value || /^(\+55\s?)?(\(\d{2}\)|\d{2})\s?\d{4,5}-\d{4}$/.test(value))
        return true;

      return 'Informe um número válido';
    },
  },
  [Validation.URL]: {
    validation: (value: string) => {
      const urlRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

      if (!value || urlRegex.test(value)) return true;

      return 'Informe um URL válida';
    },
  },
};
