import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ChangeDiscountButtonProps } from './props';

const ChangeDiscountButton: React.FC<ChangeDiscountButtonProps> = ({
  value,
  onChange,
  ...other
}) => {
  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={value}
      exclusive
      onChange={onChange}
      {...other}
    >
      <ToggleButton value="VALUE" sx={{ height: '30px' }}>
        R$
      </ToggleButton>

      <ToggleButton value="PERCENT" sx={{ height: '30px', width: '33px' }}>
        %
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ChangeDiscountButton;
