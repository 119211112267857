import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';

import * as Sentry from '@sentry/react';

import api from 'core/services/base/api';

import rootReducer, { rootPersistConfig } from './rootReducer';

export type AppDispatch = typeof store.dispatch;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: any) => {
    const transformedState = {
      auth: {
        token: state.auth.token,
      },
    };

    return transformedState;
  },
});

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([api.middleware]),
  enhancers: [sentryReduxEnhancer],
});

const persistor = persistStore(store);

const { dispatch } = store;

export type ApplicationState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof rootReducer>;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { store, persistor, dispatch, useSelector, useDispatch };
