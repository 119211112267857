import { useCallback } from 'react';

import { dispatch, useSelector } from 'core/redux/store';
import {
  toogleNavMini as toogle,
  setNavAsMini as setNavMini,
} from 'core/redux/slices/layout';

const useLayout = () => {
  const { themeDirection, data, title, primaryColor, isNavMini } = useSelector(
    state => state.layout,
  );
  const themeStretch = true;

  const toogleNavMini = () => dispatch(toogle());

  const setNavAsMini = useCallback(() => dispatch(setNavMini()), []);

  return {
    data,
    themeDirection,
    title,
    primaryColor,
    themeStretch,
    isNavMini,
    toogleNavMini,
    setNavAsMini,
  };
};

export default useLayout;
