import { Theme } from '@mui/material/styles';

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: 'none',

          '& .clickable-row': {
            cursor: 'pointer',
          },

          '& .no-clickable-row .clickable-row': {
            cursor: 'initial',
          },

          '& .no-clickable-row .clickable-row:hover': {
            backgroundColor: 'initial',
          },

          '&.simple': {
            '& .MuiDataGrid-columnHeaders': {
              color: theme.palette.text.secondary,
              backgroundColor: '#fff',
              borderBottom: 'none',
              borderRadius: 0,
            },

            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 700,
              fontSize: 11,
            },
          },

          '& .MuiTablePagination-root': {
            borderTop: 0,
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            padding: '0 16px',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.neutral,
            borderRadius: 0,
          },
          '& .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
          },
          '& .MuiDataGrid-pinnedColumns--right': {
            backgroundColor: '#fff',
            boxShadow: 'none',
          },
          '& .MuiDataGrid-pinnedColumns--left': {
            backgroundColor: '#fff',
            boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.071)',
          },
          '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: theme.palette.background.neutral,
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            width: '64px !important',
            minWidth: '64px !important',
            maxWidth: '64px !important',
          },
          '& .MuiDataGrid-cellCheckbox': {
            width: '64px !important',
            minWidth: '64px !important',
            maxWidth: '64px !important',
          },
          '& .MuiDataGrid-pinnedRows': {
            boxShadow: 'none',
            fontWeight: 'bold',
          },
          '& .hide-checkbox-select-all div': {
            display: 'none !important',
          },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
            border: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            color: theme.palette.divider,
          },
          '& .MuiDataGrid-columnSeparator:hover': {
            color: theme.palette.primary.main,
          },
          '& .MuiDataGrid-cell[data-field="__check__"]': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
        },
        toolbarContainer: {
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
          borderTop: `solid 1px ${theme.palette.divider}`,

          '& .MuiButton-root': {
            marginRight: theme.spacing(1.5),
            color: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        menu: {
          '& .MuiPaper-root': {
            boxShadow: theme.customShadows.dropdown,
            padding: 8,
            borderRadius: 12,
          },
          '& .MuiMenuItem-root': {
            borderRadius: 8,
            ...theme.typography.body2,
            '& .MuiListItemIcon-root': {
              minWidth: 'auto',
              color: theme.palette.primary.main,
            },
            '& .MuiListItemText-primary': {
              fontSize: 14,
            },
          },
        },
        panelFooter: {
          padding: theme.spacing(2),
          justifyContent: 'flex-end',
          borderTop: `1px solid ${theme.palette.divider}`,
          '& .MuiButton-root': {
            '&:first-of-type': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '&:last-of-type': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
        filterForm: {
          padding: theme.spacing(1.5, 0),
          '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0.5),
          },
          '& .MuiInput-root': {
            marginTop: theme.spacing(3),
            '&::before, &::after': {
              display: 'none',
            },
            '& .MuiNativeSelect-select, .MuiInput-input': {
              ...theme.typography.body2,
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.neutral,
            },
            '& .MuiSvgIcon-root': {
              right: 4,
            },
          },
        },
      },
    },
  };
}
