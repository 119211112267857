import { ApiRequest } from 'core/types/apiRequest';
import { OptionField } from 'core/models/optionField';
import { Field } from 'core/models/config';

import api from './base/api';

interface ApiOptionsRequest extends ApiRequest {
  url: string;
}

export const fieldOptionsApi = api.injectEndpoints({
  endpoints: build => ({
    getOptions: build.query<{ opcoes: OptionField[] }, ApiOptionsRequest>({
      query: ({ url, name, method, body }) => ({
        url,
        method: 'POST',
        params: {
          name,
          method,
        },
        body,
      }),
    }),
    getFieldOptionsConfig: build.query<{ campos: Field[] }, string>({
      query: id => ({
        url: 'services',
        method: 'POST',
        params: {
          name: 'Tabela',
          method: 'getCampos',
        },
        body: {
          id,
        },
      }),
    }),
  }),
});

export const { useGetOptionsQuery, useGetFieldOptionsConfigQuery } =
  fieldOptionsApi;
