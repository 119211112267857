import React from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { ConfirmDialogProps } from './props';

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  content,
  action,
  open,
  onClose,
  showCancelButton = true,
  ...other
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && (
        <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>
      )}

      <DialogActions>
        {action}

        {showCancelButton && (
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
