import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import useField from 'core/hooks/useField';

import { FieldProps } from './props';

const Field: React.FC<FieldProps> = props => {
  const { controller, otherFieldProps, disabled } = useField(props);
  const { helperText, onChange, ...rest } = otherFieldProps;

  return (
    <Controller
      {...controller}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={e => {
            field.onChange(e);
            if (onChange) onChange(e);
          }}
          inputRef={ref}
          fullWidth
          error={!!error}
          helperText={error?.message || helperText}
          disabled={disabled || otherFieldProps.disabled}
          {...rest}
        />
      )}
    />
  );
};

export default Field;
